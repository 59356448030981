import { useEffect } from "react";
import { useState } from "react";
import http from "../../utils/http";
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';

const AdminSubjects = () => {
  const [subject, setSubject] = useState('');
  const [subjects, setSubjects] = useState([]);

  const getSubjects = async () => {
    let cont = [];
    const data = await http.get('admin/subjects');
    for (let i = 0; i < data.data.data.length; i++) {
      cont.push(data.data.data[i])
    }
    setSubjects(cont);
  }
  const addSubjects = async (e) => {
    e.preventDefault();
    let postData = {
      name: subject
    };
    const subjects = await http.post('admin/subjects', postData);
    console.log(subjects);
        // .then((resp) => {
        //     console.log(resp)
        //     getSubjects()
        // })
    getSubjects()
  }

  const deleteSubject = async (e, id) => {
    e.preventDefault();
    await http.delete('admin/subjects/' + id)
        .then((resp) => {
            console.log(resp)
            getSubjects()
        })
  }

  useEffect(() => {
    getSubjects()
  }, [])

  return (
    <>

      <form onSubmit={addSubjects} method="post">
        <div className="card-body">
          <FormLabel>Tag name</FormLabel>
          <Input
           placeholder="Add subjects"
           sx={{ mb: 1, fontSize: 'var(--joy-fontSize-sm)' }}
            name="title" onChange={(e) => setSubject(e.target.value)} value={subject}
            endDecorator={<Button type="submit">Submit</Button>}
          />
        </div>
      </form>

      <Box sx={{ width: '100%' }}>
        {/* <Typography level="body2" textAlign="center" sx={{ pb: 2 }}>
          ← Scroll direction →
        </Typography> */}
        <Sheet
          variant="outlined"
          sx={{
            '--TableCell-height': '40px',
            // the number is the amount of the header rows.
            '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
            '--Table-firstColumnWidth': '80px',
            '--Table-lastColumnWidth': '144px',
            // background needs to have transparency to show the scrolling shadows
            '--TableRow-stripeBackground': 'rgba(0 0 0 / 0.04)',
            '--TableRow-hoverBackground': 'rgba(0 0 0 / 0.08)',
            overflow: 'auto',
            background: (theme) =>
              `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
            backgroundSize:
              '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'local, local, scroll, scroll',
            backgroundPosition:
              'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
            backgroundColor: 'background.surface',
          }}
        >
          <Table
            borderAxis="bothBetween"
            stripe="odd"
            hoverRow
            sx={{
              '& tr > *:first-child': {
                position: 'sticky',
                left: 0,
                boxShadow: '1px 0 var(--TableCell-borderColor)',
                bgcolor: 'background.surface',
              },
              '& tr > *:last-child': {
                position: 'sticky',
                right: 0,
                bgcolor: 'var(--TableCell-headBackground)',
              },
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 'var(--Table-firstColumnWidth)' }}>Row</th>
                <th style={{ width: 200 }}>Names</th>
                <th
                  aria-label="last"
                  style={{ width: 'var(--Table-lastColumnWidth)' }}
                />
              </tr>
            </thead>
            <tbody>
              {subjects.map((row, idx) => (
                <tr key={idx + 1}>
                  <td>{idx + 1}</td>
                  <td>{row.name}</td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {/* <Button size="sm" variant="plain" color="neutral">
                      Edit
                    </Button> */}
                      <Button onClick={(e) => deleteSubject(e, row.id)} size="sm" variant="soft" color="danger">
                        Delete
                      </Button>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      </Box>
    </>
  )
}

export default AdminSubjects;
