import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import { getProfile } from "../utils/get-profile";
import { InlineWidget, PopupWidget, PopupModal } from "react-calendly";
import http from "../utils/http";

// product card component with properties
function BookingModal({ teacher, status }) {
  const {days, subjects, schedule} = teacher;
  const [email, setEmail] = useState('');
  const [modalState, setModalState] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [timeRange, setTimeRange] =useState({});
  const [selectedSubject, setSelectedSubject] = useState('');
  const [open, setOpen] = useState(false);
  const [_subjects, setSubjects] = useState([]);
  const [ref, setRef] = useState(new Date().getTime().toString());


  useEffect(() => {
    setOpen(status);
  }, [status]);

  useEffect(() => {
    setSubjects(subjects?.split(','))
  }, []);

  useEffect(() => {
    const getEmail = async () => {
        const admin = await getProfile()
        setEmail(admin.data.data[0].email)
    }
    getEmail();
  }, [])

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%",
      sm: "300px",
      md: "500px",
      lg: "500px",
      xl: "800px",
    },
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const config = {
    reference: ref,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  };

  // you can call this function anything
  const onSuccess = ({reference}) => {
      const result = http.post(`checkout/${reference}`);
      navigate('/my-appointments');        
      console.log(result)
  };

  // you can call this function anything
  const onClose = () => {
      toast.info('Payment Cancelled');
      setModalState(true);
  }

  const initializePayment = usePaystackPayment({
      ...config,
      amount: teacher.amount * 100,
      email: email
  });

  const completeBooking = async(e) => {
    e.preventDefault();
    
    let postData = {
      ref_no: ref,
      teacher_id: teacher.id,
      subject: selectedSubject,
      amount: teacher.amount,
    };

    await http
      .post(API_ENDPOINTS.APPOINTMENT, postData)
      .then((data) => {
        if(data.data.success) {
          initializePayment(onSuccess, onClose);
          toast(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <small>*NB: Subscribers are entitled to 4hrs in a month</small>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Book Lesson
          </Typography>
          <form onSubmit={(e) => completeBooking(e)} method="post">
            <Box
              sx={{
                display: "flex",
                width: {
                  sm: "100%",
                  md: "300px",
                  lg: "500px",
                },
                pb: 3,
              }}
            >
              <FormLabel
                sx={{
                  fontSize: {
                    xs: "12px",
                    md: "13px",
                    lg: "15px",
                  },
                  pr: 3,
                }}
              >
                Select Subject:
              </FormLabel>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-controlled-open-select-label">Subjects</InputLabel>

                <Select 
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  label="Subjects"
                  value={selectedSubject}
                  onChange={(e) => setSelectedSubject(e.target.value)}
                  required
                >
                  {
                    _subjects ? _subjects.map((subject, idx) => 
                      <MenuItem key={idx} value={subject} style={{textTransform: 'capitalize'}}>{subject}</MenuItem>
                    ) : <MenuItem defaultValue="">No subject</MenuItem>
                  }  
                </Select>
              </FormControl>
            </Box>

            <Button style={{float: 'right'}} type="submit">Complete Booking</Button>
          </form>
        </Box>
      </Modal>
      <PopupModal
          url={teacher.calendly_link}
          // pageSettings={this.props.pageSettings}
          // utm={this.props.utm}
          // prefill={this.props.prefill}
          onModalClose={() => setModalState(false)}
          open={modalState}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
    </>
  );
}

export default BookingModal;
