import React, { useState } from "react";
import http from "../../utils/http";
import { getCategories, getTags } from "../../utils/get-categories";
import { useEffect } from "react";
import Select from 'react-select';
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 } from "uuid";
import ProgressBar from "@ramonak/react-progress-bar";
import { encrypt } from "../../utils/encryptor";

const config = {
    region: process.env.REACT_APP_S3_REGION,
    credentials: {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY        
    }
}

const client = new S3Client(config);

const UploadMedia = () => {
    const [title, setBookTitle] = useState('');
    const [tags, setTags] = useState([]);
    const [tag] = useState([]);
    const [categories, setCategories] = useState([]);
    const [mediaType, setMediaType] = useState('');
    const [oneTimePayment, setOneTimePayment] = useState('');
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('')
    const [streamType, setStreamType] = useState('');
    const [disable_upload, setUploadStatus] = useState(false);
    const [amount, setAmount] = useState(0)
    // const [type] = useState([]);
    const [category] = useState([]);
    const [progress, setProgress] = useState(0);
    const [coverProgress, setCoverProgress] = useState(0);
    const [mediaUrls, setMediaUrls] = useState([]);
    const [coverUrl, setCoverUrl] = useState('');
    const [bookFile, setBookFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [coverFile, setCoverFile] = useState();
    const [isCoverPicked, setIsCoverPicked] = useState(false);

    useEffect(() => {        
        async function cate() {
            let cont = [];
            const {data} = await getCategories();
            for(let i = 0; i < data.length; i++) {
                cont.push(data[i])
              }
            setCategories(cont);
        }
        cate();
    }, [])

    const options = [
        {value: 'video', label: 'Video'},
        {value: 'audio', label: 'Audio'}
    ]

    const streamOptions = [
        {value: 'free', label: 'Free'},
        {value: 'paid', label: 'Paid'}
    ]

    const categoryOptions = [{value: '', label: 'select category'}];
    categories.forEach((cat) => {
        categoryOptions.push({value: cat.id, label: cat.name})            
    })

    const tagOptions = [{value: '', label: 'select tags'}];
    tags.forEach((tg) => {
        tagOptions.push({value: tg.id, label: tg.name})            
    })

    useEffect(() => {
        const Tages = async () => {
          let cont = [];
          const {data} = await getTags();
          for(let i = 0; i < data.length; i++) {
            cont.push(data[i])
          }
        setTags(cont);
        }
        Tages()
    }, [])

    const handleCategories = (e) => {
        let value = []
        value.push(e[e.length - 1])
        value.forEach((val) => {
            category.push(val.value)
        })      
    }

    const handleTags = (e) => {
        let value = []
        value.push(e[e.length - 1])
        value.forEach((val) => {
            tag.push(val.value)
        })
    }

    const UploadMedia = async (media) => {
        let videoUrls = [];
        for(let i = 0; i < media.length; i++) {
            let type = media[i].type.split('/')[1];
            const mediaNameKey = "medias/";
            const mediaKey = mediaNameKey + v4() + '.' + type;
            await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: mediaKey, Body: media[i]}))
            .then((data) => {
                if(data) {
                    setProgress(100);
                }
            }).catch(err => toast.error(err))
            const _mediaUrl = encrypt(process.env.REACT_APP_S3_CLOUDFRONT_URL + mediaKey);
            videoUrls.push(_mediaUrl);
            console.log(videoUrls);
        }
        setMediaUrls(videoUrls);
    }

    const UploadMediaCover = async (cover) => {
        let type = cover.type.split('/')[1];
        const coverNameKey = "media/cover/";
        const coverKey = coverNameKey + v4() + '.' + type;
        await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: coverKey, Body: cover}))
        .then((data) => {
            if(data) {
                setCoverProgress(100);
            }
        }).catch(err => toast.error(err))
        const _coverUrl = process.env.REACT_APP_S3_CLOUDFRONT_URL + coverKey
        setCoverUrl(_coverUrl)
    }

    const mediaUploadEvent = (e) => {
        e.preventDefault();
        setIsFilePicked(true);
        setBookFile(e.target.files[0])
        UploadMedia(e.target.files)
    }

    const coverUploadEvent = (e) => {
        e.preventDefault();
        setIsCoverPicked(true);
        setCoverFile(e.target.files[0])
        UploadMediaCover(e.target.files[0])
    }

    const postMedia = async (e) => {
        e.preventDefault();
        setUploadStatus(true)
        let postData = {
            title: title,
            categories: category,
            tags: tag,
            amount: amount,
            oneTimePayment: oneTimePayment,
            stream_type: streamType.value
        }
        if(mediaType.value === 'audio') {
            await http.post('audio', {...postData, audio_url: mediaUrls, audio_cover_url: coverUrl})
            .then((data) => {
                toast.success(data.data.data)
                setLoading(false)
                setStatus(data.data.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response.data.message)
                setStatus(err.response.data.message)
                setLoading(false);
                setUploadStatus(false);
            })
        }
        if(mediaType.value === 'video') {
            await http.post('video', {...postData, video_url: mediaUrls, video_cover_url: coverUrl})
            .then((data) => {
                toast.success(data.data.data)
                setLoading(false)
                setStatus(data.data.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response.data.message)
                setStatus(err.response.data.message)
                setLoading(false);
                setUploadStatus(false);
            })
        }
    }

    return (
        <>
            {/* <Header /> */}
            <div className="main-content" style={{padding: '40px 0px'}}>
                <section className="section">
                    <div className="section-header">
                        <h1>Upload Media (Videos and AudioBooks)</h1>
                    </div>
                    <form onSubmit={postMedia} method="post">
                    <div className="row">
                        <div className="col-12">
                            <div className="card" style={{margin: '20px 10px'}}>                                
                                <div className="card-body">
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Media Title</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="text" value={title} onChange={(e) => setBookTitle(e.target.value)} name="title" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Media Type</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Select options={options} onChange={(e) => setMediaType(e)} name="mediaType" />                                            
                                        </div>
                                    </div>
                                    {/* <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Stream Type</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Select options={streamOptions} onChange={(e) => setoneTimePayment(e)} name="type" />                                            
                                        </div>
                                    </div> */}
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Media Banner <small>(Video or Image)</small></label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="file" onChange={(e) => coverUploadEvent(e)} name="coverFile" className="form-control"/>
                                            <ProgressBar completed={coverProgress} />
                                            {isCoverPicked ? (
                                                <div>
                                                    <p>Filename: {coverFile.name}</p>
                                                    <p>Filetype: {coverFile.type}</p>
                                                    <p>Size in bytes: {coverFile.size}</p>
                                                </div>
                                            ) : (
                                                <p>Select a file to show details</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Upload Media</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="file" onChange={(e) => mediaUploadEvent(e)} name="mediaFile" className="form-control" multiple/>
                                            <ProgressBar completed={progress} />
                                            {isFilePicked ? (
                                                <div>
                                                    <p>Filename: {bookFile.name}</p>
                                                    <p>Filetype: {bookFile.type}</p>
                                                    <p>Size in bytes: {bookFile.size}</p>
                                                </div>
                                            ) : (
                                                <p>Select file(s) to show details</p>
                                            )}
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Stream Type</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Select options={streamOptions} onChange={(e) => setStreamType(e)} name="type" />                                            
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Daily Amount</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} name="amount" className="form-control" />
                                        </div>
                                    </div>  
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">One off purchase</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="number" value={oneTimePayment} onChange={(e) => setOneTimePayment(e.target.value)} name="amount" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Category</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Select options={ categoryOptions } onChange={(e) => handleCategories(e)} name="category" isMulti />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Tag</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Select options={ tagOptions } onChange={(e) => handleTags(e)} name="category" isMulti />                                            
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                                        <div className="col-sm-12 col-md-7">
                                            <button type="submit" onClick={() => setLoading(true)} className="btn btn-primary btn-lg btn-block">
                                                {disable_upload ? <ClipLoader loading={loading} size={20} /> : 'Publish'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>                    
                </section>
            </div>
            <ToastContainer />
        </>
    )
}

export default UploadMedia;
