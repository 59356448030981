import { getToken } from './get-token';
import http from './http';

const getProfile = async () => {
    const token = getToken();
    let data = [];
    if (token) {
        await http.get('profile')
        .then(res => {data = res})
        .catch(err => {
            if (err && err.response) {
                data = err.response.data.message;
            } else data = null;
        })
    }
    return data;
};

export { getProfile };
