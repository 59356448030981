import { Box, Button, Sheet, Table, Typography } from "@mui/joy";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";

const AdminSubTransactions = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const getTransactions = async () => {
      const data = await http.get("admin/sub/transactions");
      console.log(data);
      setTransactions(data.data.data);
    };
    getTransactions();
  }, []);

  return (
    <>
      <Typography level="h1">Subscription Transactions</Typography>
      <Box sx={{ width: "100%" }}>
        <Sheet
          variant="outlined"
          sx={{
            "--TableCell-height": "40px",
            // the number is the amount of the header rows.
            "--TableHeader-height": "calc(1 * var(--TableCell-height))",
            "--Table-firstColumnWidth": "80px",
            "--Table-lastColumnWidth": "144px",
            // background needs to have transparency to show the scrolling shadows
            "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
            "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
            overflow: "auto",
            background: (theme) =>
              `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
            backgroundSize:
              "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local, local, scroll, scroll",
            backgroundPosition:
              "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
            backgroundColor: "background.surface",
          }}
        >
          <Table
            borderAxis="bothBetween"
            stripe="odd"
            hoverRow
            sx={{
              "& tr > *:first-child": {
                position: "sticky",
                left: 0,
                boxShadow: "1px 0 var(--TableCell-borderColor)",
                bgcolor: "background.surface",
              },
              "& tr > *:last-child": {
                position: "sticky",
                right: 0,
                bgcolor: "var(--TableCell-headBackground)",
              },
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "var(--Table-firstColumnWidth)" }}>#</th>
                <th style={{ width: 200 }}>Name</th>
                <th style={{ width: 200 }}>Amount</th>
                <th style={{ width: 200 }}>Status</th>
                <th style={{ width: 200 }}>Reference No.</th>
                <th style={{ width: 200 }}>Date</th>
                <th style={{ width: 200 }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {transactions
                ? transactions.map((transaction) => (
                    <tr>
                      <td>{transaction.paid_by}</td>
                      <td>{transaction.amount}</td>
                      <td>{transaction.status}</td>
                      <td>{transaction.ref_no}</td>
                      <td>{transaction.created_at}</td>

                      <td>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button>
                            <Link
                              to={"transactions/" + transaction.id}
                              size="sm"
                              variant="plain"
                              color="neutral"
                            >
                              Details
                            </Link>
                          </Button>
                        </Box>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </Sheet>
      </Box>
    </>
  );
};

export default AdminSubTransactions;
