import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import { ReactReader } from "react-reader";
import StopWatch from "statman-stopwatch";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import http from "../utils/http";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useParams } from "react-router-dom";
import { decrypt } from "../utils/encryptor";

function Reader() {
    let sw = new StopWatch(true);
    
    const params = useParams();
    const [bookUrl, setBookUrl] = useState('');
    const bookLength = bookUrl.split('.').length;
    const bookType = bookUrl.split('.')[bookLength - 1];
    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {
                    CurrentPageInput,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    ShowSearchPopover,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = slots;
                return (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <div style={{ padding: '0px 2px' }}>
                            <ShowSearchPopover />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomOut />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <Zoom />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomIn />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <GoToPreviousPage />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <CurrentPageInput /> / <NumberOfPages />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <GoToNextPage />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <EnterFullScreen />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
    });    

    useEffect(() => {
        const getBookFile = async () => {
            const { data } = await http.get(`books/mine/${params.id}`);
            setBookUrl(decrypt(data.data[0].bookUrl));
        }
        getBookFile();
    }, [])

    useEffect(() => {
        return(() => {
            // http.post('update/access')
            // alert("you read for" + sw.read())
            sw.stop();
        })
    }, [])

    // And your own state logic to persist state
    const [location, setLocation] = useState(null)
    const [firstRenderDone, setFirstRenderDone] = useState(false)
    const renditionRef = useRef(null)
    const locationChanged = (epubcifi) => {
        // Since this function is also called on initial rendering, we are using custom state
        // logic to check if this is the initial render.
        // If you block this function from running (i.e not letting it change the page on the first render) your app crashes.

        if (!firstRenderDone){
            setLocation(localStorage.getItem("book-progress")); // getItem returns null if the item is not found.
            setFirstRenderDone(true);
            return;
        }

        // This is the code that runs everytime the page changes, after the initial render.
        // Saving the current epubcifi on storage...
        localStorage.setItem("book-progress", epubcifi)
        // And then rendering it.
        setLocation(epubcifi) // Or setLocation(localStorage.getItem("book-progress"))
    }
    
    const renderPage = (props) => (
        <>
            {props.canvasLayer.children}
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                }}
            >
                <div
                    style={{
                        color: 'rgba(0, 0, 0, 0.2)',
                        fontSize: `${8 * props.scale}rem`,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        transform: 'rotate(-45deg)',
                        userSelect: 'none',
                    }}
                >
                    Kokawe
                </div>
            </div>
            {props.annotationLayer.children}
            {props.textLayer.children}
        </>
    );

    return (
        <div style={{ height: "100vh"}}>
            {/* <button>Home</button> */}
            {
                bookType === 'epub' ? (<ReactReader
                    location={location}           
                    locationChanged={locationChanged}
                    url={bookUrl}
                    getRendition={(rendition) => {
                        rendition.themes.register('custom', {
                            "*": {
                                'user-select': 'none !important',
                            },
                            body: {
                                'background-image': 'url(../../assets/symmetric2.jpg)'
                            },
                            p: {
                                'font-family': 'Helvetica, sans-serif',
                              'font-weight': '400',
                              'font-size': '20px'
                            }
                        })
                        rendition.themes.select('custom')
                        renditionRef.current = rendition}                    
                    }
                />) : bookType === 'pdf' ? (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/es5/build/pdf.worker.min.js">
                        <Viewer fileUrl={bookUrl} plugins={[defaultLayoutPluginInstance]} renderPage={renderPage} />
                    </Worker>                    
                ) : 'Book type not supported'
            }           
        </div>
    )
}

export default Reader;
