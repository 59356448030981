import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import http from "../utils/http";

function ChangePassword() {
    const [code, setCode] = useState('');
    const [status, setStatus] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [disable_button, setButtonStatus] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async(e) => {
        e.preventDefault()
        let postData = {
            code: code,
            password: password,
            password_confirmation: confirmPassword
        }

        await http.post('change_password', postData)
        .then((res) => {
            console.log(res.data);
            setStatus(res.data.data[0].message);
            navigate('/login')
        })
        .catch((err) => {
            // console.log(err);
            setStatus(err.response.data.message)
        })
    }

    return (        
        <>
            {/* <Header /> */}
            <div className="w-500" style={{maxWidth: 400, margin: 'auto'}}>
                <div className="login-brand">
                    <img src="./logo.png" alt="logo" width="100" />
                </div>
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>Forgot Password</h4>
                    </div>
                    <div className="card-body">
                        <div style={{color: 'red', fontWeight: 'bold', textAlign: 'center'}}>{status}</div>
                        <p className="text-muted">We will send a link to reset your password</p>
                        <form onSubmit={handleSubmit} method="POST">
                            <div className="form-group">
                                <label htmlFor="code">Code</label>
                                <input id="code" type="code" value={code} onChange={(e) => setCode(e.target.value)} className="form-control" name="code" tabIndex="1" required autoFocus />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" name="password" tabIndex="1" required autoFocus />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirm_password">Confirm Password</label>
                                <input id="confirm_password" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control" name="confirm_password" tabIndex="1" required autoFocus />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
                                Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
}

export default ChangePassword;