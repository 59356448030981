import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { RWebShare } from "react-web-share";
import BookLoader from "../components/book-loader";
import { addToCart } from "../utils/cart";
import styled from "styled-components";
import { getCategories, getTags } from "../utils/get-categories";
import http from "../utils/http";
import { shuffle } from "lodash";
// import shuffle from 'array-shuffle';
import { useCart } from "react-use-cart";
import Select from "react-select";

function BookItems({ books }) {
  const { addItem } = useCart();

  const likeBook = async (e, id) => {
    e.preventDefault();
    const { data } = await http.post(`article/${id}/like`);
    if (data.data) {
      toast.success("Book liked");
    }
  };

  const unlikeBook = async (e, id) => {
    e.preventDefault();
    const { data } = await http.post(`article/${id}/unlike`);
    if (data.data) {
      toast.success("Book unliked");
    }
  };

  const addToCarts = (e, book) => {
    e.preventDefault();
    const result = addItem(book);
    toast.info(result);
  };
  return (
    <>
      <div className="booksmedia-fullwidth">
        <ul>
          {!books ? (
            <BookLoader />
          ) : (
            books.map((book, idx) => (
              <li key={idx}>
                <Link to={"/book/" + book.id}>
                  <figure>
                    <Link to={"/book/" + book.id}>
                      <img src={book.bookCoverUrl} alt="Book" />
                    </Link>
                    <figcaption>
                      <header>
                        <h4>
                          <Link to={"/book/" + book.id}>{book.title}</Link>
                        </h4>
                        <p>
                          <strong>Author:</strong> {book.author}
                        </p>
                        <p>
                          <strong>ISBN:</strong> {book.isbn}
                        </p>
                        <p>
                          <strong>Cost:</strong> From ₦{book.softCopyAmount}
                        </p>
                      </header>
                      <p>{}</p>
                      <div className="actions">
                        <ul>
                          <li>
                            <Link
                              onClick={(e) =>
                                addToCarts(e, {
                                  ...book,
                                  price: book.softCopyAmount,
                                  type: "book",
                                  objectType: "book",
                                  bookType: "soft",
                                  id: `${book.id} soft`,
                                })
                              }
                              target="_blank"
                              data-toggle="blog-tags"
                              data-placement="top"
                              title="Add TO CART"
                            >
                              <i className="fa fa-shopping-cart"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={(e) => likeBook(e, book.id)}
                              data-placement="top"
                              title="Like"
                            >
                              <i className="fa fa-heart"></i>
                            </Link>
                          </li>

                          <li>
                            <RWebShare
                              data={{
                                text: book.title,
                                url: "https://kokawe.com/book/" + book.id,
                                title: book.title,
                              }}
                              onClick={() =>
                                toast.success("shared successfully!")
                              }
                            >
                              <i className="fa fa-share-alt"></i>
                            </RWebShare>
                          </li>
                        </ul>
                      </div>
                    </figcaption>
                  </figure>
                </Link>
              </li>
            ))
          )}
        </ul>
      </div>
    </>
  );
}

function Books() {
  const itemsPerPage = 12;
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [tag, setTag] = useState('');
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [books, setBooks] = useState([]);

  const getCat = async () => {
    let cont = [];
    const { data } = await getCategories();
    shuffle(data);
    for (let i = 0; i < data.length; i++) {
      cont.push(data[i].name);
    }
    setCategories(cont);
  };

  const getBooks = async () => {
    let cont = [];
    const data = await http.get("books/all");
    for (let i = 0; i < data.data.data.length; i++) {
      cont.push(data.data.data[i]);
    }
    const shuffledArray = shuffle(cont);
    setBooks(shuffledArray);
  };
  
  const getTage = async () => {
    let cont = [];
    const { data } = await getTags();
    for (let i = 0; i < data.length; i++) {
      cont.push(data[i].name);
    }
    setTags(cont);
  };
  
  useEffect(() => {
    getCat();
    getBooks();
    getTage();
  }, []);

  const searchBooks = async (e) => {
    e.preventDefault();
    let payload = {
        category: category,
        title: title,
        tag: tag
    }
    console.log(payload)
    const { data } = await http.post('books/search', payload);
    setBooks(data)
  }

  const [itemOffset, setItemOffset] = useState(0);
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = books.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(books.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % books.length;
    setItemOffset(newOffset);
  };

  const categoryOptions = [{ value: "", label: "select category" }];
  categories.forEach((cat) => {
    categoryOptions.push({ value: cat.id, label: cat.name });
  });

  const tagOptions = [{ value: "", label: "select tags" }];
  tags.forEach((tg) => {
    tagOptions.push({ value: tg.id, label: tg.name });
  });

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>Books</h2>
            <span className="underline center"></span>
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>Books</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="books-full-width">
              <div className="container">
                <section className="search-filters">
                  <div className="container">
                    <div className="filter-box">
                      <h3>What are you looking for at the library?</h3>
                      <form onSubmit={(e) => searchBooks(e)} method="post">
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="sr-only" htmlFor="keywords">
                              Search by Keyword
                            </label>
                            <input
                              className="form-control"
                              style={{backgroundColor: "white", color: 'grey', border: 'none', height: '35px'}}
                              placeholder="Search by Keyword"
                              id="keywords"
                              type="text"
                              value={title}
                              name="title"
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <Select options={tagOptions} name="tag" />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <Select options={categoryOptions} name="category" />
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                          <div className="form-group">
                            <input
                              className="form-control"
                              style={{height: '35px'}}
                              type="submit"
                              value="Search"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>

                <BookItems books={currentItems} />
                <section>
                  <nav
                    aria-label="Page navigation comments"
                    className="my-2 mx-auto"
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      marginRight: "auto",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      marginPagesDisplayed={2}
                      containerClassName="pagination mx-auto justify-content-center"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      // eslint-disable-next-line no-unused-vars
                      hrefBuilder={(page, pageCount, selected) =>
                        page >= 1 && page <= pageCount ? `/page/${page}` : "#"
                      }
                      hrefAllControls
                      // forcePage={currentPage}
                    />
                  </nav>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Books;
