import { Box, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import http from "../../utils/http";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import { Grid, ListItem, Stack } from "@mui/joy";

const AuthorWallet = () => {
  const [profile, setProfile] = useState();
  const [amount, setAmount] = useState(0);
  const [code, setCode] = useState();
  const [id, setID] = useState(0);
  const [openCodeModal, setOpenCodeModal] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpenCodeModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const getProfile = async () => {
      await http
        .get("profile")
        .then((data) => {
          setProfile(data.data.data[0]);
          // console.log(data)
        })
        .catch((err) => {
          // console.log(err);
        });
    };
    getProfile();
  }, []);

  const withdraw = async (e) => {
    e.preventDefault();
    // setOpenCodeModal(true);
    let postData = {
      amount: amount,
    };
    const { data, status } = await http.post("withdraw", postData);
    if (status !== 200) {
      console.log(data);
      toast.error(data.data);
      setOpen(false);
    }
    if (status === 200) {
      toast.info("Enter the code sent to your registered mail");
      setID(data.data);
      setOpenCodeModal(true);
    }
  };

  const completeWithdrawal = async (e) => {
    e.preventDefault();
    const { data, status } = await http.post("withdraw/verify", {
      code: code,
      id: id,
    });
    if (status !== 201) {
      toast.error(data.data);
      setOpenCodeModal(false);
      setOpen(false);
    }
    if (status === 201) {
      toast.success(data.data);
      setOpenCodeModal(false);
      setOpen(false);
    }
  };

  return (
    <>
      <Stack>
        <Typography level="h2" fontSize="20px" sx={{ mb: 0.5 }}>
          My Wallet
        </Typography>

        <Box>
          <Grid container spacing={3} sx={{ flexGrow: 1 }}>
            <Grid lg={4} md={4} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    Wallet Balance
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    {!profile ? "" : profile.wallet.balance}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={handleOpen}
                    data-toggle="modal"
                    data-target="#exampleModal"
                    sx={{
                      fontSize: "15px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    Withdraw
                  </Button>
                </Box>
              </Sheet>
            </Grid>
            <Grid lg={4} md={4} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    Referral
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    0
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
            <Grid lg={4} md={4} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    Referral link
                  </Typography>
                  <Typography level="h4" fontSize="15px" sx={{ mb: 0.5 }}>
                    kokawe.com/register?refered_by=$
                    {!profile ? "" : profile.referral_code}{" "}
                  </Typography>
                </Box>
                <Box>
                  <CopyToClipboard
                    text={`kokawe.com/register?refered_by=${
                      !profile ? "" : profile.referral_code
                    }`}
                    onCopy={() => toast.success("copied")}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleOpen}
                      data-toggle="modal"
                      data-target="#exampleModal"
                      sx={{
                        fontSize: "15px",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>
                </Box>
              </Sheet>
            </Grid>
          </Grid>
        </Box>
        <div className="main-content" style={{ padding: "40px 0px" }}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="card card-statistic-1">
                <div className="card-wrap">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Enter Withdrawal Amount
                      </Typography>
                      <TextField
                        style={{ margin: "20px 0px", fontSize: "20px" }}
                        id="outlined-basic"
                        label="Amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        variant="outlined"
                      />
                      <br />
                      <Button onClick={withdraw}>Submit</Button>
                    </Box>
                  </Modal>

                  <Modal
                    open={openCodeModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Enter Code
                      </Typography>
                      <TextField
                        style={{ margin: "20px 0px", fontSize: "20px" }}
                        id="outlined-basic"
                        label="Code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        variant="outlined"
                      />
                      <br />
                      <Button onClick={completeWithdrawal}>
                        Complete withdrawal
                      </Button>
                    </Box>
                  </Modal>
                  <div
                    class="modal fade"
                    tabindex="-1"
                    role="dialog"
                    id="exampleModal"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">Enter Withdrawal amount</h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body"></div>
                        <div class="modal-footer bg-whitesmoke br">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button type="button" class="btn btn-primary">
                            Withdraw
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Stack>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="card" style={{ margin: "20px 10px" }}>
                  <div className="card-header">
                    <h4>Transaction History</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-md v_center">
                        <tr>
                          <th>#</th>
                          <th>Amount</th>
                          <th>From</th>
                          <th></th>
                          {/* <th>User Type</th> */}
                          {/* <th>Action</th> */}
                        </tr>
                        {/* <tbody>
                                                  {
                                                      users ? users.map((user, idx) =>
                                                          <tr key={idx}>
                                                              <td>{idx + 1}</td>
                                                              <td>{user.name}</td>
                                                              <td>{user.email}</td>
                                                              <td>{user.phone}</td>
                                                              <td>{user.user_type}</td>
                                                          </tr>
                                                      ) : ""
                                                  }
                                              </tbody> */}
                      </table>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <nav className="d-inline-block">
                      <ul className="pagination mb-0">
                        <li className="page-item disabled">
                          <Link className="page-link" to="#" tabIndex="-1">
                            <i className="fas fa-chevron-left"></i>
                          </Link>
                        </li>
                        <li className="page-item active">
                          <Link className="page-link" to="#">
                            1 <span className="sr-only">(current)</span>
                          </Link>
                        </li>
                        {/* <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                              <li className="page-item"><Link className="page-link" to="#">3</Link></li> */}
                        <li className="page-item disabled">
                          <Link className="page-link" to="#">
                            <i className="fas fa-chevron-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </Stack>
        </div>
      </Stack>

      <ToastContainer />
    </>
  );
};

export default AuthorWallet;
