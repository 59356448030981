import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { RWebShare } from "react-web-share";
import BookLoader from "../components/book-loader";
import { fetchArticles } from "../utils/get-articles";
import { getCategories, getTags } from "../utils/get-categories";
import http from "../utils/http";

function ArticleItems({ articles }) {

    const likeArticle = async (e, id) => {
        e.preventDefault();
        const { data } = await http.post(`article/${id}/like`)
        if (data.data) {
            toast.success('Article liked')
        }
    }

    const unlikeArticle = async (e, id) => {
        e.preventDefault();
        const { data } = await http.post(`article/${id}/unlike`)
        if (data.data) {
            toast.success('Article unliked')
        }
    }

    return (
        <>
            <div className="booksmedia-fullwidth">
                <ul>
                    {
                        !articles ? <BookLoader /> : articles.map((article, idx) =>
                            <li key={idx}>
                                <Link to={"/article/" + article.id}>
                                    <figure>
                                        <Link to={"/article/" + article.id}><img src={article.articleCoverUrl} alt="Book" /></Link>
                                        <figcaption>
                                            <header>
                                                <h4><Link to={"/article/" + article.id}>{article.title}</Link></h4>
                                                <p><strong>Writer:</strong> {article.author}</p>
                                                {/* <p><strong>ISBN:</strong> {article.isbn}</p> */}
                                            </header>
                                            <p>{ }</p>
                                            <div className="actions">
                                                <ul>

                                                    <li>
                                                        <Link onClick={(e) => likeArticle(e, article.id)} data-placement="top" title="Like">
                                                            <i className="fa fa-heart"></i>
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <RWebShare
                                                            data={{
                                                                text: article.title,
                                                                url: "https://kokawe.com/article/" + article.id,
                                                                title: article.title
                                                            }}
                                                            onClick={() => toast.success("shared successfully!")}

                                                        >
                                                            <i className="fa fa-share-alt"></i>
                                                        </RWebShare>
                                                    </li>
                                                </ul>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </div>
        </>
    )
}

function Articles() {
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    // const [likeStatus, setLikeStatus] = useState()
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const getCat = async () => {
            let cont = [];
            const { data } = await getCategories();
            // console.log(data[0])
            for (let i = 0; i < data.length; i++) {
                cont.push(data[i].name)
            }
            setCategories(cont);
        }
        getCat()
    }, [])

    useEffect(() => {
        const getTage = async () => {
            let cont = [];
            const { data } = await getTags();
            for (let i = 0; i < data.length; i++) {
                cont.push(data[i].name)
            }
            setTags(cont);
        }
        getTage();
    }, [])

    useEffect(() => {
        const getArts = async () => {
            await http.get('articles/all')
                .then((data) => {
                    let cont = [];
                    for (let i = 0; i < data.data.data.length; i++) {
                        cont.push(data.data.data[i])
                    }
                    setArticles(cont);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        getArts();
    }, [])

    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>Articles</h2>
                        <span className="underline center"></span>
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>Articles</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <div className="books-full-width">
                            <div className="container">
                                <section className="search-filters">
                                    <div className="filter-box">
                                        <h3>What are you looking for at the library?</h3>
                                        <form action="" method="get">
                                            <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control" placeholder="Search by Keyword" id="keywords" name="keywords" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-sm-6">
                                                <div className="form-group">
                                                    <select name="tags" id="tags" className="form-control">
                                                        {
                                                            !tags ? (<option>Search the Catalog</option>) : tags.map((tag, idx) => {
                                                                <option key={idx} value={tag.name}>{tag.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-sm-6">
                                                <div className="form-group">
                                                    <select name="category" id="category" className="form-control">
                                                        {
                                                            !categories ? (<option>Search the Categories</option>) : categories.map((cat, idx) => {
                                                                <option key={idx} value={cat.name}>{cat.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control" type="submit" value="Search" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="clear"></div>
                                </section>
                                <ArticleItems articles={articles} />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default Articles;