import { Box, Button, Sheet, Table, Typography } from "@mui/joy";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import http from "../../utils/http";

const AdminPercentages = () => {
  const [authorPercentage, setAuthorPercentage] = useState("");
  const [referralPercentage, setReferralPercentage] = useState("");
  const [marketing, setMarketing] = useState("");
  const [nothing, setNothing] = useState("");
  const [platform, setPlatform] = useState("");
  const [website, setWebsite] = useState("");
  const [percentages, setPercentages] = useState([]);

  const addPercentage = async (e) => {
    e.preventDefault();
    let postData = {
      authorPercentage: authorPercentage,
      referralPercentage: referralPercentage,
      marketing: marketing,
      website: website,
      platform: platform,
    };
    const { data } = await http.post("admin/percentages", postData);
    console.log(data);
    toast.success(data.data);
    setAuthorPercentage("");
    setReferralPercentage("");
    setWebsite("");
    setPlatform("");
  };

  const deletePercentage = async (e, id) => {
    e.preventDefault();
    const { data } = await http.delete("admin/percentage/" + id);
    toast.success(data.data);
    setNothing("");
  };

  useEffect(() => {
    const getPercentages = async () => {
      let cont = [];
      const data = await http.get("admin/percentages");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      setPercentages(cont);
    };
    getPercentages();
  }, []);

  return (
    <>
      <Typography
        level="h1"
        sx={{
          pb: 3,
          fontFamily: "montserrat",
        }}
      >
        Percentages
      </Typography>

      <div className="main-content" style={{ padding: "40px 0px" }}>
        <section className="section">
          <div className="section-header">
            <h1>Percentages</h1>
          </div>
          <form onSubmit={(e) => addPercentage(e)} method="post">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ margin: "20px 10px" }}>
                  <div className="card-header">
                    <h4>Add Percentages</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Referral Percentage
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <input
                          type="text"
                          value={referralPercentage}
                          onChange={(e) =>
                            setReferralPercentage(e.target.value)
                          }
                          name="referralPercentages"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Creator Percentage
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <input
                          type="text"
                          value={authorPercentage}
                          onChange={(e) => setAuthorPercentage(e.target.value)}
                          name="authorPercentages"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Marketing Percentage
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <input
                          type="text"
                          value={marketing}
                          onChange={(e) => setMarketing(e.target.value)}
                          name="marketing"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Website Percentage
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <input
                          type="text"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                          name="website"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Platform Percentage
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <input
                          type="text"
                          value={platform}
                          onChange={(e) => setPlatform(e.target.value)}
                          name="platform"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className="btn btn-primary">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <Box sx={{ width: "100%" }}>
            <Sheet
              variant="outlined"
              sx={{
                "--TableCell-height": "40px",
                // the number is the amount of the header rows.
                "--TableHeader-height": "calc(1 * var(--TableCell-height))",
                "--Table-firstColumnWidth": "80px",
                "--Table-lastColumnWidth": "144px",
                // background needs to have transparency to show the scrolling shadows
                "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
                "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
                overflow: "auto",
                background: (theme) =>
                  `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
                backgroundSize:
                  "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "local, local, scroll, scroll",
                backgroundPosition:
                  "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
                backgroundColor: "background.surface",
              }}
            >
              <Table
                borderAxis="bothBetween"
                stripe="odd"
                hoverRow
                sx={{
                  "& tr > *:first-child": {
                    position: "sticky",
                    left: 0,
                    boxShadow: "1px 0 var(--TableCell-borderColor)",
                    bgcolor: "background.surface",
                  },
                  "& tr > *:last-child": {
                    position: "sticky",
                    right: 0,
                    bgcolor: "var(--TableCell-headBackground)",
                  },
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "var(--Table-firstColumnWidth)" }}>
                      #
                    </th>
                    <th style={{ width: 200 }}>Author Percentage</th>
                    <th style={{ width: 200 }}>Referral Percentage</th>
                    <th style={{ width: 200 }}>Date Created</th>
                    <th style={{ width: 200 }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {percentages
                    ? percentages.map((percent, idx) => (
                        <tr>
                          <td>{percent.author}</td>
                          <td>{percent.referral}</td>
                          <td>{percent.created_at}</td>

                          <td>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Button
                                onClick={(e) => deletePercentage(e, percent.id)}
                                size="sm"
                                variant="plain"
                                color="neutral"
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={(e) => deletePercentage(e, percent.id)}
                                size="sm"
                                variant="soft"
                                color="danger"
                              >
                                Delete
                              </Button>
                            </Box>
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </Table>
            </Sheet>
          </Box>
        </section>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminPercentages;
