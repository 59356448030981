import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ShareOutlined } from "@mui/icons-material";
import styled from "styled-components";
import http from "../utils/http";
import user from "../assets/user.jpeg";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  MenuItem,
  Rating,
  Select,
  Typography,
} from "@mui/material";
import ReactPaginate from "react-paginate";
// import Select from "react-select";
import { shuffle } from "lodash";
import { RWebShare } from "react-web-share";
import BookingModal from "../components/bookingModal";

function TeacherItem({ teachers, title }) {
  const [modalStatus, setModalStatus] = useState(false);
  // const [searchedTeachers, setSearchedTeachers] = useState([]);
  const navigate = useNavigate();

  // const searchTeachers = () => {
  //   let newTeachers = [];
  //   teachers.find((teacher) => {
  //     let result = teacher.includes(title);
  //     newTeachers.push(result);
  //   });
  //   setSearchedTeachers(newTeachers);
  // };

  return (
    <div className="booksmedia-fullwidth">
      <ul style={{ margin: "40px 0px" }}>
        {!teachers
          ? ""
          : teachers.map((teacher, idx) => (
              <li key={idx}>
                <Card sx={{ maxWidth: 400 }}>
                  <Link to={`/teacher/${teacher.id}`}>
                    <Box
                      height="100"
                      sx={{
                        backgroundImage: `url(${teacher.photo ?? user})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        minHeight: "350px",
                        textAlign: "center",
                        padding: 5,
                      }}
                    >
                    </Box>
                  </Link>
                  <CardContent>
                    <Link to={`/teacher/${teacher.id}`}>
                      <Typography variant="h4" color="black">
                        {teacher.name}
                      </Typography>
                    </Link>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        variant="subtitle1"
                        sx={{ paddingY: "10px" }}
                        color="text.secondary"
                      >
                        {teacher.subjects.substring(0, 100) + "..."}
                      </Typography>
                      <Box>
                        <Rating name="read-only" precision={0.2} value={teacher.rating} readOnly />
                        <Typography
                          variant="subtitle1"
                          sx={{ paddingY: "0px" }}
                          color="text.secondary"
                        >
                          ({teacher.reviews ?? 0} Reviews)
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="h5"
                      sx={{ padding: "10px", fontWeight: "600" }}
                    >
                      ₦{teacher.amount}/mo
                    </Typography>
                    <CardActions disableSpacing>
                      <Button aria-label="share">
                        <RWebShare
                          data={{
                            text: teacher.name,
                            url: "https://kokawe.com/teacher/" + teacher.id,
                            title: teacher.name,
                          }}
                          onClick={() => toast.success("shared!")}
                        >
                          <ShareOutlined />
                        </RWebShare>
                      </Button>
                      <Button
                        aria-label="book"
                        onClick={(e) => navigate(`/teacher/${teacher.id}`)}
                      >
                        View Profile
                      </Button>
                      <Button
                        aria-label="book"
                        onClick={(e) => setModalStatus(true)}
                      >
                        Book Me
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
                <BookingModal teacher={teacher} status={modalStatus} />
              </li>
            ))}
      </ul>
    </div>
  );
}

function Teachers() {
  const itemsPerPage = 12;
  const [title, setTitle] = useState("");
  const [teachers, setteachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subject, setSubject] = useState('');

  const getteachers = async () => {
    const teachers = await http.get("teachers");
    let shuffledData = shuffle(teachers.data);
    setteachers(shuffledData);
  };
  
  const getSubjects = async () => {
    const {data} = await http.get("subjects");
    setSubjects(data.data);      
  }
  useEffect(() => {
    getteachers();
    getSubjects();
  }, []);

  const MyPaginate = styled(ReactPaginate).attrs({
    // You can redefine classes here, if you want.
    activeClassName: "active", // default to "selected"
  })`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 5rem;
    li a {
      border-radius: 7px;
      padding: 0.1rem 1rem;
      border: gray 1px solid;
      cursor: pointer;
    }
    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
    }
    li.active a {
      background-color: #0366d6;
      border-color: transparent;
      color: white;
      min-width: 32px;
    }
    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  `;

  const [itemOffset, setItemOffset] = useState(0);
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = teachers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(teachers.length / itemsPerPage);

  const searchteachers = async (e) => {
    e.preventDefault();
    const {data} = await http.post('teachers/search', {subject: subject, keyword: title});
    console.log(data)
    setteachers(data);

  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % teachers.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>teachers</h2>
            <span className="underline center"></span>
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>Teachers</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="teachers-full-width">
              <div className="container">
                <section className="search-filters mx-auto">
                  <div className="container">
                  <div className="filter-box">
                      <h3>What are you looking for at the library?</h3>
                      <form onSubmit={(e) => searchteachers(e)} method="post">
                        <div className="col-md-5 col-sm-4">
                          <div className="form-group">
                            <label className="sr-only" htmlFor="keywords">
                              Search by Keyword
                            </label>
                            <input
                              className="form-control"
                              style={{backgroundColor: "white", color: 'grey', border: 'none', height: '35px'}}
                              placeholder="Search by Keyword"
                              id="keywords"
                              type="text"
                              value={title}
                              name="title"
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="form-group">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{width: {lg: 300, xs: '100%'}, backgroundColor: 'white'}}
                            value={subject}
                            defaultValue={'Select Subject'}
                            label="Subject"
                            onChange={(e) => setSubject(e.target.value)}
                          >
                                <MenuItem value="Select Subject">Select Subject</MenuItem>                            
                            {
                              subjects.length > 0 ? subjects.map((sub, idx) => 
                                <MenuItem id={sub} key={idx+1} value={sub.name}>{sub.name}</MenuItem>                            
                              ) : <MenuItem value="">No subject available</MenuItem>
                            }
                          </Select>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              style={{height: '35px'}}
                              type="submit"
                              value="Search"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
                <TeacherItem
                  teachers={ currentItems }
                />
                <nav
                  aria-label="Page navigation comments"
                  className="my-2 mx-auto"
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    marginBottom: "30px",
                    marginRight: "auto",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination mx-auto justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    // eslint-disable-next-line no-unused-vars
                    hrefBuilder={(page, pageCount, selected) =>
                      page >= 1 && page <= pageCount ? `/page/${page}` : "#"
                    }
                    hrefAllControls
                    // forcePage={currentPage}
                  />
                </nav>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Teachers;
