import React, { useEffect } from "react";
import logo from "../assets/logo.png";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getProfile } from "../utils/get-profile";
import { getCart } from "../utils/cart";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import background from "../assets/course_background.jpeg";
import ListItemText from "@mui/material/ListItemText";
import { useCart } from "react-use-cart";
import { Typography, useMediaQuery } from "@mui/material";
import AppbarMobile from "./appMobile";

export function AccountMenu() {
  const { items, removeItem, totalUniqueItems, cartTotal } = useCart();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const matches = useMediaQuery("(min-width:600px)");
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box>
        <Tooltip title="cart">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {matches ? (
              <i
                style={{ color: "gainsboro", fontSize: "18px" }}
                className="fa fa-shopping-cart"
              ></i>
            ) : (
              <i
                style={{ color: "black", fontSize: "20px" }}
                className="fa fa-shopping-cart"
              ></i>
            )}
            <Avatar
              sx={{
                width: 15,
                height: 15,
                backgroundColor: "green",
                top: "-10px",
              }}
            >
              {totalUniqueItems}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: "fit-content",
              height: "100%",
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box style={{ margin: "10px" }}>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {items.map((item, idx) => (
              <ListItem key={idx + 1}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  {item.type === "course" ? (
                    <Box
                      height="194"
                      sx={{
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        minHeight: "100px",
                        textAlign: "center",
                        padding: 5,
                      }}
                    >
                      <Typography
                        variant="body2"
                        marginTop={1}
                        color="white"
                        sx={{ fontWeight: "400" }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  ) : (
                    <img
                      width="80px"
                      src={
                        item.bookCoverUrl ??
                        item.video_cover_url ??
                        item.audio_cover_url
                      }
                      alt="cart item"
                    />
                  )}
                  <Box>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", padding: "0px 30px" }}
                    >
                      {item.name ?? item.title}{" "}
                    </Typography>
                    <div
                      style={{ fontWeight: "300", padding: "0px 30px" }}
                      className="author"
                    >
                      <strong>Author:</strong> {item.author ?? item.owner}
                    </div>
                    <div style={{ fontWeight: "200", padding: "0px 30px" }}>
                      {item.quantity} X {item.price}
                    </div>
                  </Box>
                  <Link className="remove" onClick={(e) => removeItem(item.id)}>
                    <i className="fa fa-trash-o"></i>
                  </Link>
                </Box>
              </ListItem>
            ))}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              style={{ margin: "10px" }}
            >
              <div style={{ fontWeight: "bold" }} className="title">
                Total
              </div>
              <div className="price">₦{cartTotal}</div>
            </Box>
            <Link to="/cart" className="btn btn-primary">
              View Cart
            </Link>
          </List>
        </Box>
      </Menu>
    </React.Fragment>
  );
}

function Header() {
  const location = useLocation();
  const { items, removeItem } = useCart();
  const [cart, setCart] = useState([]);
  const [loggedIn, setLoggedIn] = useState();
  const [profile, setProfile] = useState();

  const matches = useMediaQuery("(max-width:800px)");

  useEffect(() => {
    const Profile = async () => {
      let myProfile = await getProfile();
      if (myProfile.length !== 0 && myProfile !== "Unauthenticated.") {
        setLoggedIn(true);
        setProfile(myProfile.data.data[0]);
      }
    };
    Profile();
  }, []);

  useEffect(() => {
    const books = getCart();
    setCart(books);
  }, []);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          { text: "Home", href: "/home" },
          { text: "About", href: "/home#about" },
          { text: "Books", href: "/books" },
          { text: "Events", href: "/events" },
          { text: "Articles", href: "/articles" },
          { text: "Media", href: "/medias" },
          // { text: "Online Course", href: "/courses" },
          // { text: "Authors", href: "/authors" },
          { text: "Teachers", href: "/teachers" },
          { text: "Services", href: "/services" },
          { text: "Contact", href: "/contact" },
        ].map((text, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton href={text.href}>
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={text.text}
                primaryTypographyProps={{ fontSize: "1.3rem" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <Divider /> */}
      {profile ? (
        <List>
          {profile.user_type === "Publisher" ? (
            <ListItem disablePadding>
              <ListItemButton href={"/aggregator-dashboard"}>
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary={"Dashboard"}
                  primaryTypographyProps={{ fontSize: "1.3rem" }}
                />
              </ListItemButton>
            </ListItem>
          ) : profile.user_type === "Author" ? (
            <ListItem disablePadding>
              <ListItemButton href={"/dashboard"}>
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary={"Dashboard"}
                  primaryTypographyProps={{ fontSize: "1.3rem" }}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            ""
          )}
          {[
            { text: "My Books", href: "/reader-books" },
            { text: "My Media", href: "/user-media" },
            { text: "My Courses", href: "/user-course" },
            { text: "Logout", href: "/logout" },
          ].map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton href={text.href}>
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary={text.text}
                  primaryTypographyProps={{ fontSize: "1.3rem" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <List disablePadding>
          {[{ text: "Login/Register", href: "/login" }].map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton href={text.href}>
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary={text.text}
                  primaryTypographyProps={{ fontSize: "1.3rem" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );

  return (
    <>
      <header id="header" className="navbar-wrapper">
        <div className="container">
          <div className="row">
            <nav className="navbar navbar-default" style={{ left: "0px" }}>
              <div className="col-sm-12">
                <div className="header-topbar hidden-sm hidden-xs">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="topbar-info">
                        <Link to="tel:+2348025505812">
                          <i className="fa fa-phone"></i>+2348025505812
                        </Link>{" "}
                        &nbsp;
                        <Link to="tel:+447503859651">
                          <i className="fa fa-mobile"></i>+447503859651
                        </Link>
                        <span>/</span>
                        <Link to="mailto:support@kokawe.com">
                          <i className="fa fa-envelope"></i>support@kokawe.com
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="topbar-links">
                        {!loggedIn || !profile ? (
                          <Link to="/login">
                            <i className="fa fa-lock"></i>Login / Register
                          </Link>
                        ) : profile.user_type !== "User" ? (
                          <>
                            <Link to="/reader-books">My Books</Link>&nbsp;&nbsp;
                            <Link to="/user-media">My Media</Link>&nbsp;&nbsp;
                            <Link to="/user-course">My Courses</Link>
                            &nbsp;&nbsp;
                            <Link to="/my-appointments">Booked Classes</Link>
                            &nbsp;&nbsp;
                            <Link to="/dashboard">Dashboard</Link>&nbsp;&nbsp;
                            <Link to={"/logout"}>Logout</Link>
                          </>
                        ) : profile.user_type === "User" ? (
                          <>
                            <Link to="/reader-books">My Books</Link>&nbsp;&nbsp;
                            <Link to="/user-media">My Media</Link>&nbsp;&nbsp;
                            <Link to={"/logout"}>Logout</Link>
                          </>
                        ) : (
                          <Link to="/login">
                            <i className="fa fa-lock"></i>Login / Register
                          </Link>
                        )}
                        <span>|</span>
                        <div className="header-cart dropdown">
                          <AccountMenu />
                          <Link
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            to="/"
                          >
                            {/* <i className="fa fa-shopping-cart"></i>
                                                        <small>{totalUniqueItems}</small> */}
                          </Link>
                          <div className="dropdown-menu cart-dropdown">
                            <ul>
                              {!cart
                                ? "Cart is Empty"
                                : items.map((item, idx) => (
                                    <li key={idx} className="clearfix">
                                      {!item.bookCoverUrl &&
                                      !item.video_cover_url &&
                                      !item.audio_cover_url ? (
                                        <Box
                                          sx={{
                                            backgroundColor: "darkred",
                                            textAlign: "center",
                                            padding: 5,
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            color="white"
                                            sx={{ fontWeight: "400" }}
                                          >
                                            {item.name ?? item.title}
                                          </Typography>
                                        </Box>
                                      ) : (
                                        <>
                                          {item.type === "course" ? (
                                            <Box
                                              height="194"
                                              sx={{
                                                backgroundImage: `url(${background})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                                minHeight: "100px",
                                                textAlign: "center",
                                                padding: 5,
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                marginTop={1}
                                                color="white"
                                                sx={{ fontWeight: "400" }}
                                              >
                                                {item.name}
                                              </Typography>
                                            </Box>
                                          ) : (
                                            <img
                                              width="80px"
                                              src={
                                                item.bookCoverUrl ??
                                                item.video_cover_url ??
                                                item.audio_cover_url
                                              }
                                              alt="cart item"
                                            />
                                          )}
                                        </>
                                      )}
                                      <div className="item-info">
                                        <div className="name">
                                          <Link to={"/book/" + item.id}>
                                            {item.title ?? item.name}{" "}
                                          </Link>
                                        </div>
                                        <div className="author">
                                          <strong>Author:</strong>
                                          {item.author ?? item.owner}
                                        </div>
                                        {/* <div className="price">{book.qty} X {book.cost}</div> */}
                                      </div>
                                      <Link
                                        className="remove"
                                        onClick={(e) => removeItem(item.id)}
                                      >
                                        <i className="fa fa-trash-o"></i>
                                      </Link>
                                    </li>
                                  ))}
                            </ul>
                            {/* <div className="cart-total">
                                                            <div className="title">SubTotal</div>
                                                            <div className="price">0.00</div>
                                                        </div> */}
                            <div className="cart-buttons">
                              {/* <Link to="/cart" className="btn btn-dark-gray">View Cart</Link> */}
                              <Link to="/cart" className="btn btn-primary">
                                View Cart
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <li style={{textAlign: 'right', color: 'white', listStyle: 'none'}}><Link onClick={() => logout()}>Logout</Link></li> */}

                <div className="bg-light">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="navbar-header">
                        <div className="navbar-brand">
                          <h1>
                            <Link to="/">
                              <img src={logo} width="140" alt="kokawe logo" />
                            </Link>
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="navbar-collapse hidden-sm hidden-xs">
                        <ul className="nav navbar-nav">
                          <li
                            className={
                              location.pathname === "/home" ? "active" : ""
                            }
                          >
                            <Link to="/home">Home</Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/about" ? "active" : ""
                            }
                          >
                            <a href="/home#about">About</a>
                          </li>
                          <li
                            className={
                              location.pathname === "/books" ? "active" : ""
                            }
                          >
                            <Link to="/books">Books</Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/events" ? "active" : ""
                            }
                          >
                            <Link to="/events">Events</Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/teachers" ? "active" : ""
                            }
                          >
                            <Link to="/teachers">Teachers</Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/articles" ? "active" : ""
                            }
                          >
                            <Link to="/articles">Articles</Link>
                          </li>
                          <li
                            className={
                              location.pathname === "/medias" ? "active" : ""
                            }
                          >
                            <Link to="/medias">Media</Link>
                          </li>
                          {/* <li
                            className={
                              location.pathname === "/courses" ? "active" : ""
                            }
                          >
                            <Link to="/courses">Online Course</Link>
                          </li> */}
                          {/* <li className={location.pathname === '/authors' ? "active" : ''}><Link to="/authors">Authors</Link></li> */}
                          {/* <li
                            className={
                              location.pathname === "/services" ? "active" : ""
                            }
                          >
                            <Link to="/services">Services</Link>
                          </li> */}
                          <li
                            className={
                              location.pathname === "/contact" ? "active" : ""
                            }
                          >
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {matches ? <AppbarMobile matches={matches} /> : ""}

              <div className="mobile-menu hidden-lg hidden-md">
                <div>
                  <Box display={"flex"}>
                    {/* <AccountMenu /> */}
                    <div>
                      <Link onClick={toggleDrawer(true)}>
                        <i className="fa fa-navicon"></i>
                      </Link>
                      <Drawer
                        anchor={"right"}
                        open={state["right"]}
                        onClose={toggleDrawer(false)}
                      >
                        {list()}
                      </Drawer>
                    </div>
                  </Box>
                </div>

                <div id="mobile-menu">
                  <ul>
                    <li className="mobile-title">
                      <h4>Navigation</h4>
                      <Link to="/home" className="close"></Link>
                    </li>
                    <li>
                      <Link to="/home">Home</Link>
                    </li>
                    <li>
                      <a href="/home#about">About</a>
                    </li>
                    <li>
                      <Link to="/articles">Articles</Link>
                    </li>
                    <li>
                      <Link to="/books">Books</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/medias">Media</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/cart">Cart</Link>
                    </li>
                    {!loggedIn || !profile ? (
                      <li>
                        <Link to="/login">
                          <i className="fa fa-lock"></i>Login / Register
                        </Link>
                      </li>
                    ) : profile.user_type === "Author" ? (
                      <>
                        <li>
                          <Link to="/reader-books">My Books</Link>
                        </li>
                        <li>
                          <Link to="/user-media">My Media</Link>
                        </li>
                        {/* <li>
                          <Link to="/user-course">My Courses</Link>
                        </li> */}
                        <li>
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li>
                          <Link to={"/logout"}>Logout</Link>
                        </li>
                      </>
                    ) : profile.user_type === "User" ? (
                      <>
                        <li>
                          <Link to="/reader-books">My Books</Link>
                        </li>
                        <li>
                          <Link to="/user-media">My Media</Link>
                        </li>
                        {/* <li>
                          <Link to="/user-course">My Courses</Link>
                        </li> */}
                        <li>
                          <Link to={"/logout"}>Logout</Link>
                        </li>
                      </>
                    ) : profile.user_type === "Publisher" ? (
                      <>
                        <li>
                          <Link to="/reader-books">My Books</Link>
                        </li>
                        <li>
                          <Link to="/user-media">My Media</Link>
                        </li>
                        <li>
                          <Link to="/aggregator-dashboard">Dashboard</Link>
                        </li>
                        <li>
                          <Link to={"/logout"}>Logout</Link>
                        </li>
                      </>
                    ) : (
                      <li>
                        <Link to="/login">
                          <i className="fa fa-lock"></i>Login / Register
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
