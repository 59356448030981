import { Avatar, Box, Grid, Sheet, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";

function AdminDashboard() {
  const [articles, setArticles] = useState([]);
  const [books, setBooks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getArticles = async () => {
      let cont = [];
      const data = await http.get("admin/articles");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      setArticles(cont);
    };
    getArticles();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      let cont = [];
      const data = await http.get("admin/categories");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      setCategories(cont);
    };
    getCategories();
  }, []);

  useEffect(() => {
    const getTags = async () => {
      let cont = [];
      const { data } = await http.get("admin/tags");
      for (let i = 0; i < data.data.length; i++) {
        cont.push(data.data[i]);
      }
      setTags(cont);
    };
    getTags();
  }, []);

  useEffect(() => {
    const getBooks = async () => {
      let cont = [];
      const { data } = await http.get("admin/books");
      for (let i = 0; i < data.data.length; i++) {
        cont.push(data.data[i]);
      }
      setBooks(cont);
    };
    getBooks();
  }, []);

  useEffect(() => {
    const getProfile = async () => {
      await http
        .get("profile")
        .then((data) => {
          // console.log(data)
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getProfile();
  }, []);

  return (
    <>
      <Stack spacing={4} paddingY={2}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"}>
            <Box>
              <Avatar sx={{ p: 3, mr: 3 }} />
            </Box>
            <Box>
              <Typography level="h1" fontSize="xl4">
                Welcome Back, {!profile ? "" : profile.name}!
              </Typography>
              <Typography level="body1">
                This dashboard is a place to manage books, articles and more.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: 1, "& > *": { flexGrow: 1 } }}>
              {/* <Button
                variant="outlined"
                color="neutral"
                startDecorator={<i data-feather="download-cloud" />}
              >
                Download PDF
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                startDecorator={<i data-feather="table" />}
              >
                Download CSV
              </Button> */}
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={3} sx={{ flexGrow: 1 }}>
            <Grid lg={3} md={3} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    Wallet Balance
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    {!profile ? "" : profile.wallet.balance}
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
            <Grid lg={3} md={3} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    Total Books
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    {books.length}
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
            <Grid lg={3} md={3} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    Total Articles
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    {articles.length}
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
            <Grid lg={3} md={3} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    Active Consultations
                  </Typography>
                  <Typography level="subtitle" sx={{ mb: 0.5 }}>
                    coming shortly....
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

export default AdminDashboard;
