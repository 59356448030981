import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";
import {toast, ToastContainer} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from "@mui/joy";


const AuthorArticles = () => {
    // toast..configure();
    const [articles, setArticles] = useState([]);

    const getArticles = async () => {
        let cont = []
        const data = await http.get('article');
        for(let i = 0; i < data.data.data.length; i++) {
            cont.push(data.data.data[i])
        }
        setArticles(cont);
    }
    useEffect(() => {
        getArticles();
    }, [])

    const deleteArticles = async (e, id) => {
        e.preventDefault();
        await http.delete('article/' + id)
        .then((data) => {
            getArticles();
            toast.success(data.data.data);
        })
        .catch(err => {
            toast.error(err.response.data.message)
        })
    }
    
    return (
        <>
            {/* <div className="main-content" style={{padding: '0px 0px'}}> */}
                <section className="">                    
                    <div className="section-body">
                        <Typography level="h2"  textAlign="center" sx={{ pb: 2 }}>
                            Articles
                        </Typography>
                        <h2 className="section-title">Articles</h2>
                        {/* <p className="section-lead">This article component is based on card and flexbox.</p> */}

                        <div className="row">
                            {
                                articles.length > 0 ? articles.map((art, idx) => 
                                    (
                                        <div key={idx} className="col-12 col-sm-6 col-md-6 col-lg-3">
                                            <article className="article">
                                                <div className="article-header">
                                                    <div className="article-image" data-background={art.articleCoverUrl}>
                                                    </div>
                                                    <div className="article-title">
                                                        <h2><Link to="#">{art.title}</Link></h2>
                                                    </div>
                                                </div>
                                                <div className="article-details">
                                                    <p>{art.body.slice(0, 100) + '...'} </p>
                                                    <div className="article-cta">
                                                        <Link to={'/author/article/'+art.id} className="btn btn-primary">Edit</Link>
                                                        <Link onClick={(e) => deleteArticles(e, art.id)} className="btn btn-danger">Delete</Link>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>  
                                    )
                                ) : ''
                            }                                                      
                        </div>
                    </div>
                </section>
            {/* </div> */}
            <ToastContainer />
        </>
    )
}

export default AuthorArticles;
