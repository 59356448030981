import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import http from "../utils/http";

function CalendlyAuth() {
    const location = useLocation();
    const search = location.search;

    const params = new URLSearchParams(search);
    const code = params.get('code');

    useEffect(() => {
        const getCalendlyCode = async () => {
            await http.post('/get_calendly_code', {code: code})
            .then((data) => {
                console.log(code)
            })
            .catch((err) => {
            })
            console.log(code);
        }
        getCalendlyCode();
    }, [code])

    return (
        <>
            <h1>Testing Calendly Auth</h1>
        </>
    )
}

export default CalendlyAuth;