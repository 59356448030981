import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import http from "../utils/http";
import parse from 'html-react-parser';
import useScript from "../utils/useScript";
import { toast, ToastContainer } from "react-toastify";
import { RWebShare } from "react-web-share";
import { Comment } from "react-loader-spinner";
import ArticleLoader from "../components/article-loader";

function Article2() {
    let param = useParams();
    const [article, setArticle] = useState();
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    useEffect(() => {
        const getArticle = async () => {
            await http.get('articles/'+param.id)
            .then((data) => {
                console.log(data)
                setArticle(data.data.data[0])
                setComments(data.data.data[0].comments)
            })
            .catch((err) => {
            })
        }
        getArticle();
    }, [])

    const likeArticle = async (e, id) => {
        e.preventDefault();
        const {data} = await http.post(`article/${id}/like`)
        if (data.data) {
            toast.success('Article liked')
        }
    }

    const unlikeArticle = async (e, id) => {
        e.preventDefault();
        const {data} = await http.post(`article/${id}/unlike`)
        if (data.data) {
            toast.success('Article unliked')
        }
    }

    const postComment = async (e) => {
        e.preventDefault();
        let postData = {
            comments: comment
        }

        const {data} = await http.post(`article/${param.id}/comment`, postData);
        if (data.data) {
            toast.success(data.data)
            setComment('')
        } else toast.success('something went wrong')
    }

    useScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7034524516564660')

    return (
        <>
            {/* <Header /> */}
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>Article</h2>
                        <span className="underline center"></span>
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li><Link to="/articles">Articles</Link></li>
                            <li>Article</li>
                        </ul>
                    </div>
                </div>
            </section>
            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <div className="blog-detail-main">
                            <div className="container">
                                <div className="row">
                                    <div className="blog-page">
                                        <div className="blog-section">
                                            {
                                                !article ? (<ArticleLoader />) : (
                                                    <article>
                                                        <div className="blog-detail">
                                                            <header className="entry-header">
                                                                <div className="blog_meta_category">
                                                                    {
                                                                        article != null ? article.categories.map((cat, idx) => 
                                                                            <><Link to="" key={idx} rel="category tag">{cat.name}</Link> ,</>
                                                                        ) : ''
                                                                    }
                                                                </div>
                                                                <h2 className="entry-title">{article != null ? article.title : ''}</h2>
                                                                <div className="entry-meta">
                                                                    <span><i className="fa fa-user"></i> <Link to="">{article != null ? article.author : ''}</Link></span>
                                                                </div>
                                                            </header>
                                                            <div className="post-thumbnail">
                                                                <div className="post-date-box">
                                                                    <div className="post-date">
                                                                        <Link className="date" to="">{article == null ? '' : article.created_at.slice(8, 10)}</Link>
                                                                    </div>
                                                                    <div className="post-date-month">
                                                                        <Link className="month" to="">{article == null ? '' : article.created_at.slice(5, 7)}</Link>
                                                                    </div>
                                                                    <div className="post-date">
                                                                        <Link className="date" to="">{article == null ? '' : article.created_at.slice(2, 4)}</Link>
                                                                    </div>
                                                                </div>
                                                                <figure>
                                                                    <img alt="article background" src={article != null ? article.articleCoverUrl : ''} />
                                                                </figure>
                                                            </div>
                                                            <div className="post-detail" style={{margin: '80px 30px'}}>
                                                                <div className="post-detail-head">
                                                                    <div className="post-share">
                                                                        <Link to=""><i className="fa fa-comment"></i> {comments.length} Comments</Link>
                                                                        <Link onClick={(e) => likeArticle(e, article.id)}><i className="fa fa-thumbs-o-up"></i> {article ? article.likes : 0} Likes</Link>
                                                                        <Link to=""><i className="fa fa-eye"></i> {article ? article.counter : 0} Viewed</Link>
                                                                    </div>
                                                                    <div className="post-social-share">
                                                                        <RWebShare
                                                                            data={{
                                                                                text: article != undefined ? article.title : '',
                                                                                url: "https://kokawe.com/article/" + article != undefined ? article.title : '',
                                                                                title: article != undefined ?article.title : ''
                                                                            }}
                                                                            onClick={() => toast.success("shared successfully!")}

                                                                        >
                                                                            <i className="fa fa-share-alt"></i>
                                                                        </RWebShare>
                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                                <div className="entry-content">
                                                                    {parse(article != null ? article.body : '')}                                                           
                                                                </div>
                                                                <footer className="entry-footer">
                                                                    <div className="col-xs-12 col-sm-12 entry-tags">
                                                                        <strong><i className="fa fa-tags" aria-hidden="true"></i> Tags:</strong> <span>
                                                                            {
                                                                                article != null ? article.tags.map((tag, idx) => 
                                                                                    <><Link key={idx} to="#">{tag.name}</Link>, </>
                                                                                ) : ''
                                                                            }
                                                                            </span>
                                                                    </div>
                                                                </footer>
                                                            </div>
                                                        </div>
                                                    </article>
                                                  )
                                            }                                            
                                            {/* <div className="about-author">
                                                <img src="images/blog/about-author.jpg" alt="" />
                                                <div className="author-content">
                                                    <div className="author-head">
                                                        <h3>Tom Hanks</h3>
                                                        <span className="underline left"></span>
                                                    </div>
                                                    <div className="post-social-share">
                                                        <ul>
                                                            <li>
                                                                <Link to="">
                                                                    <i className="fa fa-facebook"></i>
                                                                    <span>/ Facebook</span>
                                                                </Link>
                                                            </li>
                                                            <li><Link to=""><i className="fa fa-twitter"></i> <span>/ Twitter</span></Link></li>
                                                            <li><Link to=""><i className="fa fa-google-plus"></i> <span>/ Google+</span></Link></li>
                                                            <li><Link to=""><i className="fa fa-youtube-play"></i> <span>/ Youtube</span></Link></li>
                                                        </ul>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <p>Morbi in erat laoreet, eleifend mi sit amet, eleifend mauris. Duis magna turpis, semper ac ligula id, elementum hendrerit augue. Aliquam euismod sem ut justo ultrices, in eleifend sapien hendrerit. Vestibulum sollicitudin dapibus aliquet. Suspendisse a commodo ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. </p>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div> */}
                                            <div className="comments-area" id="comments">
                                                <div className="comment-bg">
                                                    <h4 className="comments-title">Comments</h4>
                                                    <span className="underline left"></span>
                                                    <ol className="comment-list">
                                                        <li className="comment even thread-even depth-1 parent">                                                            
                                                            {
                                                                !comments ? (
                                                                    <div className="comment-body">
                                                                        <Comment
                                                                            visible={true}
                                                                            height="80"
                                                                            width="80"
                                                                            ariaLabel="comment-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClass="comment-wrapper"
                                                                            color="#fff"
                                                                            backgroundColor="#F4442E"
                                                                        />
                                                                        <p>No comments available</p>
                                                                    </div>
                                                                ) : comments.map((_comment) => 
                                                                    <div className="comment-body">
                                                                        <div className="comment-author vcard">
                                                                            <img className="avatar avatar-32 photo avatar-default" src="images/blog/mathew.jpg" alt="Comment Author" />
                                                                            <b className="fn">
                                                                                <Link className="url" rel="external nofollow" to="#">{_comment.comment_by}</Link>
                                                                            </b>
                                                                        </div>
                                                                        <footer className="comment-meta">
                                                                            <div className="left-arrow"></div>
                                                                            {/* <div className="reply">
                                                                                <Link to="#" className="comment-reply-link">
                                                                                    <i className="fa fa-reply"></i> Reply
                                                                                </Link>
                                                                            </div> */}
                                                                            <div className="comment-metadata">
                                                                                <Link to="#">
                                                                                    <time datetime="2016-01-17">
                                                                                        {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(_comment ? Date.parse(_comment.created_at) : Date.now())}
                                                                                    </time>
                                                                                </Link>
                                                                            </div>
                                                                            <div className="comment-content">
                                                                                <p>{_comment.comments}</p>
                                                                            </div>
                                                                        </footer>
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                        </li>
                                                    </ol>
                                                </div>
                                                <div className="comment-respond" id="respond">
                                                    <h4 className="comment-reply-title" id="reply-title">Write your comment:</h4>
                                                    <span className="underline left"></span>
                                                    <form className="comment-form" id="commentform" method="post">
                                                        <div className="row">
                                                            {/* <p className="comment-form-author input-required">
                                                                <label>
                                                                    <span className="first-letter">Name</span>
                                                                    <span className="second-letter">*</span>
                                                                </label>
                                                                <input name="author" id="author" type="text" />
                                                            </p>
                                                            <p className="comment-form-email input-required">
                                                                <label>
                                                                    <span className="first-letter">Email</span>
                                                                    <span className="second-letter">*</span>
                                                                </label>
                                                                <input name="email" id="email" type="email" />
                                                            </p>
                                                            <p className="comment-form-subject input-required">
                                                                <label>
                                                                    <span className="first-letter">Subject</span>
                                                                </label>
                                                                <input name="subject" id="subject" type="text" />
                                                            </p> */}
                                                            <p className="comment-form-comment">
                                                                <textarea name="comment" value={comment} onChange={(e) => setComment(e.target.value)} id="comment" placeholder=""></textarea>
                                                            </p>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                        <p className="form-submit">
                                                            <input value="Submit" className="submit" onClick={postComment} id="submit" name="submit" type="submit" />
                                                        </p>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Article2;
