import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Comment } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useCart } from "react-use-cart";
import background from "../assets/course_background.jpeg";
import { RWebShare } from "react-web-share";
import http from "../utils/http";
import { ShareOutlined, ShoppingCart } from "@mui/icons-material";
import { shuffle } from "lodash";

const Course = () => {
  const [course, setCourse] = useState({});
  const [courses, setCourses] = useState([]);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const { addItem } = useCart();
  let param = useParams();
  useEffect(() => {
    const getBook = async () => {
      await http
        .get("course/" + param.id)
        .then((data) => {
          setCourse(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getBook();
  }, []);

  function setPrice(price) {
    let totalPrice = 0;
    if (price > 0) {
      totalPrice = Number(price) * 930;
    }
    return totalPrice;
  }

  useEffect(() => {
    const getCourses = async () => {
      const { data } = await http.get("courses");
      let shuffledData = shuffle(data);
      setCourses(shuffledData);
    };
    getCourses();
  }, []);

  const postComment = async (e) => {
    e.preventDefault();
    let postData = {
      comments: comment,
    };

    const { data } = await http.post(`book/${param.id}/comment`, postData);
    if (data.data) {
      toast.success(data.data);
      setComment("");
    } else toast.success("something went wrong");
  };

  const addToCarts = (e, course) => {
    e.preventDefault();
    const result = addItem(course);
    toast.info(result);
  };

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>Book Details</h2>
            <span className="underline center"></span>
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/courses">Courses</Link>
              </li>
              <li>Course details</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="booksmedia-detail-main">
              <div className="container">
                <section>
                  <div className="booksmedia-detail-box">
                    <div className="detailed-box">
                      <div className="col-xs-12 col-sm-5 col-md-4">
                        <div className="post-thumbnail">
                          {/* <div className="book-list-icon yellow-icon"></div> */}
                          <Box
                            height="194"
                            sx={{
                              backgroundImage: `url(${background})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              minWidth: "250px",
                              minHeight: "250px",
                              textAlign: "center",
                              padding: 3,
                            }}
                          >
                            <Typography
                              variant="h5"
                              marginTop={10}
                              color="white"
                              sx={{ fontWeight: "700" }}
                            >
                              {course.name}
                            </Typography>
                          </Box>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-7 col-md-5">
                        <div className="post-center-content">
                          <h2>{course.name}</h2>
                          <p>
                            <strong>Author: </strong>
                            Kokawe
                          </p>
                          <p>
                            <strong>Cost:</strong> ₦
                            {setPrice(course ? course.rrp : 0)}
                          </p>
                          <p>
                            <strong>Course Duration: </strong> {course.duration}
                            min
                          </p>
                          <div className="actions">
                            <ul>
                              <li>
                                <Link
                                  onClick={(e) =>
                                    addToCarts(e, {
                                      ...course,
                                      price: setPrice(course ? course.rrp : 0),
                                      author: "Kokawe",
                                      objectType: "course",
                                      type: "course",
                                    })
                                  }
                                  target="_blank"
                                  data-toggle="blog-tags"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Add To Cart"
                                >
                                  <i className="fa fa-shopping-cart"></i>
                                </Link>
                              </li>
                              <li>
                                <RWebShare
                                  data={{
                                    text: course.name,
                                    url:
                                      "https://kokawe.com/course/" + course.id,
                                    title: course.name,
                                  }}
                                  onClick={() =>
                                    toast.success("shared successfully!")
                                  }
                                >
                                  <i className="fa fa-share-alt"></i>
                                </RWebShare>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-3 ">
                        <div className="post-right-content">
                          <h4>Available now</h4>
                          <Link
                            onClick={(e) =>
                              addToCarts(e, {
                                ...course,
                                objectType: "course",
                                price: setPrice(course ? course.rrp : 0),
                                author: "Videotile",
                              })
                            }
                            className="btn btn-dark-gray"
                          >
                            Purchase Course
                          </Link>
                          <Link to="#." className="btn btn-dark-gray">
                            Consult Author
                          </Link>
                          <Link to="/cart" className="btn btn-dark-gray">
                            Quick Checkout
                          </Link>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>
                    <p>
                      <strong>Summary: </strong>
                      {course.description}{" "}
                    </p>
                  </div>
                </section>
                <section className="booksmedia-fullwidth">
                  <div className="container">
                    <h2 className="section-title text-center">
                      Recommended Courses
                    </h2>
                    <span className="underline center"></span>
                    <ul style={{ margin: "40px 0px" }}>
                      {!courses
                        ? ""
                        : courses.slice(0, 3).map((course, idx) => (
                            <li key={idx}>
                              <Card sx={{ maxWidth: 445 }}>
                                <Box
                                  height="194"
                                  sx={{
                                    backgroundImage: `url(${background})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    minHeight: "350px",
                                    textAlign: "center",
                                    padding: 5,
                                  }}
                                >
                                  <Typography
                                    variant="h5"
                                    marginTop={10}
                                    color="white"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    {course.name}
                                  </Typography>
                                </Box>
                                <CardContent>
                                  <Link to={`/course/${course.id}`}>
                                    <Typography variant="h4" color="black">
                                      {course.name}
                                    </Typography>
                                  </Link>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ paddingY: "10px" }}
                                    color="text.secondary"
                                  >
                                    {course.description.substring(0, 100) +
                                      "..."}
                                  </Typography>
                                </CardContent>
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                >
                                  <Typography
                                    variant="h5"
                                    sx={{ padding: "10px", fontWeight: "600" }}
                                  >
                                    ₦{setPrice(course ? course.rrp : 0)}
                                  </Typography>
                                  <CardActions disableSpacing>
                                    <IconButton aria-label="share">
                                      <RWebShare
                                        data={{
                                          text: course.name,
                                          url:
                                            "https://kokawe.com/course/" +
                                            course.id,
                                          title: course.name,
                                        }}
                                        onClick={() => toast.success("shared!")}
                                      >
                                        <ShareOutlined />
                                      </RWebShare>
                                    </IconButton>
                                    <IconButton
                                      onClick={(e) =>
                                        addToCarts(e, {
                                          ...course,
                                          type: "course",
                                          price: setPrice(
                                            course ? course.rrp : 0
                                          ),
                                          author: "kokawe",
                                          objectType: "course",
                                        })
                                      }
                                      aria-label="add to cart"
                                    >
                                      <ShoppingCart />
                                    </IconButton>
                                  </CardActions>
                                </Box>
                              </Card>
                            </li>
                          ))}
                    </ul>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Course;
