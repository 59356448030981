import React from "react";
import { useEffect } from "react";
import Select from "react-select";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { RWebShare } from "react-web-share";
import { addToCart } from "../utils/cart";
import { getCategories, getTags } from "../utils/get-categories";
import http from "../utils/http";
import arrayShuffle from "array-shuffle";
import { useCart } from "react-use-cart";

function Medias() {
  const { addItem } = useCart();
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [tag, setTag] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [medias, setMedias] = useState([]);

  useEffect(() => {
    const getCat = async () => {
      let cont = [];
      const { data } = await getCategories();
      for (let i = 0; i < data.length; i++) {
        cont.push(data[i].name);
      }
      setCategories(cont);
    };
    getCat();
  }, []);

  useEffect(() => {
    const getTage = async () => {
      let cont = [];
      const { data } = await getTags();
      for (let i = 0; i < data.length; i++) {
        cont.push(data[i].name);
      }
      setTags(cont);
    };
    getTage();
  }, []);

  useEffect(() => {
    const getMedias = async () => {
      let cont = [];
      const video = await http.get("video/all");
      for (let i = 0; i < video.data.data.length; i++) {
        cont.push(video.data.data[i]);
      }
      const audio = await http.get("audio/all");
      for (let i = 0; i < audio.data.data.length; i++) {
        cont.push(audio.data.data[i]);
      }
      let shuffled = arrayShuffle(cont);
      setMedias(shuffled);
    };
    getMedias();
  }, []);

  const categoryOptions = [{ value: "", label: "select category" }];
  categories.forEach((cat) => {
    categoryOptions.push({ value: cat.id, label: cat.name });
  });

  const searchBooks = async (e) => {
    e.preventDefault();
    let payload = {
      category: category,
      title: title,
      tag: tag,
    };
    const { data } = await http.post("videos/search", payload);
    setMedias(data);
  };

  const tagOptions = [{ value: "", label: "select tags" }];
  tags.forEach((tg) => {
    tagOptions.push({ value: tg.id, label: tg.name });
  });

  const addToCarts = (e, media) => {
    e.preventDefault();
    addItem(media);
    toast.info("Media added to cart");
  };

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>Medias</h2>
            <span className="underline center"></span>
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>Media</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="books-full-width">
              <div className="container">
                <section className="search-filters">
                  <div className="container">
                    <div className="filter-box">
                      <h3>What are you looking for at the library?</h3>
                      <form onSubmit={(e) => searchBooks(e)} method="post">
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="sr-only" htmlFor="keywords">
                              Search by Keyword
                            </label>
                            <input
                              className="form-control"
                              style={{
                                backgroundColor: "white",
                                color: "grey",
                                border: "none",
                                height: "35px",
                              }}
                              placeholder="Search by Keyword"
                              id="keywords"
                              type="text"
                              value={title}
                              name="title"
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <Select options={tagOptions} name="tag" />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <Select options={categoryOptions} name="category" />
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                          <div className="form-group">
                            <input
                              className="form-control"
                              style={{ height: "35px" }}
                              type="submit"
                              value="Search"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>

                <div className="booksmedia-fullwidth">
                  <ul>
                    {!medias
                      ? ""
                      : medias.map((media, idx) => (
                          <li key={idx}>
                            <Link
                              to={`/media/${
                                media.audio_cover_url ? "audio" : "video"
                              }/${media.id}`}
                            >
                              <figure>
                                <Link
                                  to={`/media/${
                                    media.audio_cover_url ? "audio" : "video"
                                  }/${media.id}`}
                                >
                                  {media.video_cover_url &&
                                  media.video_cover_url.split(".").at(-1) ===
                                    "mp4" ? (
                                    <video width="auto" height="240" autoPlay>
                                      <source
                                        src={media.video_cover_url}
                                        type="video/quicktime"
                                      />
                                      <source
                                        src={media.video_cover_url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <img
                                      src={
                                        media.audio_cover_url ??
                                        media.video_cover_url
                                      }
                                      alt={media.title}
                                    />
                                  )}
                                </Link>
                                <figcaption>
                                  <header>
                                    <h4>
                                      <Link
                                        to={`/media/${
                                          media.audio_cover_url
                                            ? "audio"
                                            : "video"
                                        }/${media.id}`}
                                      >
                                        {media.title}
                                      </Link>
                                    </h4>
                                    <p>
                                      <strong>Uploaded by:</strong>{" "}
                                      {media.owner}
                                    </p>
                                    <p>
                                      <strong>Daily fee:</strong>{" "}
                                      {"₦ " + media.amount}
                                    </p>
                                    <p>
                                      <strong>One Off Access fee:</strong> ₦{" "}
                                      {media.oneTimePayment}
                                    </p>
                                    {/* <p><strong>Stream Type:</strong> ₦ {media.stream_type}</p> */}
                                  </header>
                                  <p>{}</p>
                                  <div className="actions">
                                    <ul>
                                      {media.stream_type !== "free" ? (
                                        <li>
                                          <Link
                                            onClick={(e) =>
                                              addToCarts(e, {
                                                ...media,
                                                price: parseInt(media.amount),
                                                type: "media",
                                                objectType:
                                                  media.video_cover_url
                                                    ? "video"
                                                    : "audio",
                                              })
                                            }
                                            target="_blank"
                                            data-toggle="blog-tags"
                                            data-placement="top"
                                            title="Add TO CART"
                                          >
                                            <i className="fa fa-shopping-cart"></i>
                                          </Link>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                      <li>
                                        <RWebShare
                                          data={{
                                            text: media.title,
                                            url: `https://kokawe.com/media/${
                                              media.audio_cover_url
                                                ? "audio"
                                                : "video"
                                            }/${media.id}`,
                                            title: media.title,
                                          }}
                                          onClick={() =>
                                            toast.success(
                                              "shared successfully!"
                                            )
                                          }
                                        >
                                          <i className="fa fa-share-alt"></i>
                                        </RWebShare>
                                      </li>
                                    </ul>
                                  </div>
                                </figcaption>
                              </figure>
                            </Link>
                          </li>
                        ))}
                  </ul>
                </div>
                <nav className="navigation pagination text-center">
                  <h2 className="screen-reader-text">Posts navigation</h2>
                  <div className="nav-links">
                    <Link className="prev page-numbers" to="#.">
                      <i className="fa fa-long-arrow-left"></i> Previous
                    </Link>
                    <Link className="page-numbers current" to="#.">
                      1
                    </Link>
                    <Link className="next page-numbers" to="#.">
                      Next <i className="fa fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Medias;
