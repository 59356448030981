import React, { useState } from "react";
import http from "../utils/http";
import { getCategories, getTags } from "../utils/get-categories";
import { useEffect } from "react";
import {encrypt} from '../utils/encryptor';
import Select from 'react-select';
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 } from "uuid";
import ProgressBar from "@ramonak/react-progress-bar";
import { Input, Textarea } from "@mui/joy";
import { Typography } from "@mui/material";

const config = {
    region: process.env.REACT_APP_S3_REGION,
    credentials: {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY        
    }
}

const client = new S3Client(config);

function UploadBook() {
    const [title, setBookTitle] = useState('');
    const [tags, setTags] = useState([]);
    const [tag] = useState([]);
    const [categories, setCategories] = useState([]);
    const [duration, setDuration] = useState('');
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [status, setStatus] = useState('');
    const [oneTimePayment, setOneTimePayment] = useState('');
    const [disable_upload, setUploadStatus] = useState(false);
    const [hardCopyAmount, setHardCopyAmount] = useState(0);
    const [softCopyAmount, setSoftCopyAmount] = useState(0);
    const [type] = useState([]);
    const [category] = useState([]);
    const [progress, setProgress] = useState(0);
    const [coverProgress, setCoverProgress] = useState(0);
    const [about, setAbout] = useState('');
    const [bookUrl, setBookUrl] = useState('');
    const [coverUrl, setCoverUrl] = useState('');
    const [isbn, setIsbn] = useState('');
    const [bookFile, setBookFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [coverFile, setCoverFile] = useState();
    const [isCoverPicked, setIsCoverPicked] = useState(false);

    useEffect(() => {        
        async function cate() {
            let cont = [];
            const {data} = await getCategories();
            for(let i = 0; i < data.length; i++) {
                cont.push(data[i])
            }
            setCategories(cont);
        }
        cate();
    }, [])

    const options = [
        {value: 'soft copy', label: 'Soft Copy'},
        {value: 'hard copy', label: 'Hard Copy'}
    ]

    const categoryOptions = [{value: '', label: 'select category'}];
    categories.forEach((cat) => {
        categoryOptions.push({value: cat.id, label: cat.name})            
    })

    const tagOptions = [{value: '', label: 'select tags'}];
    tags.forEach((tg) => {
        tagOptions.push({value: tg.id, label: tg.name})            
    })

    useEffect(() => {
        const Tages = async () => {
          let cont = [];
          const {data} = await getTags();
          for(let i = 0; i < data.length; i++) {
            cont.push(data[i])
          }
        setTags(cont);
        } 
        Tages()
    }, [])

    const handleCategories = (e) => {
        let value = []
        value.push(e[e.length - 1])
        value.forEach((val) => {
            category.push(val.value)
        })      
    }

    const handleTags = (e) => {
        let value = []
        value.push(e[e.length - 1])
        value.forEach((val) => {
            tag.push(val.value)
        })
    }

    const handleBookType = (e) => {
        let value = []
        value.push(e[e.length - 1])
        value.forEach((val) => {
            type.push(val.value)
        })
    }


    const UploadBook = async (book) => {
        let type = book.type.split('/')[1];
        const bookNameKey = "books/";
        const bookKey = bookNameKey + v4() + '.' + type;
        await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: bookKey, Body: book}))
        .then((data) => {
            if(data) {
                setProgress(100);
            }
        }).catch(err => toast.error(err))
        const _bookUrl = encrypt(process.env.REACT_APP_S3_CLOUDFRONT_URL + bookKey)
        setBookUrl(_bookUrl)
    }

    const UploadBookCover = async (cover) => {
        let type = cover.type.split('/')[1];
        const coverNameKey = "books/cover/";
        const coverKey = coverNameKey + v4() + '.' + type;
        await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: coverKey, Body: cover}))
        .then((data) => {
            if(data) {
                setCoverProgress(100);
            }
        }).catch(err => toast.error(err))
        const _coverUrl =  process.env.REACT_APP_S3_CLOUDFRONT_URL + coverKey
        setCoverUrl(_coverUrl)
    }

    const bookUploadEvent = (e) => {
        e.preventDefault();
        setIsFilePicked(true);
        setBookFile(e.target.files[0])
        UploadBook(e.target.files[0])
    }

    const coverUploadEvent = (e) => {
        e.preventDefault();
        setIsCoverPicked(true);
        setCoverFile(e.target.files[0])
        UploadBookCover(e.target.files[0])
    }

    const postBook = async (e) => {
        e.preventDefault();
        setUploadStatus(true)
        let postData = {
            title: title,
            isbn: isbn,
            description: about,
            categories: category,
            tags: tag,
            author_name: name,
            author_bio: bio,
            accessDuration: duration.toString(),
            bookCoverUrl: coverUrl,
            softCopyAmount: softCopyAmount,
            hardCopyAmount: hardCopyAmount,
            bookType: type,
            bookUrl: bookUrl,
            oneTimePayment: oneTimePayment
        }
        await http.post('book', postData)
        .then((data) => {
            toast.success(data.data.data)
            setLoading(false)
            setStatus(data.data.data)
        })
        .catch((err) => {
            console.log(err)
            toast.error(err.response.data.message)
            setStatus(err.response.data.message)
            setLoading(false);
            setUploadStatus(false);
        })
    }

    return (
        <>
            <div className="main-content" style={{padding: '0px 0px'}}>
                <div className="section">
                    <Typography variant="h1">
                        Upload Book
                    </Typography>
                    <form onSubmit={postBook} method="post">
                    <div className="row">
                        <div className="col-12">
                            <div className="card" style={{margin: '20px'}}>
                                <div className="card-body">
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Book Title</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="text" value={title} onChange={(e) => setBookTitle(e.target.value)} name="title" className=""/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Book Cover</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="file" onChange={(e) => coverUploadEvent(e)} name="coverFile" className=""/>
                                            <ProgressBar completed={coverProgress} />
                                            {isCoverPicked ? (
                                                <div>
                                                    <p>Filename: {coverFile.name}</p>
                                                    <p>Filetype: {coverFile.type}</p>
                                                    <p>Size in bytes: {coverFile.size}</p>                                                    
                                                </div>
                                            ) : (
                                                <p>Select a file to show details</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Upload Book</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="file" onChange={(e) => bookUploadEvent(e)} name="bookFile" className=""/>
                                            <ProgressBar completed={progress} />
                                            {isFilePicked ? (
                                                <div>
                                                    <p>Filename: {bookFile.name}</p>
                                                    <p>Filetype: {bookFile.type}</p>
                                                    <p>Size in bytes: {bookFile.size}</p>
                                                </div>
                                            ) : (
                                                <p>Select a file to show details</p>
                                            )}
                                        </div>
                                    </div>
                                   
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">ISBN</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="text" value={isbn} onChange={(e) => setIsbn(e.target.value)} name="isbn" className=""/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Author Name <br /> <small>*Optional (for publishers only)</small></label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} name="isbn" className=""/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Author's Biography <br /> <small>*Optional (for publishers only)</small></label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="text" value={bio} onChange={(e) => setBio(e.target.value)} name="isbn" className=""/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Access Duration (24 Hours)</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="number" min={24} value={duration} onChange={(e) => setDuration(e.target.value)} name="duration" className=""/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">One off purchase</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="number" value={oneTimePayment} onChange={(e) => setOneTimePayment(e.target.value)} name="amount" className="" />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Daily Amount</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="number" value={softCopyAmount} onChange={(e) => setSoftCopyAmount(e.target.value)} name="softCopyAmount" className="" />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Hard Copy Amount</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Input type="number" value={hardCopyAmount} onChange={(e) => setHardCopyAmount(e.target.value)} name="hardCopyAmount" className="" />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Book Type</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Select options={options} onChange={(e) => handleBookType(e)} name="type" isMulti />                                            
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Category</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Select options={ categoryOptions } onChange={(e) => handleCategories(e)} name="category" isMulti />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Tag</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Select options={ tagOptions } onChange={(e) => handleTags(e)} name="category" isMulti />                                            
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">About the book</label>
                                        <div className="col-sm-12 col-md-7">
                                            <Textarea minRows={4} onChange={(e) => setAbout(e.target.value)} name="about"/>
                                            {/* <textarea className=""></textarea> */}
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                                        <div className="col-sm-12 col-md-7">
                                            <button type="submit" onClick={() => setLoading(true)} className="btn btn-primary btn-lg btn-block">
                                                {disable_upload ? <ClipLoader loading={loading} size={20} /> : 'Publish'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>                    
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default UploadBook;
