import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import http from "../utils/http";
// import { Outlet, Link } from "react-router-dom";



function Logout() {
    const navigate = useNavigate()
    useEffect(() => {
        http.post('logout')
        localStorage.clear('token')
        toast.info('Logged Out')
        navigate('/home')
    }, [])

    return (
        <>
           <div>

           </div>
           <ToastContainer />
        </>
    )
}

export default Logout;
