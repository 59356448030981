import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import http from "../../utils/http";

const EventTable = () => {
	const [selectedRows, setSelectedRows] = useState([]);

    const [events, setEvents] = useState([]);
    const params = useParams();

    useEffect(() => {
        const getEvents = async () => {
            let cont = []
            const {data} = await http.get(`event/${params.id}/users`);
            console.log(data)
            for(let i = 0; i < data.data.length; i++) {
                cont.push(data.data[i])
            }
            setEvents(cont);
        }
        getEvents();
    }, []); 

    console.log(events)

	useEffect(() => {
		
		console.log('state', selectedRows);
	}, [selectedRows]);

	const handleChange = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const columns = useMemo(
		() => [
            {
                name: 'Name',
				selector: row => row.name,
				sortable: true,                
			},
            {
                name: 'Phone',
				selector: row => row.phone,
				sortable: true,
			},
            {
                name: 'Email',
				selector: row => row.email,
				sortable: true,
			},
            {
                name: 'Gender',
				selector: row => row.gender,
				sortable: true,
			},
			{
                name: 'Date',
				selector: row => row.created_at,
				sortable: true,
			},
            // {
                
            //     cell: () => <button onClick={handleButtonClick}><i class="fa fa-eye"></i></button>,
            //     ignoreRowClick: true,
            //     allowOverflow: true,
            //     button: true,
            // },
            // {
                
            //     cell: () => <button onClick={handleButtonClick}><i class="fa fa-eye"></i></button>,
            //     ignoreRowClick: true,
            //     allowOverflow: true,
            //     button: true,
            // },
            // {
                
            //     cell: () => <button onClick={handleButtonClick}><i class="fa fa-eye"></i></button>,
            //     ignoreRowClick: true,
            //     allowOverflow: true,
            //     button: true,
            // },
			
		],
		[],
	);

	return (
		<DataTable
			title="Events"
			data={events}
			columns={columns}
			selectableRows
            pagination
			onSelectedRowsChange={handleChange}
		/>
	);
}

const AuthorEventUsers = () => {
    return (
        <>
            <div className="main-content" style={{padding: '40px 0px'}}>
                <section className="section">
                    <div className="section-header">
                        {/* <h1>{events.title}</h1> */}
                        <div className="section-header-breadcrumb">
                            <div className="breadcrumb-item active"><Link to="/author-dashboard">Dashboard</Link></div>
                            <div className="breadcrumb-item">Events</div>
                            {/* <div className="breadcrumb-item">{events.title}</div> */}
                        </div>
                    </div>
                    <EventTable />
                </section>
            </div>
            <ToastContainer />
        </>
    )
}

export default AuthorEventUsers;
