import React, { useEffect, useState } from "react";
import http from "../../utils/http";
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 } from "uuid";
import ProgressBar from "@ramonak/react-progress-bar";
import { useParams } from "react-router-dom";

const config = {
    region: process.env.REACT_APP_S3_REGION,
    credentials: {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY        
    }
}

const client = new S3Client(config);

const AuthorEditEvent = () => {
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState('');
    const [date1, setDate1] = useState(''); 
    const [date2, setDate2] = useState('');
    const [link, setEventLink] = useState('');
    const [link1, setEventLink1] = useState('');
    const [link2, setEventLink2] = useState('');
    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [event_status, setEventStatus] = useState(0);
    const [disable_upload, setUploadStatus] = useState(false);
    const [coverProgress, setCoverProgress] = useState(0);
    const [coverUrl, setCoverUrl] = useState('')
    const [coverFile, setCoverFile] = useState();
    const [isCoverPicked, setIsCoverPicked] = useState(false);
    const [event, setEvent] = useState();

    const params = useParams();

    useEffect(() => {
        const getEvent = async () => {
            const {data} = await http.get(`event/${params.id}`)
            setEvent(data.data)
            setLocation(data.data.location)
            setTitle(data.data.title)
            setPhone(data.data.contact_details.split(',')[1])
            setEmail(data.data.contact_details.split(',')[0]);
            setWebsite(data.data.contact_details.split(',')[2])
            setEventLink(data.data.links.split(',')[0])
            setEventLink1(data.data.links.split(',')[1])
            setEventLink2(data.data.links.split(',')[2])
            setDate(data.data.event_time.split(',')[0])
            setDate1(data.data.event_time.split(',')[1])
            setDate2(data.data.event_time.split(',')[2])
            setDescription(data.data.description)
            setCoverUrl(data.data.banner)
            setEventStatus(data.data.status)
        }
        getEvent()
    }, [])

    const UploadMediaCover = async (cover) => {
        let type = cover.type.split('/')[1];
        const coverNameKey = "event/banner/";
        const coverKey = coverNameKey + v4() + '.' + type;
        await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: coverKey, Body: cover}))
        .then((data) => {
            if(data) {
                setCoverProgress(100);
            }
        }).catch(err => toast.error(err))
        const _coverUrl = process.env.REACT_APP_S3_CLOUDFRONT_URL + coverKey
        setCoverUrl(_coverUrl)
    }

    const coverUploadEvent = (e) => {
        e.preventDefault();
        setIsCoverPicked(true);
        setCoverFile(e.target.files[0])
        UploadMediaCover(e.target.files[0])
    }

    const postEvent = async (e) => {
        e.preventDefault();
        setUploadStatus(true)
        let postData = {
            location: location,
            title: title,
            links: [link1, link2, link],
            description: description,
            contact_details: `${phone}, ${email}, ${website}`,
            event_time: [date, date1, date2],
            banner: coverUrl,
            status: event_status,
        }
        console.log(postData)
        await http.patch(`event/${params.id}`, postData)
        .then((data) => {
            toast.success(data.data.data)
            setLoading(false)
            setStatus(data.data.data)
        })
        .catch((err) => {
            console.log(err)
            toast.error(err.response.data.message)
            setStatus(err.response.data.message)
            setLoading(false);
            setUploadStatus(false);
        })        
    }

    return (
        <>
            {/* <Header /> */}
            <div className="main-content" style={{padding: '40px 0px'}}>
                <section className="section">
                    <div className="section-header">
                        <h1>Edit Events (Book Launch)</h1>
                    </div>
                    <form onSubmit={postEvent} method="post">
                    <div className="row">
                        <div className="col-12">
                            <div className="card" style={{margin: '20px 10px'}}>
                                <div className="card-body">
                                    
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Title</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} name="title" className="form-control"/>                                             
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Links</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="text" value={link} onChange={(e) => setEventLink(e.target.value)} name="link" placeholder="zoom link" className="form-control"/>                                             
                                            <input type="text" value={link1} onChange={(e) => setEventLink1(e.target.value)} name="link1" placeholder="youtube link" className="form-control"/>                                             
                                            <input type="text" value={link2} onChange={(e) => setEventLink2(e.target.value)} name="link2" placeholder="google meet link" className="form-control"/>                                             
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Banner</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="file" onChange={(e) => coverUploadEvent(e)} name="coverFile" className="form-control" multiple/>
                                            <ProgressBar completed={coverProgress} />
                                            { isCoverPicked ? (
                                                <div>
                                                    <p>Filename: {coverFile.name}</p>
                                                    <p>Filetype: {coverFile.type}</p>
                                                    <p>Size in bytes: {coverFile.size}</p>
                                                </div>
                                            ) : (
                                                <p>Select a file to show details</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Date and Time</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="text" value={date} onChange={(e) => setDate(e.target.value)} name="amount" placeholder="2013-07-04 07:20pm GMT+1" className="form-control" />
                                            <input type="text" value={date1} onChange={(e) => setDate1(e.target.value)} name="amount" placeholder="2013-07-04 07:20pm GMT+1" className="form-control" />
                                            <input type="text" value={date2} onChange={(e) => setDate2(e.target.value)} name="amount" placeholder="2013-07-04 07:20pm GMT+1" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Location</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} name="location" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Contact Details</label>
                                        <div className="col-sm-12 col-md-7">
                                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} name="phone" placeholder="Phone" className="form-control"/>
                                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Email" className="form-control"/>
                                            <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} name="website" placeholder="Website" className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">About Event</label>
                                        <div className="col-sm-12 col-md-7">
                                            <textarea type="text" value={description} onChange={(e) => setDescription(e.target.value)} name="location" className="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                                        <div className="col-sm-12 col-md-7">
                                            <button type="submit" onClick={() => setLoading(true)} className="btn btn-primary btn-lg btn-block">
                                                {disable_upload ? <ClipLoader loading={loading} size={20} /> : 'Create Event'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>                    
                </section>
            </div>
            <ToastContainer />
        </>
    )
}

export default AuthorEditEvent;
