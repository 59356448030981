import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import background from "../../assets/course_background.jpeg";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";

function UserCourse() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const getCourses = async () => {
      const { data } = await http.get("courses/mine");
      console.log(data.courses);
      setCourses(data.courses);
    };
    getCourses();
  }, []);

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>My Courses</h2>
            <span className="underline center"></span>
            {/* <p className="lead">Rando text here</p> */}
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>My Courses</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="books-full-width">
              <div className="container">
                <div className="booksmedia-fullwidth">
                  <ul style={{ margin: "40px 0px" }}>
                  {!courses
          ? ""
          : courses.map((course, idx) => (
              <li key={idx}>
                <Card sx={{ maxWidth: 400 }}>
                  <Link to={`/my-course/${course.id}`}>
                    <Box
                      height="194"
                      sx={{
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        minHeight: "350px",
                        textAlign: "center",
                        padding: 5,
                      }}
                    >
                      <Typography
                        variant="h5"
                        marginTop={10}
                        color="white"
                        sx={{ fontWeight: "700" }}
                      >
                        {course.course_name}
                      </Typography>
                    </Box>
                  </Link>
                  <CardContent>
                    <Link to={`/my-course/${course.id}`}>
                      <Typography variant="h4" color="black">
                        {course.course_name}
                      </Typography>
                    </Link>
                    <Typography
                      variant="subtitle1"
                      sx={{ paddingY: "10px" }}
                      color="text.secondary"
                    >
                      {course.course_description.substring(0, 100) + "..."}
                    </Typography>
                  </CardContent>
                </Card>
              </li>
            ))}
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default UserCourse;
