import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { RWebShare } from "react-web-share";
import BookLoader from "../components/book-loader";
import { addToCart } from "../utils/cart";
import { getCategories, getTags } from "../utils/get-categories";
import http from "../utils/http";

function Authors({itemsPerPage}) {

    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
        const getCat = async () => {
            let cont = [];
            const { data } = await getCategories();
            for (let i = 0; i < data.length; i++) {
                cont.push(data[i].name)
            }
            setCategories(cont);
        }
        getCat()
    }, [])

    useEffect(() => {
        const getTage = async () => {
            let cont = [];
            const { data } = await getTags();
            for (let i = 0; i < data.length; i++) {
                cont.push(data[i].name)
            }
            setTags(cont);
        }
        getTage();
    }, [])

    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = authors.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(authors.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % authors.length;
        console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    useEffect(() => {
        const getAuthors = async () => {
            let cont = [];
            const data = await http.get('authors');
            for (let i = 0; i < data.data.data.length; i++) {
                cont.push(data.data.data[i])
            }
            console.log(cont)
            setAuthors(cont);
        }
        getAuthors();
    }, [])

    const addToCarts = (e, author) => {
        e.preventDefault();
        const result = addToCart(author);
        toast.info(result)
    }

    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>authors</h2>
                        <span className="underline center"></span>
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>authors</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <div className="authors-full-width">
                            <div className="container">
                                <section className="search-filters">
                                    <div className="filter-box">
                                        <h3>What are you looking for at the library?</h3>
                                        <form action="" method="get">
                                            <div className="col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control" placeholder="Search by Keyword" id="keywords" name="keywords" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-sm-6">
                                                <div className="form-group">
                                                    <select name="tags" id="tags" className="form-control" multiple>
                                                        {
                                                            tags.length == 0 ? (<option>Search with tags</option>) : tags.map((tag, idx) =>
                                                                <option key={idx} value={tag.name}>{tag.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-sm-6">
                                                <div className="form-group">
                                                    <select name="category" id="category" className="form-control">
                                                        {
                                                            categories.length == 0 ? (<option>Search with Categories</option>) : categories.map((cat, idx) =>
                                                                <option key={idx} value={cat.name}>{cat.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control" type="submit" value="Search" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="clear"></div>
                                </section>

                               
                                <div className="booksmedia-fullwidth">
                                    <ul>
                                        {
                                            !authors ? (<BookLoader />) : authors.map((author, idx) =>
                                                <li key={idx}>
                                                    {/* <div className="author-list-icon yellow-icon"></div> */}
                                                    <Link to={"/author/" + author.id}>
                                                        <figure>
                                                            <Link to={"/author/" + author.id}><img src={author.email} alt="author" /></Link>
                                                            <figcaption>
                                                                <header>
                                                                    <h4><Link to={"/author/" + author.id}>{author.name}</Link></h4>
                                                                    <p><strong>Author:</strong> {author.author}</p>
                                                                    <p><strong>ISBN:</strong> {author.isbn}</p>
                                                                </header>
                                                                <p>{ }</p>
                                                                <div className="actions">
                                                                    <ul>
                                                                        <li>
                                                                            <Link onClick={(e) => addToCarts(e, author)} target="_blank" data-toggle="blog-tags" data-placement="top" title="Add TO CART">
                                                                                <i className="fa fa-shopping-cart"></i>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="/" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                <i className="fa fa-heart"></i>
                                                                            </Link>
                                                                        </li>
                                                                        
                                                                        <li>
                                                                            <RWebShare
                                                                                data={{
                                                                                text: author.title,
                                                                                url: "https://kokawe.com/author/" + author.id,
                                                                                title: author.title
                                                                                }}
                                                                                onClick={() => toast.success("shared successfully!")}
                                                                            >
                                                                                <i className="fa fa-share-alt"></i>
                                                                            </RWebShare>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="< previous"
                                    renderOnZeroPageCount={null}
                                />
                                {/* <nav className="navigation pagination text-center">
                                    <h2 className="screen-reader-text">Posts navigation</h2>
                                    <div className="nav-links">
                                        <Link className="prev page-numbers" to="#."><i className="fa fa-long-arrow-left"></i> Previous</Link>
                                        <Link className="page-numbers current" to="#.">1</Link>
                                        <Link className="next page-numbers" to="#.">Next <i className="fa fa-long-arrow-right"></i></Link>
                                    </div>
                                </nav> */}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default Authors;