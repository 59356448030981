import React from "react";
import { Link } from "react-router-dom";
import "../assets/assets2/css/font-awesome.min.css"

function Footer() {
    return (
        <footer className="site-footer">
            <div className="container">
                <div id="footer-widgets">
                    <div className="row">
                        <div className="col-md-2 widget-container">
                            <div className="widget widget_social_icons">
                                <ul>
                                    <li>
                                        <a className="facebook" href="https://www.facebook.com/profile.php?id=100087598612339" target="_blank" rel="noreferrer">
                                            <span>
                                                <i className="fa fa-facebook-f"></i>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="instagram" href="https://www.instagram.com/kokawe01" target="_blank" rel="noreferrer">
                                            <span>
                                                <i className="fa fa-instagram"></i>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="youtube" href="https://www.youtube.com/channel/UClL6HMfatay8MECsYi_SgLA" target="_blank" rel="noreferrer">
                                            <span>
                                                <i className="fa fa-youtube"></i>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="twitter" href="https://twitter.com/kokawe01" target="_blank" rel="noreferrer">
                                            <span>
                                                <i className="fa fa-twitter"></i>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 widget-container">
                            <div id="text-2" className="widget widget_text">
                                <h3 className="footer-widget-title">About Kokawe</h3>
                                <span className="underline left"></span>
                                <div className="textwidget">
                                    The Content Marketplace!
                                </div>
                                <address>
                                    <div className="info">
                                        <i className="fa fa-location-arrow"></i>
                                        <span>KM 5, LASU EXPRESSWAY, LAGOS, NIGERIA</span>
                                    </div>
                                    <div className="info">
                                        <i className="fa fa-envelope"></i>
                                        <span><Link to="mailto:support@kokawe.com">support@kokawe.com</Link></span>
                                    </div>
                                    <div className="info">
                                        <i className="fa fa-phone"></i>
                                        <span><Link to="tel:+2348025505812">+2348025505812</Link></span> <br />
                                        <i className="fa fa-phone"></i>
                                        <span><Link to="tel:+447503859651">+447503859651</Link></span>                                        
                                    </div>
                                </address>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-6 widget-container">
                            <div id="nav_menu-2" className="widget widget_nav_menu">
                                <h3 className="footer-widget-title">Quick Links</h3>
                                <span className="underline left"></span>
                                <div className="menu-quick-links-container">
                                    <ul id="menu-quick-links" className="menu">
                                        <li><Link to="/">Home</Link></li>
                                        <li><a href="#about">About</a></li>
                                        <li><Link to="/services">Services</Link></li>
                                        <li><Link to="/books">Books</Link></li>
                                        <li><Link to="/articles">Articles</Link></li>
                                        <li><Link to="/medias">Media</Link></li>
                                        <li><a href="#new-releases">New Releases</a></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </footer>
    )
}

export default Footer;
