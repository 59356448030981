import axios from 'axios';
// import { useRouter } from 'next/router'
import { getToken } from './get-token';

// const router = useRouter();
// const slug = router.query.slug as string
const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 300000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Change request data/error here
http.interceptors.request.use(
  (config) => {
    const token = getToken();
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token ? token.substring(1, token.length-1) : ''}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
