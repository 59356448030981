import * as React from "react";
import logo from "../assets/logo2.png";
import AspectRatio from "@mui/joy/AspectRatio";

export default function MuiLogo({ sx, ...props }) {
  return (
    <AspectRatio
      ratio="1"
      variant="plain"
      {...props}
      sx={[
        {
          width: 36,
          borderRadius: "sm",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div>
        <img src={logo} width="140" alt="kokawe logo" />
      </div>
    </AspectRatio>
  );
}
