import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import http from "../utils/http";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(true);
    const [disable_button, setButtonStatus] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async(e) => {
        e.preventDefault()
        setButtonStatus(true)
        let postData = {
            email: email
        }

        await http.post('forgot_password', postData)
        .then((res) => {
            setStatus(res.data.data);
            toast.success(res.data.data)
            setLoading(false)
            navigate('/change-password');
        })
        .catch((err) => {
            setStatus(err.response.data.message);
            setLoading(false);
            setButtonStatus(false)
        })
        setEmail('')
    }

    return (        
        <>
            {/* <Header /> */}
            <div className="w-500" style={{maxWidth: 400, margin: 'auto'}}>
                <div className="login-brand">
                    <img src="./logo.png" alt="logo" width="100" />
                </div>
                <div className="card card-primary">
                    <div className="card-header">
                        <h4>Forgot Password</h4>
                    </div>
                    <div className="card-body">
                        <div style={{color: 'red', fontWeight: 'bold', textAlign: 'center'}}>{status}</div>
                        <p className="text-muted">We will send a link to reset your password</p>
                        <form onSubmit={handleSubmit} method="POST">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" name="email" tabIndex="1" required autoFocus />
                            </div>
                            <div className="form-group">
                                <button type="submit" onClick={() => setLoading(true)} className="btn btn-primary btn-lg btn-block" tabIndex="4">
                                    {disable_button ? <ClipLoader loading={loading} size={20} /> : 'Forgot Password'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default ForgotPassword;