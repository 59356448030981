import { Link } from "react-router-dom";
import React from 'react';
import { usePaystackPayment } from 'react-paystack';
import axios from 'axios';
import { useState } from "react";
import { useEffect } from "react";
import { getProfile } from "../../utils/get-profile";
import { toast, ToastContainer } from "react-toastify";

const AuthorSubscription = () => {
    const [email, setEmail] = useState('');
    const amount = 0;
    let subscribed = localStorage.getItem('ssd');
    
    useEffect(() => {
        const getEmail = async () => {
            const admin = await getProfile()
            setEmail(admin.data.data[0].email)
        }
        getEmail();
    }, [])

    const config = {
        reference: (new Date()).getTime().toString(),
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
    };

    // you can call this function anything
    const onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        const result = axios.post(`${process.env.REACT_APP_API_BASE_URL}subscribe/${reference}`);
        console.log(result)
    };

    // you can call this function anything
    const onClose = () => {
        toast.info('Payment Cancelled');
    }

    const initializePayment = usePaystackPayment({
        ...config,
        amount: amount * 100,
        email: email
    });
    
    const subscribe = (e) => {
        e.preventDefault();
        initializePayment(onSuccess, onClose);
    }

    return (
        <>
        <div className="main-content" style={{padding: '40px 0px'}}>
            <section className="section">
                <div className="section-header">
                    
                </div>

                <div className="section-body">
                    <h2 className="section-title">Subscriptions</h2>
                    <p className="section-lead">Subscribe to upload and earn from your books and articles</p>

                    <div className="row">
                        
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="pricing pricing-highlight">
                                <div className="pricing-title">Yearly</div>
                                <div className="pricing-padding">
                                    <div className="pricing-price">
                                        <div>₦0</div>
                                        <div><strike>₦10,000</strike></div>
                                        <div>per year</div>
                                    </div>
                                    <div className="pricing-details">
                                        <div className="pricing-item">
                                            <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                            <div className="pricing-item-label">Unlimited Books</div>
                                        </div>
                                        <div className="pricing-item">
                                            <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                            <div className="pricing-item-label">Upload Books(Hard Copy & Soft Copies)</div>
                                        </div>
                                        <div className="pricing-item">
                                            <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                            <div className="pricing-item-label">Earn from Books</div>
                                        </div>
                                        <div className="pricing-item">
                                            <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                            <div className="pricing-item-label">Earn from Written Articles</div>
                                        </div>
                                        <div className="pricing-item">
                                            <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                            <div className="pricing-item-label">24/7 Support</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pricing-cta">
                                    { subscribed ? <Link style={{cursor: 'not-allowed'}}>Subscribed</Link>  : <><Link onClick={(e) => subscribe(e)}>Subscribe <i className="fas fa-arrow-right"></i></Link></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <ToastContainer />
        </>
    )
}

export default AuthorSubscription;


