import {
    Rating,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import React, { useEffect, useState } from "react";
  import { Link, useParams } from "react-router-dom";
  import { toast, ToastContainer } from "react-toastify";
  import user from "../assets/user.jpeg";
  import { RWebShare } from "react-web-share";
  import http from "../utils/http";
  import { shuffle } from "lodash";
import BookingModal from "../components/bookingModal";
  
  const Teacher = () => {
    const [teacher, setTeacher] = useState({});
    const [teachers, setTeachers] = useState([]);
    const [review, setReview] = useState("");
    const [modalStatus, setModalStatus] = useState(false);
    const [rating, setRating] = useState(0);
    const [reviews, setReviews] = useState([]);
    let param = useParams();
    useEffect(() => {
      const getTeacher = async () => {
        await http
          .get("teacher/" + param.id)
          .then((data) => {
            setTeacher(data.data);
            setRating(data.data?.rating);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getTeacher();
    }, []);

  
    useEffect(() => {
      const getTeachers = async () => {
        const { data } = await http.get("teachers");
        let shuffledData = shuffle(data);
        setTeachers(shuffledData);
      };
      getTeachers();
    }, []);
  
    const postReview = async (e) => {
      e.preventDefault();
      let postData = {
        review: review,
      };
  
      const { data } = await http.post(`teacher/${param.id}/review`, postData);
      if (data.data) {
        toast.success(data.data);
        setReview("");
      } else toast.success("something went wrong");
    };
  
    return (
      <>
        <section className="page-banner services-banner">
          <div className="container">
            <div className="banner-header">
              <h2>Teacher Profile</h2>
              <span className="underline center"></span>
            </div>
            <div className="breadcrumb">
              <ul>
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <Link to="/teachers">Teachers</Link>
                </li>
                <li>Teacher Profile</li>
              </ul>
            </div>
          </div>
        </section>
  
        <div id="content" className="site-content">
          <div id="primary" className="content-area">
            <main id="main" className="site-main">
              <div className="booksmedia-detail-main">
                <div className="container">
                  <section>
                    <div className="booksmedia-detail-box">
                      <div className="detailed-box">
                        <div className="col-xs-12 col-sm-5 col-md-4">
                          <div className="post-thumbnail">
                            {/* <div className="book-list-icon yellow-icon"></div> */}
                            {
                              teacher.subjects && teacher.schedule ? <BookingModal teacher={teacher} status={modalStatus} /> : ""
                            }
                            <Box
                              height="194"
                              sx={{
                                backgroundImage: `url(${teacher.photo ?? user})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                minWidth: "250px",
                                minHeight: "250px",
                                textAlign: "center",
                                padding: 3,
                              }}
                            >
                            </Box>
                            <div className="post-right-content">
                              <Link
                                className="btn btn-dark-gray"
                                onClick={(e) => setModalStatus(true)}
                              >
                                Book Lessons
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-7 col-md-8">
                          <div className="post-center-content">
                            <h2>{teacher.name}</h2>
                            <p style={{textTransform: 'uppercase'}}><strong>Subjects:</strong> {teacher.subjects}</p>
                            <p style={{textTransform: 'uppercase'}}><strong>Highest Qualification:</strong> {teacher.highest_qualification}</p>
                            <p>{teacher.certificate}</p>
                            <p>
                              <Rating name="read-only" value={rating} readOnly /> ({teacher.review} reviews)
                            </p>
                            <div className="actions">
                              <ul>
                                <li>
                                  <Link
                                    data-toggle="blog-tags"
                                    data-placement="top"
                                    title=""
                                    data-original-title="book"
                                    onClick={(e) => setModalStatus(true)}
                                  >
                                    Book me
                                  </Link>
                                </li>
                                <li>
                                  <RWebShare
                                    data={{
                                      text: teacher.name,
                                      url:
                                        "https://kokawe.com/course/" + teacher.id,
                                      title: teacher.name,
                                    }}
                                    onClick={() =>
                                      toast.success("shared successfully!")
                                    }
                                  >
                                    <i className="fa fa-share-alt"></i>
                                  </RWebShare>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-xs-12 col-sm-12 col-md-3 ">
                          
                        </div> */}
                        <div className="clearfix"></div>
                      </div>
                      <div className="clearfix"></div>
                      <p>
                        <strong>Biography: </strong>
                        {teacher.description}{" "}
                      </p>
                    </div>
                    <div className="comments-area" id="comments">
                    <div className="comment-bg">
                      <h4 className="comments-title">Reviews</h4>
                      <span className="underline left"></span>
                      <ol className="comment-list">
                        <li className="comment even thread-even depth-1 parent">
                          {!reviews ? (
                            // <div className="comment-body">
                            //   <Comment
                            //     visible={true}
                            //     height="80"
                            //     width="80"
                            //     ariaLabel="comment-loading"
                            //     wrapperStyle={{}}
                            //     wrapperClass="comment-wrapper"
                            //     color="#fff"
                            //     backgroundColor="#F4442E"
                            //   />
                            // </div>
                            <p>No reviews yet</p>
                          ) : (
                            reviews.map((_review) => (
                              <div className="comment-body">
                                <div className="comment-author vcard">
                                  <img
                                    className="avatar avatar-32 photo avatar-default"
                                    src="images/blog/mathew.jpg"
                                    alt="Comment Author"
                                  />
                                  <b className="fn">
                                    <Link
                                      className="url"
                                      rel="external nofollow"
                                      to="#"
                                    >
                                      {_review.review_by}
                                    </Link>
                                  </b>
                                </div>
                                <footer className="comment-meta">
                                  <div className="left-arrow"></div>
                                  <div className="comment-metadata">
                                    <Link to="#">
                                      <time dateTime="2016-01-17">
                                        {_review.created_at}
                                      </time>
                                    </Link>
                                  </div>
                                  <div className="comment-content">
                                    <p>{_review.review}</p>
                                  </div>
                                </footer>
                              </div>
                            ))
                          )}
                        </li>
                      </ol>
                    </div>
                    {/* <div className="comment-respond" id="respond">
                      <h4 className="comment-reply-title" id="reply-title">
                        Write your Review:
                      </h4>
                      <span className="underline left"></span>
                      <form
                        className="comment-form"
                        id="commentform"
                        method="post"
                      >
                        <div className="row">
                          <p className="comment-form-comment">
                            <textarea
                              name="comment"
                              value={review}
                              onChange={(e) => setReview(e.target.value)}
                              id="comment"
                              placeholder=""
                            ></textarea>
                          </p>
                        </div>
                        <div className="clearfix"></div>
                        <p className="form-submit">
                          <input
                            value="Submit"
                            className="submit"
                            onClick={postReview}
                            id="submit"
                            name="submit"
                            type="submit"
                          />
                        </p>
                      </form>
                    </div> */}
                  </div>
                  </section>
                  {/* <section className="booksmedia-fullwidth">
                    <div className="container">
                      <h2 className="section-title text-center">
                        Recommended Courses
                      </h2>
                      <span className="underline center"></span>
                      <ul style={{ margin: "40px 0px" }}>
                        {!courses
                          ? ""
                          : courses.slice(0, 3).map((course, idx) => (
                              <li key={idx}>
                                <Card sx={{ maxWidth: 445 }}>
                                  <Box
                                    height="194"
                                    sx={{
                                      backgroundImage: `url(${teacher})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                      minHeight: "350px",
                                      textAlign: "center",
                                      padding: 5,
                                    }}
                                  >
                                    <Typography
                                      variant="h5"
                                      marginTop={10}
                                      color="white"
                                      sx={{ fontWeight: "700" }}
                                    >
                                      {course.name}
                                    </Typography>
                                  </Box>
                                  <CardContent>
                                    <Link to={`/course/${course.id}`}>
                                      <Typography variant="h4" color="black">
                                        {course.name}
                                      </Typography>
                                    </Link>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ paddingY: "10px" }}
                                      color="text.secondary"
                                    >
                                      {course.description.substring(0, 100) +
                                        "..."}
                                    </Typography>
                                  </CardContent>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography
                                      variant="h5"
                                      sx={{ padding: "10px", fontWeight: "600" }}
                                    >
                                      ₦{setPrice(course ? course.rrp : 0)}
                                    </Typography>
                                    <CardActions disableSpacing>
                                      <IconButton aria-label="share">
                                        <RWebShare
                                          data={{
                                            text: course.name,
                                            url:
                                              "https://kokawe.com/course/" +
                                              course.id,
                                            title: course.name,
                                          }}
                                          onClick={() => toast.success("shared!")}
                                        >
                                          <ShareOutlined />
                                        </RWebShare>
                                      </IconButton>
                                      <IconButton
                                        onClick={(e) =>
                                          addToCarts(e, {
                                            ...course,
                                            type: "course",
                                            price: setPrice(
                                              course ? course.rrp : 0
                                            ),
                                            author: "kokawe",
                                            objectType: "course",
                                          })
                                        }
                                        aria-label="add to cart"
                                      >
                                        <ShoppingCart />
                                      </IconButton>
                                    </CardActions>
                                  </Box>
                                </Card>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </section> */}
                </div>
              </div>
            </main>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  };
  
  export default Teacher;
  