import React, { useState } from "react";
import http from "../../utils/http";
import { getCategories, getTags } from "../../utils/get-categories";
import { useEffect } from "react";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 } from "uuid";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate, useParams } from "react-router-dom";
import { encrypt } from "../../utils/encryptor";
import { Input, Textarea } from "@mui/joy";

const config = {
  region: process.env.REACT_APP_S3_REGION,
  credentials: {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  },
};

const client = new S3Client(config);

function AuthorEditBook() {
  const [title, setBookTitle] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);
  const [tags, setTags] = useState([]);
  const [tag] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [book, setBook] = useState();
  const [bookCoverUrl, setBookCoverUrl] = useState("");
  const [author_name, setAuthorName] = useState();
  const [bio, setAuthorBio] = useState();
  const [oneTimePayment, setOneTimePayment] = useState();
  const [disable_upload, setUploadStatus] = useState(false);
  const [hardCopyAmount, setHardCopyAmount] = useState(0);
  const [softCopyAmount, setSoftCopyAmount] = useState(0);
  const [type] = useState([]);
  const [category] = useState([]);
  const [progress, setProgress] = useState(0);
  const [coverProgress, setCoverProgress] = useState(0);
  const [about, setAbout] = useState("");
  const [bookUrl, setBookUrl] = useState("");
  const [coverUrl, setCoverUrl] = useState("");
  const [isbn, setIsbn] = useState("");
  const [bookFile, setBookFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [coverFile, setCoverFile] = useState();
  const [isCoverPicked, setIsCoverPicked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function cate() {
      let cont = [];
      const { data } = await getCategories();
      for (let i = 0; i < data.length; i++) {
        cont.push(data[i]);
      }
      setCategories(cont);
    }
    cate();
  }, []);

  const options = [
    { value: "soft copy", label: "Soft Copy" },
    { value: "hard copy", label: "Hard Copy" },
  ];

  const categoryOptions = [{ value: "", label: "select category" }];
  categories.forEach((cat) => {
    categoryOptions.push({ value: cat.id, label: cat.name });
  });

  const tagOptions = [{ value: "", label: "select tags" }];
  tags.forEach((tg) => {
    tagOptions.push({ value: tg.id, label: tg.name });
  });

  useEffect(() => {
    const Tages = async () => {
      let cont = [];
      const { data } = await getTags();
      for (let i = 0; i < data.length; i++) {
        cont.push(data[i]);
      }
      setTags(cont);
    };
    Tages();
  }, []);

  const sortSelectedCats = (e) => {
    let value = [];
    e.forEach((val) => {
      value.push(val.name);
      category.push(val.id);
    });
    setSelectedCats(value);
  };

  const sortSelectedTags = (e) => {
    let value = [];
    e.forEach((val) => {
      value.push(val.name);
      tag.push(val.id);
    });
    setSelectedTags(value);
  };

  const handleCategories = (e) => {
    let value = [];
    value.push(e[e.length - 1]);
    value.forEach((val) => {
      category.push(val.value);
    });
  };

  const handleTags = (e) => {
    let value = [];
    value.push(e[e.length - 1]);
    value.forEach((val) => {
      console.log(val.value);
      tag.push(val.value);
    });
  };

  const handleBookType = (e) => {
    let value = [];
    value.push(e[e.length - 1]);
    value.forEach((val) => {
      type.push(val.value);
    });
  };

  const UploadBook = async (book) => {
    let type = book.type.split("/")[1];
    const bookNameKey = "books/";
    const bookKey = bookNameKey + v4() + "." + type;
    await client
      .send(
        new PutObjectCommand({
          Bucket: "bestubborn",
          ContentType: type,
          Key: bookKey,
          Body: book,
        })
      )
      .then((data) => {
        if (data) {
          setProgress(100);
        }
      })
      .catch((err) => toast.error(err));
    const _bookUrl = encrypt(process.env.REACT_APP_S3_CLOUDFRONT_URL + bookKey);
    setBookUrl(_bookUrl);
  };

  const UploadBookCover = async (cover) => {
    let type = cover.type.split("/")[1];
    const coverNameKey = "books/cover/";
    const coverKey = coverNameKey + v4() + "." + type;
    await client
      .send(
        new PutObjectCommand({
          Bucket: "bestubborn",
          ContentType: type,
          Key: coverKey,
          Body: cover,
        })
      )
      .then((data) => {
        if (data) {
          setCoverProgress(100);
        }
      })
      .catch((err) => toast.error(err));
    const _coverUrl = process.env.REACT_APP_S3_CLOUDFRONT_URL + coverKey;
    setCoverUrl(_coverUrl);
  };

  const param = useParams();

  useEffect(() => {
    const getBook = async () => {
      const { data } = await http.get(`book/${param.id}`);
      setBook(data.data[0]);
      setOneTimePayment(data.data[0].oneTimePayment);
      setSoftCopyAmount(data.data[0].softCopyAmount);
      setHardCopyAmount(data.data[0].hardCopyAmount);
      setBookTitle(data.data[0].title);
      setBookUrl(data.data[0].bookUrl);
      setBookCoverUrl(data.data[0].bookCoverUrl);
      sortSelectedCats(data.data[0].categories);
      sortSelectedTags(data.data[0].tags);
      setAbout(data.data[0].description);
      setAuthorName(data.data[0].author_name);
      setAuthorBio(data.data[0].author_bio);
      setIsbn(data.data[0].isbn);
    };
    getBook();
  }, []);

  const bookUploadEvent = (e) => {
    e.preventDefault();
    setIsFilePicked(true);
    setBookFile(e.target.files[0]);
    UploadBook(e.target.files[0]);
  };

  console.log(selectedCats);

  const coverUploadEvent = (e) => {
    e.preventDefault();
    setIsCoverPicked(true);
    setCoverFile(e.target.files[0]);
    UploadBookCover(e.target.files[0]);
  };

  const postBook = async (e) => {
    e.preventDefault();
    setUploadStatus(true);
    let postData = {
      id: book.id,
      title: title,
      author_name: author_name,
      author_bio: bio,
      isbn: isbn,
      description: about,
      categories: category,
      tags: tag,
      accessDuration: 0,
      oneTimePayment: oneTimePayment,
      bookCoverUrl: coverUrl ? coverUrl : book.bookCoverUrl,
      softCopyAmount: softCopyAmount,
      hardCopyAmount: hardCopyAmount,
      bookType: type,
      bookUrl: bookUrl ? bookUrl : book.bookUrl,
    };
    console.log(postData);
    await http
      .patch("book/" + param.id, postData)
      .then((data) => {
        console.log(data);
        toast.success(data.data.data);
        setLoading(false);
        navigate("/author-books");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(false);
        setUploadStatus(false);
      });
  };

  return (
    <>
      {/* <Header /> */}
      <div className="main-content" style={{ padding: "0px 0px" }}>
        <div className="section">
          <div className="section-header">
            <h1>Edit Book</h1>
          </div>
          <form onSubmit={postBook} method="post">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ margin: "20px 10px" }}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Book Title
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="text"
                          value={title}
                          onChange={(e) => setBookTitle(e.target.value)}
                          name="title"
                          className=""
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Author Name
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="text"
                          value={author_name}
                          onChange={(e) => setAuthorName(e.target.value)}
                          name="name"
                          className=""
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Author Bio
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="text"
                          value={bio}
                          onChange={(e) => setAuthorBio(e.target.value)}
                          name="bio"
                          className=""
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Book Cover
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="file"
                          onChange={(e) => coverUploadEvent(e)}
                          name="coverFile"
                          className=""
                        />
                        <ProgressBar completed={coverProgress} />
                        {bookCoverUrl ? (
                          <img src={bookCoverUrl} width="200px" />
                        ) : (
                          "Upload book Cover"
                        )}
                        {isCoverPicked ? (
                          <div>
                            <p>Filename: {coverFile.name}</p>
                            <p>Filetype: {coverFile.type}</p>
                            <p>Size in bytes: {coverFile.size}</p>
                            <p>
                              lastModifiedDate:{" "}
                              {coverFile.lastModifiedDate.toLocaleDateString()}
                            </p>
                          </div>
                        ) : (
                          <p>Select a file to show details</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Upload Book
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="file"
                          onChange={(e) => bookUploadEvent(e)}
                          name="bookFile"
                          className=""
                        />
                        <ProgressBar completed={progress} />
                        {bookUrl ? (
                          <p className="text-success">Book file available</p>
                        ) : (
                          <p className="text-danger">
                            No file available, upload a book file
                          </p>
                        )}
                        {isFilePicked ? (
                          <div>
                            <p>Filename: {bookFile.name}</p>
                            <p>Filetype: {bookFile.type}</p>
                            <p>Size in bytes: {bookFile.size}</p>
                            <p>
                              lastModifiedDate:{" "}
                              {bookFile.lastModifiedDate.toLocaleDateString()}
                            </p>
                          </div>
                        ) : (
                          <p>Select a file to show details</p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        ISBN
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="text"
                          value={isbn}
                          onChange={(e) => setIsbn(e.target.value)}
                          name="isbn"
                          className=""
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Hard Copy Amount
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="text"
                          value={hardCopyAmount}
                          onChange={(e) => setHardCopyAmount(e.target.value)}
                          name="hardCopyAmount"
                          className=""
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        One off purchase
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="number"
                          value={oneTimePayment}
                          onChange={(e) => setOneTimePayment(e.target.value)}
                          name="amount"
                          className=""
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Daily Amount
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Input
                          size="lg"
                          type="text"
                          value={softCopyAmount}
                          onChange={(e) => setSoftCopyAmount(e.target.value)}
                          name="softCopyAmount"
                          className=""
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Book Type
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Select
                          options={options}
                          onChange={(e) => handleBookType(e)}
                          name="type"
                          isMulti
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Category
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Select
                          options={categoryOptions}
                          onChange={(e) => handleCategories(e)}
                          name="category"
                          isMulti
                        />
                        {selectedCats ? (
                          // <p>{selectedCats[0]}</p>
                          selectedCats.map(e => (
                            <span>{e}, </span>
                          ))
                        ) : (
                          <p>no categorie(s) selected</p>
                        )}
                        {/* <p>show me</p> */}
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        Tag
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Select
                          options={tagOptions}
                          onChange={(e) => handleTags(e)}
                          name="category"
                          isMulti
                        />
                      {selectedTags
                        ? selectedTags.map((e) => (
                            <span>{e}, </span>
                        ))
                        : "no tag(s) selected"}
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">
                        About the book
                      </label>
                      <div className="col-sm-12 col-md-7">
                        <Textarea
                          className=""
                          minRows={4}
                          value={about}
                          onChange={(e) => setAbout(e.target.value)}
                          name="about"
                        ></Textarea>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button
                          type="submit"
                          onClick={() => setLoading(true)}
                          className="btn btn-primary btn-lg btn-block"
                        >
                          {disable_upload ? (
                            <ClipLoader loading={loading} size={20} />
                          ) : (
                            "Publish"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default AuthorEditBook;
