import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ListItemIcon, ListItemButton, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import Actions from "./actions";
import * as React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import FavoriteIcon from "@mui/icons-material/Favorite";
import { styled } from "@mui/material/styles";
import { useCart } from "react-use-cart";
import background from "../assets/course_background.jpeg";
import { UseUIContext } from "../utils/ui-context";
import { Link, useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import {
  BookOnlineOutlined,
  CastForEducationOutlined,
  LibraryBooksOutlined,
  MovieOutlined,
} from "@mui/icons-material";
import { AccountMenu } from "./header";

export const AppbarContainer = styled(Box)(() => ({
  display: "flex",
  marginTop: 4,
  justifyContent: "center",
  alignItems: "center",
  padding: "2px 8px",
}));

// header
export const AppbarHeader = styled(Typography)(() => ({
  padding: "4px",
  flexGrow: 1,
  fontSize: "4rem",
  fontFamily: '"Montez", "cursive"',
  //   color: Colors.secondary,
}));

export const MyList = styled(List)(({ type }) => ({
  display: type === "row" ? "flex" : "block",
  position: "fixed",
  flexGrow: 3,
  backgroundColor: "#12a89d",
  width: "100%",
  bottom: 0,
  justifyContent: "center",
  alignItems: "center",
}));

export default function AppbarMobile({ matches }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { items, removeItem, totalUniqueItems, cartTotal } = useCart();
  const open = Boolean(anchorEl);
  const toProfile = (e) => {
    e.preventDefault();
    navigate("/profile");
  };
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //   const { setDrawerOpen, setShowSearchBox } = UseUIContext();
  return (
    <>
      <MyList type="row">
        <ListItemButton
          href="books"
          sx={{
            justifyContent: "center",
          }}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack sx={{ alignItems: "center", color: "white" }}>
              <LibraryBooksOutlined />
              <Typography>Books</Typography>
            </Stack>
          </ListItemIcon>
        </ListItemButton>
        <Divider orientation="vertical" flexItem />
        <ListItemButton
          href="/teachers"
          sx={{
            justifyContent: "center",
          }}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack sx={{ color: "white", alignItems: "center" }}>
              {/* <BookOnlineOutlined /> */}
              <CastForEducationOutlined />
              <Typography>Teachers</Typography>
            </Stack>
          </ListItemIcon>
        </ListItemButton>
        <Divider orientation="vertical" flexItem />
        <ListItemButton
          href="/medias"
          sx={{
            justifyContent: "center",
          }}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack sx={{ color: "white", alignItems: "center" }}>
              <MovieOutlined />
              <Typography>Media</Typography>
            </Stack>
          </ListItemIcon>
        </ListItemButton>
        <Divider orientation="vertical" flexItem />
        <ListItemButton
          sx={{
            justifyContent: "center",
          }}
        >
          <ListItemIcon
            onClick={handleClick}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack sx={{ color: "white", alignItems: "center" }}>
              <ShoppingCartIcon />
              <Typography>Cart</Typography>
            </Stack>
          </ListItemIcon>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                // filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: "fit-content",
                  height: "100%",
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  // bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box style={{ margin: "10px" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {items.map((item, idx) => (
                  <ListItem key={idx + 1}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      {item.type === "course" ? (
                        <Box
                          height="194"
                          sx={{
                            backgroundImage: `url(${background})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            minHeight: "100px",
                            textAlign: "center",
                            padding: 5,
                          }}
                        >
                          <Typography
                            variant="body2"
                            marginTop={1}
                            color="white"
                            sx={{ fontWeight: "400" }}
                          >
                            {item.name}
                          </Typography>
                        </Box>
                      ) : (
                        <img
                          width="80px"
                          src={
                            item.bookCoverUrl ??
                            item.video_cover_url ??
                            item.audio_cover_url
                          }
                          alt="cart item"
                        />
                      )}
                      <Box>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "bold",
                            padding: "0px 30px",
                          }}
                        >
                          {" "}
                          {item.title ?? item.name}{" "}
                        </Typography>
                        <div
                          style={{ fontWeight: "300", padding: "0px 30px" }}
                          className="author"
                        >
                          <strong>Author:</strong> {item.author ?? item.owner}
                        </div>
                        <div style={{ fontWeight: "200", padding: "0px 30px" }}>
                          {item.quantity} X {item.price}
                        </div>
                      </Box>
                      <Link
                        className="remove"
                        onClick={(e) => removeItem(item.id)}
                      >
                        <i className="fa fa-trash-o"></i>
                      </Link>
                    </Box>
                  </ListItem>
                ))}
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  style={{ margin: "10px" }}
                >
                  <div style={{ fontWeight: "bold" }} className="title">
                    Total
                  </div>
                  <div className="price">₦{cartTotal}</div>
                </Box>
                <Link to="/cart" className="btn btn-primary">
                  View Cart
                </Link>
              </List>
            </Box>
          </Menu>
        </ListItemButton>
      </MyList>
    </>
  );
}
