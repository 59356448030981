import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <>
      <div>
        <section className="page-banner services-banner">
          <div className="container">
            <div className="banner-header">
              <h2>Contact Us</h2>
              <span className="underline center"></span>
              {/* <p className="lead">The standard chunk of Lorem Ipsum used since</p> */}
            </div>
            <div className="breadcrumb">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li>contact</li>
              </ul>
            </div>
          </div>
        </section>
        <div id="content" className="site-content">
          <div id="primary" className="content-area">
            <main id="main" className="site-main">
              <div className="contact-main">
                <div className="contact-us">
                  <div className="container">
                    <div className="contact-location">
                      <div className="flipcard">
                        <div className="front">
                          <div className="top-info">
                            <span><i className="fa fa-map-marker" aria-hidden="true"></i> Office Address</span>
                          </div>
                          <div className="bottom-info">
                            <span className="top-arrow"></span>
                            <ul>
                              <li>KM 5, LASU EXPRESSWAY</li>
                              <li>LAGOS, NIGERIA</li>
                              {/* <li>PO Box 16122</li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="back">
                          <div className="bottom-info orange-bg">
                            <span className="bottom-arrow"></span>
                            <ul>
                              <li>KM 5, LASU EXPRESSWAY</li>
                              <li>LAGOS, NIGERIA</li>
                              {/* <li>PO Box 16122</li> */}
                            </ul>
                          </div>
                          <div className="top-info dark-bg">
                            <span><i className="fa fa-map-marker" aria-hidden="true"></i> Office Address</span>
                          </div>
                        </div>
                      </div>
                      <div className="flipcard">
                        <div className="front">
                          <div className="top-info">
                            <span><i className="fa fa-fax" aria-hidden="true"></i> Phone and Fax</span>
                          </div>
                          <div className="bottom-info">
                            <span className="top-arrow"></span>
                            <ul>
                              <li><Link to="tel:+2348025505812">Local: +2348025505812</Link></li>
                              <li><Link to="tel:+447503859651">Local: +447503859651</Link></li>
                              {/* <li><Link to="fax:(001)-254-7359">Fax: (001)-254-7359</Link></li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="back">
                          <div className="bottom-info orange-bg">
                            <span className="bottom-arrow"></span>
                            <ul>
                              <li><Link to="tel:+2348025505812">Local: +2348025505812</Link></li>
                              <li><Link to="tel:+447503859651">Local: +447503859651</Link></li>
                              {/* <li><Link to="fax:(001)-254-7359">Fax: (001)-254-7359</Link></li> */}
                            </ul>
                          </div>
                          <div className="top-info dark-bg">
                            <span><i className="fa fa-fax" aria-hidden="true"></i> Phone and Fax</span>
                          </div>
                        </div>
                      </div>
                      <div className="flipcard">
                        <div className="front">
                          <div className="top-info">
                            <span><i className="fa fa-envelope" aria-hidden="true"></i> Email Address</span>
                          </div>
                          <div className="bottom-info">
                            <span className="top-arrow"></span>
                            <ul>
                              <li>www.kokawe.com</li>
                              <li>support@kokawe.com</li>
                              {/* <li>info@kokawe.com</li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="back">
                          <div className="bottom-info orange-bg">
                            <span className="bottom-arrow"></span>
                            <ul>
                              <li><Link to="http://www.kokawe.com/">www.kokawe.com </Link></li>
                              <li><Link to="mailto:support@kokawe.com">support@kokawe.com</Link></li>
                              {/* <li><Link to="mailto:info@kokawe.com">info@kokawe.com</Link></li> */}
                            </ul>
                          </div>
                          <div className="top-info dark-bg">
                            <span><i className="fa fa-envelope" aria-hidden="true"></i> Email Address</span>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="row">
                      <div className="contact-area">
                        <div className="container">
                          <div className="col-md-5 col-md-offset-1 border-gray-left">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="contact-map bg-light margin-left">
                                  <div className="company-map" id="map"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 border-gray-right">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="contact-form bg-light margin-right">
                                  <h2>Send us a message</h2>
                                  <span className="underline left"></span>
                                  <div className="contact-fields">
                                    <form id="contact" name="contact" action="" method="post" >
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <div className="form-group">
                                            <input className="form-control" type="text" placeholder="First Name" name="first-name" id="first-name" size="30" value="" aria-required="true" />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <div className="form-group">
                                            <input className="form-control" type="text" placeholder="Last Name" name="last-name" id="last-name" size="30" value="" aria-required="true" />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <div className="form-group">
                                            <input className="form-control" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" placeholder="Email" name="email" id="email" size="30" value="" aria-required="true" />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <div className="form-group">
                                            <input className="form-control" type="text" placeholder="Phone Number" name="phone" id="phone" size="30" value="" aria-required="true" />
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div className="form-group">
                                            <textarea className="form-control" placeholder="Your message" id="message" aria-required="true"></textarea>
                                            <div className="clearfix"></div>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                          <div className="form-group form-submit">
                                            <input className="btn btn-default" id="submit-contact-form" type="button" name="submit" value="Send Message" />
                                          </div>
                                        </div>
                                        <div id="success">
                                          <span>Your message was sent successfully! Our team will contact you soon.</span>
                                        </div>

                                        <div id="error">
                                          <span>Something went wrong, try refreshing and submitting the form again.</span>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <section className="social-network section-padding">
          <div className="container">
            <div className="center-content">
              <h2 className="section-title">Follow Us</h2>
              <span className="underline center"></span>
              {/* <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </div>
            <ul>
              <li>
                <a className="rs" to="#" rel='noreferrer' target="_blank">
                  <span>
                    {/* <i className="fa fa-rss"></i> */}
                  </span>
                </a>
              </li>
              <li>
                <a className="facebook" href="https://www.facebook.com/profile.php?id=100087598612339" rel='noreferrer' target="_blank">
                  <span>
                    <i className="fa fa-facebook-f"></i>
                  </span>
                </a>
              </li>
              <li>
                <a className="twitter" href="https://twitter.com/kokawe01" rel='noreferrer' target="_blank">
                  <span>
                    <i className="fa fa-twitter"></i>
                  </span>
                </a>
              </li>
              <li style={{backgroundColor: 'black'}}>
                <a className="instagram" href="https://www.instagram.com/kokawe01" rel='noreferrer' target="_blank">
                  <span>
                    <i className="fa fa-instagram"></i>
                  </span>
                </a>
              </li>
              
              
              <li>
                <a className="youtube" href="https://www.youtube.com/channel/UClL6HMfatay8MECsYi_SgLA" rel='noreferrer' target="_blank">
                  <span>
                    <i className="fa fa-youtube"></i>
                  </span>
                </a>
              </li>
              <li>
                <a className="linkedn" to="#" rel='noreferrer' target="_blank">
                  <span>
                    {/* <i className="fa fa-linkedin"></i> */}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  )
}

export default Contact;
