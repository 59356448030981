function addToCart(type, items) {
    let cartArray = []
    let item = {...items, type: type};
    let cartString = localStorage.getItem('cart');
    cartArray = JSON.parse(cartString) ?? [];
    if (type === 'book') {
        if(cartArray.length > 0) {
            const result = cartArray.find(({isbn}) => isbn === item.isbn)
            if(result) {
                return "Item already in cart";
            } else {                
                cartArray.push(item);                
            }
        } else {
            cartArray.push(item);
        }
    } else {
        if(cartArray.length > 0) {
            const result = cartArray.find(({id}) => id === item.id)
            if(result) {
                return "Item already in cart";
            } else {                
                cartArray.push(item);                
            }
        } else {
            cartArray.push(item);
        }
    }
    localStorage.setItem('cart', JSON.stringify(cartArray));
    return "Item added to cart"
}

function getCart() {
    let cart = localStorage.getItem('cart');
    return JSON.parse(cart);
}

function removeFromCart(id) {
    let cartArray = [];
    let cartString = localStorage.getItem('cart');
    cartArray = JSON.parse(cartString);
    for(let i = 0; i < cartArray.length; i++) {
        if(cartArray[i].id === id) {
            const index = cartArray.indexOf(id);
            cartArray.splice(index, 1);
        }
    }
    localStorage.setItem('cart', JSON.stringify(cartArray));
    return "Item removed from cart"
}

function emptyCart() {
    localStorage.clear('cart');
}

export {addToCart, getCart, emptyCart, removeFromCart};
