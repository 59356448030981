import {
  Box,
  Button,
  FormLabel,
  Input,
  Sheet,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import http from "../../utils/http";

const AdminRoles = () => {
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);

  const addRole = async (e) => {
    e.preventDefault();
    let postData = {
      name: role,
    };
    await http.post("admin/roles", postData).then((data) => {
      toast.success(data.data.data);
    });
    setRole("");
  };

  const deleteRole = async (e, id) => {
    e.preventDefault();
    await http
      .delete("admin/roles/" + id)
      .then((data) => {
        toast.success(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    const getroles = async () => {
      let cont = [];
      const data = await http.get("admin/roles");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      setRoles(cont);
    };
    getroles();
  }, []);

  return (
    <>
      <Stack>
        <Typography
          level="h1"
          sx={{
            pb: 3,
            fontFamily: "montserrat",
          }}
        >
          Roles
        </Typography>
        <form onSubmit={addRole} method="post">
          <Box
            sx={{
              display: "flex",
              width: {
                sm: "100%",
                md: "300px",
                lg: "500px",
              },
              pb: 3,
            }}
          >
            <FormLabel
              sx={{
                fontSize: {
                  xs: "12px",
                  md: "13px",
                  lg: "20px",
                },
                pr: 3,
              }}
            >
              Role:
            </FormLabel>
            <Input
              placeholder="Role name"
              sx={{
                mb: 1,
                fontSize: "var(--joy-fontSize-xl)",
                height: "35px",
                width: {
                  xs: "100%",
                },
                px: 2,
              }}
              name="title"
              onChange={(e) => setRole(e.target.value)}
              value={role}
              endDecorator={<Button>Submit</Button>}
            />
          </Box>
          <div className="card-body"></div>
        </form>
      </Stack>

      <Box sx={{ width: "100%" }}>
        <Sheet
          variant="outlined"
          sx={{
            "--TableCell-height": "40px",
            // the number is the amount of the header rows.
            "--TableHeader-height": "calc(1 * var(--TableCell-height))",
            "--Table-firstColumnWidth": "80px",
            "--Table-lastColumnWidth": "144px",
            // background needs to have transparency to show the scrolling shadows
            "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
            "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
            overflow: "auto",
            background: (theme) =>
              `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
            backgroundSize:
              "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local, local, scroll, scroll",
            backgroundPosition:
              "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
            backgroundColor: "background.surface",
          }}
        >
          <Table
            borderAxis="bothBetween"
            stripe="odd"
            hoverRow
            sx={{
              "& tr > *:first-child": {
                position: "sticky",
                left: 0,
                boxShadow: "1px 0 var(--TableCell-borderColor)",
                bgcolor: "background.surface",
              },
              "& tr > *:last-child": {
                position: "sticky",
                right: 0,
                bgcolor: "var(--TableCell-headBackground)",
              },
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "var(--Table-firstColumnWidth)" }}>#</th>
                <th style={{ width: 200 }}>Name</th>
                <th style={{ width: 200 }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {roles
                ? roles.map((role, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{role.name}</td>

                      <td>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            onClick={(e) => deleteRole(e, role.id)}
                            size="sm"
                            variant="soft"
                            color="danger"
                          >
                            Delete
                          </Button>
                        </Box>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </Sheet>
      </Box>

      <ToastContainer />
    </>
  );
};

export default AdminRoles;
