import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import { getProfile } from "../utils/get-profile";
// import useScript from "../utils/useScript";

export default function RootRoutes() {
  useEffect(() => {
    const Profile = async () => {
      let myProfile = await getProfile();
      if (myProfile.length !== 0 && myProfile !== "Unauthenticated.") {
        localStorage.setItem("profile", myProfile.data.data[0].name);
      }
    };
    Profile();
  }, []);
  return (
    <>
      <div>
        <Header />
        <Outlet />
        <Footer />
      </div>
      {/* <HelmetProvider>
        <Helmet>
          
        </Helmet>
      </HelmetProvider> */}
    </>
  );
}
