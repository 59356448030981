import { useEffect, useState } from "react";
import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import Sidebar from "../components/side-bar";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SideDrawer from "../components/side-drawer";
import useScript from "../utils/useScript";
import { CssVarsProvider } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import ColorSchemeToggle from "../components/ColorSchemeToggle";
import customTheme from "../theme";
import Header2 from "../components/Header2";
import FirstSidebar from "../components/FirstSidebar";
import SecondSidebar from "../components/SecondSidebar";
import { Avatar } from "@mui/joy";
import http from "../utils/http";
import { getProfile } from "../utils/get-profile";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

const AuthorRoutes = () => {
  const feather = useScript(`https://unpkg.com/feather-icons`);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [feather]);
  let token = localStorage.getItem("token");
  let auth = { token: token };
  let navigate = useNavigate();
  useEffect(() => {
    const getUserType = async () => {
      const admin = await getProfile();
      if (admin === "Unauthenticated.") {
        localStorage.clear("token");
        navigate("/login");
      }
    };
    getUserType();
  }, []);

  return (
    <>
      <CssVarsProvider disableTransitionOnChange theme={customTheme}>
        <GlobalStyles
          styles={{
            "[data-feather], .feather": {
              color: "var(--Icon-color)",
              margin: "var(--Icon-margin)",
              fontSize: "var(--Icon-fontSize, 25px)",
              width: "1em",
              height: "1em",
            },
          }}
        />
        <CssBaseline />
        <Box sx={{ display: "flex", minHeight: "100dvh" }}>
          <Header2 />
          {/* <FirstSidebar /> */}
          <SecondSidebar />
          {/* <SideDrawer /> */}

          <Box
            component="main"
            className="MainContent"
            sx={(theme) => ({
              px: {
                xs: 2,
                md: 6,
              },
              pt: {
                xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
                sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
                md: 3,
              },
              pb: {
                xs: 2,
                sm: 2,
                md: 3,
              },
              flex: 1,
              display: "flex",
              position: {
                lg: 'relative',
              },
              marginLeft: {
                lg: 30,
              },
              flexDirection: "column",
              minWidth: 0,
              height: "100dvh",
              gap: 1,
            })}
          >
            <Box sx={{ 
              display: "flex",
              alignItems: "center",
            }}>
              <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<i data-feather="chevron-right" />}
                sx={{
                  "--Breadcrumbs-gap": "1rem",
                  "--Icon-fontSize": "16px",
                  fontWeight: "lg",
                  color: "neutral.400",
                  px: 0,
                }}
              >
                <Link
                  underline="none"
                  color="neutral"
                  fontSize="inherit"
                  href="#some-link"
                  aria-label="Home"
                >
                  <i data-feather="home" />
                </Link>
                {/* <Link
                  underline="hover"
                  color="neutral"
                  fontSize="inherit"
                  href="#some-link"
                >
                  Dashboard
                </Link>
                <Typography fontSize="inherit" variant="soft" color="primary">
                  Orders
                </Typography> */}
              </Breadcrumbs>
              <ColorSchemeToggle
                sx={{ ml: "auto", display: { xs: "none", md: "inline-flex" } }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                my: 1,
                gap: 1,
                flexWrap: "wrap",
                "& > *": {
                  minWidth: "clamp(0px, (500px - 100%) * 999, 100%)",
                  flexGrow: 1,
                },
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </CssVarsProvider>
    </>
  );
};

export default AuthorRoutes;
