import { Chip, Stack, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { API_ENDPOINTS } from "../utils/api-endpoints";
import { v4 } from "uuid";
import ProgressBar from "@ramonak/react-progress-bar";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {encrypt} from '../utils/encryptor';
import { toast, ToastContainer } from "react-toastify";
import http from "../utils/http";

const config = {
  region: process.env.REACT_APP_S3_REGION,
  credentials: {
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY        
  }
}

const client = new S3Client(config);

function TeacherProfile() {
  const [mathVariant, setMathVariant] = useState("outlined");
  const [physicsVariant, setPhysicsVariant] = useState("outlined");
  const [chemistryVariant, setChemistryVariant] = useState("outlined");
  const [englishVariant, setEnglishVariant] = useState("outlined");
  const [igboVariant, setIgboVariant] = useState("outlined");
  const [yorubaVariant, setYorubaVariant] = useState("outlined");
  const [biologyVariant, setBiologyVariant] = useState("outlined");
  const [loading, setLoading] = useState(true);
  const [photoUrl, setPhotoUrl] = useState('');
  const [isFilePicked, setIsFilePicked] = useState();
  const [progress, setProgress] = useState(0);
  const [certProgress, setCertProgress] = useState(0);
  const [photoFile, setPhotoFile] = useState();
  const [certFile, setCertFile] = useState();
  const [biography, setBiography] = useState('');
  const [isCertPicked, setIsCertPicked] = useState();
  const [status, setStatus] = useState("");
  const [highestQualification, setHighestQualification] = useState('');
  const [certificate, setCertificate] = useState('');
  const [certificateUrl, setCertificateUrl] = useState('');
  const [disable_login, setLoginStatus] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [schedule, setSchedule] = useState('');
  const [calendlyLink, setCalendlyLink] = useState('');
  const [monday, setMonday] = useState(false);
  const [meetingLink, setMeetingLink] = useState('');
  const [meetingPassword, setMeetingPassword] = useState('');
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [mondayTime, setMondayTime] = useState({
    from: '',
    to: '',
  });
  const [tuesdayTime, setTuesdayTime] = useState({
    from: '',
    to: '',
  });
  const [wednesdayTime, setWednesdayTime] = useState({
    from: '',
    to: '',
  });
  const [thursdayTime, setThursdayTime] = useState({
    from: '',
    to: '',
  });
  const [fridayTime, setFridayTime] = useState({
    from: '',
    to: '',
  });
  const [amount, setAmount] = useState(0);
  const [error_message] = useState("");
  const navigate = useNavigate();

  const selectSubject = async (e, subject) => {
    setSubjects([...subjects, subject]);
    if (subject === "mathematics") {
      setMathVariant("filled");
    }
    if (subject === "biology") {
      setBiologyVariant("filled");
    }
    if (subject === "igbo") {
      setIgboVariant("filled");
    }
    if (subject === "yoruba") {
      setYorubaVariant("filled");
    }
    if (subject === "chemistry") {
      setChemistryVariant("filled");
    }
    if (subject === "physics") {
      setPhysicsVariant("filled");
    }
    if (subject === "english") {
      setEnglishVariant("filled");
    }
  };

  const UploadPhoto = async (photo) => {
    let type = photo.type.split('/')[1];
    const photoNameKey = "photo/";
    const photoKey = photoNameKey + v4() + '.' + type;
    await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: photoKey, Body: photo}))
    .then((data) => {
        if(data) {
            setProgress(100);
        }
    }).catch(err => toast.error(err));
    const _photoUrl = encrypt(process.env.REACT_APP_S3_CLOUDFRONT_URL + photoKey)
    setPhotoUrl(_photoUrl);
  }

  const photoUploadEvent = (e) => {
    e.preventDefault();
    setIsFilePicked(true);
    setPhotoFile(e.target.files[0])
    UploadPhoto(e.target.files[0])
  }

  const UploadCertificate = async (cert) => {
    let type = cert.type.split('/')[1];
    const certNameKey = "certificate/";
    const certKey = certNameKey + v4() + '.' + type;
    await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: certKey, Body: cert}))
    .then((data) => {
        if(data) {
            setCertProgress(100);
        }
    }).catch(err => toast.error(err));
    const _certUrl = encrypt(process.env.REACT_APP_S3_CLOUDFRONT_URL + certKey)
    setCertificateUrl(_certUrl);
  }

  const certificateUploadEvent = (e) => {
    e.preventDefault();
    setIsCertPicked(true);
    setCertFile(e.target.files[0])
    UploadCertificate(e.target.files[0])
  }

  const handleSchedule = async () => {
    setSchedule({
      monday: {
        time: mondayTime
      },
      tuesday: {
        time: tuesdayTime
      },
      wednesday: {
       time: wednesdayTime
      },
      thursday: {
        time: thursdayTime
      },
      friday: {
        time: fridayTime,
      }
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoginStatus(true);
    // handleSchedule()
    
    let postData = {
      amount: amount,
      photo_url: photoUrl,
      meetingLink: meetingLink,
      biography: biography,
      meetingPassword: meetingPassword,
      subjects: subjects.join(','),
      schedule: schedule,
      calendlyLink: calendlyLink,
      highestQualification: highestQualification,
      certificate: certificate,
      certificateUrl: certificateUrl,
    };

    console.log(postData);

    await http
      .post(API_ENDPOINTS.TEACHER, postData)
      .then((data) => {
        if(data.data) {
          toast(data.data);
          navigate('/my-appointments');
        }
      })
      .catch((err) => {
        setLoginStatus(false);
        console.log(err);
      });
  };

  return (
    <div className="Flex-Display">
      {/* <AuthSidePane /> */}
      <div className="Register">
        <div className="login-brand">
          <a href="/">
            <img src="./logo.png" alt="logo" width="200" />
          </a>
        </div>

        <div className="card card-primary">
          <div className="card-header">
            <h4>Set up your Account</h4>
          </div>
          <div
            style={{ color: "red", fontWeight: "bold", textAlign: "center" }}
          >
            {status}
          </div>
          <div className="card-body">
            <span>
              <small>
                <b>{error_message}</b>
              </small>
            </span>
            <form method="POST" onSubmit={(e) => handleSubmit(e)}>
              <div className="form-divider">Profile</div>
              <div className="form-group">
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                    Photo <br /><small>*Upload photo that has a clear view of your face</small>
                  </label>
                  <div className="col-sm-9">
                    <input type="file" alt="photo" id="photo" onChange={(e) => photoUploadEvent(e)} className="form-control" name="photo"/>
                    <ProgressBar completed={progress} />
                    {isFilePicked ? (
                        <div>
                            <p>Filename: {photoFile.name}</p>
                            <p>Filetype: {photoFile.type}</p>
                            <p>Size in bytes: {photoFile.size}</p>
                        </div>
                    ) : (
                        <p>Select a file to show details</p>
                    )}
                  </div>
                </div>
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                    Biography <br /><small>*Write a little about yourself</small>
                  </label>
                  <div className="col-sm-9">
                    <textarea type="text" alt="biography" id="biography" onChange={(e) => setBiography(e.target.value)} className="form-control" name="biography"/>
                  </div>
                </div>
              </div>
              <div className="form-divider">Subjects <small>(Select lesson subjects)</small></div>
              <div className="row text-center g-3 mx-auto">
                <Chip
                  className="m-3"
                  label="Mathematics"
                  onClick={(e) => selectSubject(e, "mathematics")}
                  variant={mathVariant}
                />
                <Chip
                  className="m-3"
                  label="English"
                  onClick={(e) => selectSubject(e, "english")}
                  variant={englishVariant}
                />
                <Chip
                  className="m-3"
                  label="Yoruba"
                  onClick={(e) => selectSubject(e, "yoruba")}
                  variant={yorubaVariant}
                />
                <Chip
                  className="m-3"
                  label="Biology"
                  onClick={(e) => selectSubject(e, "biology")}
                  variant={biologyVariant}
                />
                <Chip
                  className="m-3"
                  label="Physics"
                  onClick={(e) => selectSubject(e, "physics")}
                  variant={physicsVariant}
                />
                <Chip
                  className="m-3"
                  label="Chemistry"
                  onClick={(e) => selectSubject(e, "chemistry")}
                  variant={chemistryVariant}
                />
                <Chip
                  className="m-3"
                  label="Igbo"
                  onClick={(e) => selectSubject(e, "igbo")}
                  variant={igboVariant}
                />
              </div>

              <div className="form-divider">Qualifications <small>(Submit proficiency certificates/qualifications)</small></div>
              <div className="form-group">
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                    Highest Qualification
                  </label>
                  <div className="col-sm-9">
                    <select className="form-control py-2" onChange={(e) => setHighestQualification(e.target.value)} name="highestQualification">
                      <option defaultValue={''}>Select highest Qualification</option>
                      <option value="o-level">O'level</option>
                      <option value="a-level">A'level</option>
                      <option value="ond/hnd">OND/HND</option>
                      <option value="bsc">BSC</option>
                      <option value="msc">MSC</option>
                    </select>
                  </div>
                </div>
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                    Qualification/Certification
                  </label>
                  <div className="col-sm-9">
                    <input type="text" onChange={(e) => setCertificate(e.target.value)} id="qualification" placeholder="BSC. English and Literary Studies" className="form-control" name="certificate"/>
                  </div>
                </div>
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                    Upload Qualifications
                  </label>
                  <div className="col-sm-9">
                    <input type="file" id="certificateUrl" onChange={(e) => certificateUploadEvent(e)} className="form-control" name="certificateUrl"/>
                    <ProgressBar completed={certProgress} />
                    {isCertPicked ? (
                        <div>
                            <p>Filename: {certFile.name}</p>
                            <p>Filetype: {certFile.type}</p>
                            <p>Size in bytes: {certFile.size}</p>
                        </div>
                    ) : (
                        <p>Select a file to show details</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-divider">
                Schedule <small>(Schedule daily availability for lessons)</small>
                </div>
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-3 col-form-label">
                      Calendly Link
                  </label>
                  <div className="col-sm-9">
                    <input type="text" onChange={(e) => setCalendlyLink(e.target.value)} id="calendlyUrl" placeholder="e.g https://calendly.com/d_oracle" className="form-control" name="calendlyUrl" required/>
                  </div>
                </div>
              {/* <div className="form-group">
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                    Monday
                  </label>
                  <div className="col-sm-3">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Off</Typography>
                      <Switch
                        checked={monday}
                        onChange={e => setMonday(monday ? false : true)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  </div>
                  { monday ? <>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">From</label>
                      <input
                        type="time"
                        className="form-control"
                        onChange={e => {setMondayTime({from: e.target.value, to: mondayTime.to}); handleSchedule()}}
                      />
                    </div>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">To</label>
                      <input type="time" id="appt" className="form-control" onChange={e => {setMondayTime({from: mondayTime.from, to: e.target.value}); handleSchedule()}} name="appt" min="09:00" max="18:00" required/>
                    </div></> : ""
                  }
                </div>
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                    Tuesday
                  </label>
                  <div className="col-sm-3">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                        <Switch
                          checked={tuesday}
                          onChange={e => setTuesday(tuesday ? false : true)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      <Typography>On</Typography>
                    </Stack>
                  </div>
                  {
                    tuesday ? <>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">From</label>
                      <input type="time" id="appt" className="form-control" onChange={e => {setTuesdayTime({from: e.target.value, to: tuesdayTime.to}); handleSchedule()}} name="appt" min="09:00" max="18:00" required/>
                    </div>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">To</label>
                      <input type="time" id="appt" className="form-control" onChange={e => {setTuesdayTime({from: tuesdayTime.from,  to: e.target.value}); handleSchedule()}} name="appt" min="09:00" max="18:00" required/>
                    </div></> : ''
                  }
                </div>
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                    Wednesday
                  </label>
                  <div className="col-sm-3">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                        <Switch
                          checked={wednesday}
                          onChange={e => setWednesday(wednesday ? false : true)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      <Typography>On</Typography>
                    </Stack>
                  </div>
                  {
                    wednesday ? <>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">From</label>
                      <input type="time" id="appt" onChange={e => {setWednesdayTime({from: e.target.value, to: wednesdayTime.to}); handleSchedule()}} className="form-control" name="appt" min="09:00" max="18:00" required/>
                    </div>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">To</label>
                      <input type="time" id="appt"  onChange={e => {setWednesdayTime({from: wednesdayTime.from, to: e.target.value}); handleSchedule()}} className="form-control" name="appt" min="09:00" max="18:00" required/>
                    </div></> : ''
                  }
                </div>
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                    Thursday
                  </label>
                  <div className="col-sm-3">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                        <Switch
                          checked={thursday}
                          onChange={e => setThursday(thursday ? false : true)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      <Typography>On</Typography>
                    </Stack>
                  </div>
                  {
                    thursday ? <>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">From</label>
                      <input type="time" id="appt" onChange={e => {setThursdayTime({from: e.target.value, to: thursdayTime.to}); handleSchedule()}} className="form-control" name="appt" min="09:00" max="18:00" required/>
                    </div>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">To</label>
                      <input type="time" id="appt" onChange={e => {setThursdayTime({from: thursdayTime.from, to: e.target.value}); handleSchedule()}} className="form-control" name="appt" min="09:00" max="18:00" required/>
                    </div></> : ''
                  }
                </div>
                <div className="row mx-auto mb-3">
                  <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
                    Friday
                  </label>
                  <div className="col-sm-3">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Off</Typography>
                        <Switch
                          checked={friday}
                          onChange={e => setFriday(friday ? false : true)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      <Typography>On</Typography>
                    </Stack>
                  </div>
                  {
                    friday ? <>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">From</label>
                      <input type="time" id="appt" onChange={e => {setFridayTime({from: e.target.value, to: fridayTime.to}); handleSchedule()}} className="form-control" name="appt" min="09:00" max="18:00" required/>
                    </div>
                    <div className="col-sm-3">
                      <label htmlhtmlFor="">To</label>
                      <input type="time" id="appt" onChange={e => {setFridayTime({from: fridayTime.from, to: e.target.value}); handleSchedule()}} className="form-control" name="appt" min="09:00" max="18:00" required/>
                    </div></> : ''
                  }
                </div>
                <div className="row mx-auto mb-3">
                <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">Personal Meeting Invite Link</label>
                <div className="col-sm-4 input-group mb-3">
                  <input type="url" id="meeting" onChange={e => setMeetingLink(e.target.value)} className="form-control" name="meeting" required/>
                </div>
                <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-3">
                  <input type="text" id="password" onChange={e => setMeetingPassword(e.target.value)} className="form-control" name="password" required/>
                </div>
              </div>
              </div> */}
              <div className="form-divider">
                Pay <small>(Set amount for lessons)</small>
              </div>
              
              <div className="row mx-auto mb-3">
                <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">Amount</label>
                <div className="col-sm-4 input-group mb-3">
                  <span className="input-group-text">₦</span>
                  <input type="number" id="amount" onChange={e => setAmount(e.target.value)} className="form-control" name="amount" required/>
                  <span className="input-group-text">hour</span>
                </div>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  onClick={() => setLoading(true)}
                  className="btn btn-primary btn-lg btn-block"
                  tabIndex="4"
                >
                  {disable_login ? (
                    <ClipLoader loading={loading} size={20} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default TeacherProfile;
