import * as React from "react";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemButton from "@mui/joy/ListItemButton";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { closeSidebar } from "../utils";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "../utils/get-profile";

export default function SecondSidebar() {
  const navigate = useNavigate();
  const [admin, setAdmin] = React.useState(true);
  const [profile, setProfile] = React.useState();
  const [userType, setUserType] = React.useState("");

  React.useEffect(() => {
    const getUserType = async () => {
      const admin = await getProfile();
      setProfile(admin.data.data[0]);
      if (admin === "Unauthenticated.") {
        localStorage.clear("token");
        <Navigate to={"/login"} />;
      } else {
        setUserType(admin.data.data[0].user_type);
      }
      // if (admin.data.data[0].role[0].name) {
      //   setAdmin(true);
      // }
    };
    getUserType();
  }, []);

  let currentRoute = useLocation();
  return (
    <React.Fragment>
      <Box
        className="SecondSidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          bgcolor: "background.body",
          opacity: "calc(var(--SideNavigation-slideIn, 0) - 0.2)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className="SecondSidebar"
        sx={{
          position: {
            xs: "fixed",
            lg: "fixed",
          },
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
            lg: "none",
          },
          borderRight: "1px solid",
          borderColor: "divider",
          transition: "transform 0.4s",
          zIndex: 9999,
          height: "100dvh",
          top: 0,
          p: 2,
          py: 3,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <List
          sx={{
            "--ListItem-radius": "8px",
            "--ListItem-minHeight": "32px",
            "--List-gap": "4px",
          }}
        >
          {" "}
          {admin ? (
            <>
              <ListSubheader role="presentation" sx={{ color: "text.primary" }}>
                Admin
              </ListSubheader>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-dashboard" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-dashboard" ? "soft" : ""
                  }
                  onClick={() => {navigate("/admin-dashboard"); closeSidebar()}}
                >
                  <ListItemDecorator>
                    <i data-feather="activity" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Dashboard</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-users" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-users" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-users")}
                >
                  <ListItemDecorator>
                    <i data-feather="bell" />
                  </ListItemDecorator>
                  <ListItemContent>Admin users</ListItemContent>
                  {/* <Chip variant="soft" size="sm">
                    10
                  </Chip> */}
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-teachers" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-teachers" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-teachers")}
                >
                  <ListItemDecorator>
                    <i data-feather="bell" />
                  </ListItemDecorator>
                  <ListItemContent>Admin teachers</ListItemContent>
                  {/* <Chip variant="soft" size="sm">
                    10
                  </Chip> */}
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-roles" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-roles" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-roles")}
                >
                  <ListItemDecorator>
                    <i data-feather="bar-chart" />
                  </ListItemDecorator>
                  <ListItemContent>Admin roles</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-books" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-books" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-books")}
                >
                  <ListItemDecorator>
                    <i data-feather="star" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Books</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-percentages"
                      ? true
                      : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-percentages" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-percentages")}
                >
                  <ListItemDecorator>
                    <i data-feather="shopping-cart" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Percentages</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-articles" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-articles" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-articles")}
                >
                  <ListItemDecorator>
                    <i data-feather="user" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Articles</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-categories" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-categories" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-categories")}
                >
                  <ListItemDecorator>
                    <i data-feather="settings" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Categories</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-tags" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-tags" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-tags")}
                >
                  <ListItemDecorator>
                    <i data-feather="settings" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Tags</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-subjects" ? true : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-subjects" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-subjects")}
                >
                  <ListItemDecorator>
                    <i data-feather="settings" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Subjects</ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-book-transactions"
                      ? true
                      : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-book-transactions"
                      ? "soft"
                      : ""
                  }
                  onClick={() => navigate("/admin-book-transactions")}
                >
                  <ListItemDecorator>
                    <i data-feather="settings" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Book Transactions</ListItemContent>
                </ListItemButton>
              </ListItem>{" "}
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-sub-transactions"
                      ? true
                      : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-sub-transactions"
                      ? "soft"
                      : ""
                  }
                  onClick={() => navigate("/admin-sub-transactions")}
                >
                  <ListItemDecorator>
                    <i data-feather="settings" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Subscription Transactions</ListItemContent>
                </ListItemButton>
              </ListItem>{" "}
              <ListItem>
                <ListItemButton
                  selected={
                    currentRoute.pathname === "/admin-withdrawals"
                      ? true
                      : false
                  }
                  variant={
                    currentRoute.pathname === "/admin-withdrawals" ? "soft" : ""
                  }
                  onClick={() => navigate("/admin-withdrawals")}
                >
                  <ListItemDecorator>
                    <i data-feather="settings" />
                  </ListItemDecorator>
                  <ListItemContent>Admin Withdrawals</ListItemContent>
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            ""
          )}
          {/* <ListItem>
          <ListItemButton selected={currentRoute.pathname === "/admin-consultations" ? true : false}
            variant={currentRoute.pathname === "/admin-consultations" ? "soft" : ""} onClick={() => navigate('/admin-consultations')}>
              <ListItemDecorator>
                <i data-feather="settings" />
                </ListItemDecorator>
                <ListItemContent>Consultations</ListItemContent>
            </ListItemButton>
          </ListItem> 
          <ListItem>
            <ListItemButton selected={currentRoute.pathname === "/admin-settings" ? true : false}
            variant={currentRoute.pathname === "/admin-settings" ? "soft" : ""} onClick={() => navigate('/admin-settings')}>
            <ListItemDecorator>
            <i data-feather="settings" />
            </ListItemDecorator>
            <ListItemContent>Settings</ListItemContent>
            </ListItemButton>
          </ListItem> */}
          <ListSubheader role="presentation" sx={{ color: "text.primary" }}>
            Author
          </ListSubheader>
          <ListItem>
            <ListItemButton
              selected={currentRoute.pathname === "/dashboard" ? true : false}
              variant={currentRoute.pathname === "/dashboard" ? "soft" : ""}
              onClick={() => {navigate("/dashboard"); closeSidebar()}}
            >
              <ListItemDecorator>
                <i data-feather="activity" />
              </ListItemDecorator>
              <ListItemContent>Author Dashboard</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={currentRoute.pathname === "/upload-book" ? true : false}
              variant={currentRoute.pathname === "/upload-book" ? "soft" : ""}
              onClick={() => {navigate("/upload-book"); closeSidebar()}}
            >
              <ListItemDecorator>
                <i data-feather="bell" />
              </ListItemDecorator>
              <ListItemContent>Upload Book</ListItemContent>
              {/* <Chip variant="soft" size="sm">
                10
              </Chip> */}
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={
                currentRoute.pathname === "/author-books" ? true : false
              }
              variant={currentRoute.pathname === "/author-books" ? "soft" : ""}
              onClick={() => {navigate("/author-books"); closeSidebar()}}
            >
              <ListItemDecorator>
                <i data-feather="bar-chart" />
              </ListItemDecorator>
              <ListItemContent>My Books</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={
                currentRoute.pathname === "/upload-media" ? true : false
              }
              variant={currentRoute.pathname === "/upload-media" ? "soft" : ""}
              onClick={() => {navigate("/upload-media"); closeSidebar()}}
            >
              <ListItemDecorator>
                <i data-feather="star" />
              </ListItemDecorator>
              <ListItemContent>Medias</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={
                currentRoute.pathname === "/author-medias" ? true : false
              }
              variant={currentRoute.pathname === "/author-medias" ? "soft" : ""}
              onClick={() => {navigate("/author-medias"); closeSidebar()}}
            >
              <ListItemDecorator>
                <i data-feather="shopping-cart" />
              </ListItemDecorator>
              <ListItemContent>My Medias</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={currentRoute.pathname === "/new-article" ? true : false}
              variant={currentRoute.pathname === "/new-article" ? "soft" : ""}
              onClick={() => {navigate("/new-article"); closeSidebar()}}
            >
              <ListItemDecorator>
                <i data-feather="user" />
              </ListItemDecorator>
              <ListItemContent>Write Article</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={
                currentRoute.pathname === "/author-articles" ? true : false
              }
              variant={
                currentRoute.pathname === "/author-articles" ? "soft" : ""
              }
              onClick={() => {navigate("/author-articles"); closeSidebar()}}
            >
              <ListItemDecorator>
                <i data-feather="settings" />
              </ListItemDecorator>
              <ListItemContent>My Articles</ListItemContent>
            </ListItemButton>
          </ListItem>
          
          {/* <ListItem>
            <ListItemButton
              selected={
                currentRoute.pathname === "/author-subscription" ? true : false
              }
              variant={
                currentRoute.pathname === "/author-subscription" ? "soft" : ""
              }
              onClick={() => navigate("/author-subscription")}
            >
              <ListItemDecorator>
                <i data-feather="settings" />
              </ListItemDecorator>
              <ListItemContent>Subscriptions</ListItemContent>
            </ListItemButton>
          </ListItem>{" "} */}
          <ListItem>
            <ListItemButton
              selected={
                currentRoute.pathname === "/author-wallet" ? true : false
              }
              variant={currentRoute.pathname === "/author-wallet" ? "soft" : ""}
              onClick={() => navigate("/author-wallet")}
            >
              <ListItemDecorator>
                <i data-feather="settings" />
              </ListItemDecorator>
              <ListItemContent>Wallet</ListItemContent>
            </ListItemButton>
          </ListItem>{" "}
          {/* <ListItem>
            <ListItemButton
              selected={
                currentRoute.pathname === "/author-settings" ? true : false
              }
              variant={
                currentRoute.pathname === "/author-settings" ? "soft" : ""
              }
              onClick={() => navigate("/author-settings")}
            >
              <ListItemDecorator>
                <i data-feather="settings" />
              </ListItemDecorator>
              <ListItemContent>Settings</ListItemContent>
            </ListItemButton>
          </ListItem> */}
        </List>
        <Box sx={{ pl: 1, mt: "auto", display: "flex", alignItems: "center" }}>
          <div>
            <Typography fontWeight="lg" level="body2">
              {profile ? profile.name : ""}
            </Typography>
            <Typography level="body2">{profile ? profile.email : ""}</Typography>
          </div>
          <IconButton variant="plain" sx={{ ml: "auto" }}>
            <i data-feather="log-out" />
          </IconButton>
        </Box>
      </Sheet>
    </React.Fragment>
  );
}
