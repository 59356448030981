import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { RWebShare } from "react-web-share";
import BookLoader from "../components/book-loader";
import http from "../utils/http";

function Events() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const getEvents = async () => {
            const {data} = await http.get(`events/all`);
            setEvents(data.data)
            console.log(data)
        }
        getEvents()
    }, [])

    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>events</h2>
                        <span className="underline center"></span>
                        {/* <p className="lead">Rando text here</p> */}
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>events</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <div className="books-full-width">
                            <div className="container">
                                <div className="booksmedia-fullwidth">
                                    <ul>
                                        {
                                            !events ? (<BookLoader />) : events.map((event, idx) => 
                                                <li key={idx}>
                                                    <Link to={"/event/"+event.id}>
                                                    <figure>
                                                        <Link to={"/event/"+event.id}><img src={event.banner} alt="Book" /></Link>
                                                        <figcaption>
                                                            <header>
                                                                <h4><Link to={"/event/"+event.id}>{event.title}</Link></h4>
                                                                <p><strong>Date:</strong> {event.event_time.split(' ')[0]}</p>
                                                                <p><strong>Location:</strong> {event.location}</p>
                                                            </header>
                                                        </figcaption>
                                                    </figure>
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default Events;