import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react';
import { Outlet } from 'react-router-dom'


const AuthRoutes = () => {
    
    return (
        <>           
            <HelmetProvider>
                <Helmet>
                    <link rel="stylesheet" href="../assets/css/style.min.css" />
                    <link rel="stylesheet" href="../assets/css/components.min.css" />
                    <link rel="stylesheet" href="../assets/modules/jqvmap/dist/jqvmap.min.css" />
                    <link rel="stylesheet" href="../assets/modules/bootstrap/css/bootstrap.min.css" />
                    {/* <script src="../assets/modules/chocolat/dist/js/jquery.chocolat.min.js"></script>
                    <script src="../assets/modules/jqvmap/dist/jquery.vmap.min.js"></script>
                    <script src="../assets/page/index-0.js"></script>
                    <script src="../assets/CodiePie.js"></script>
                    <script src="../assets/bundles/lib.vendor.bundle.js"></script>
                    <script src="../assets/scripts.js"></script>
                    <script src="../assets/custom.js"></script> */}
                </Helmet>
                <div id="app">
                    <Outlet />
                </div>
            </HelmetProvider>
        </>
    )
}

export default AuthRoutes;
