import React from "react";
import { Link } from "react-router-dom";

export default function Services() {
    return (
        <>
        <section class="page-banner services-banner">
            <div class="container">
                <div class="banner-header">
                    <h2>Our Services</h2>
                    <span class="underline center"></span>
                    {/* <p class="lead">The standard chunk of Lorem Ipsum used since</p> */}
                </div>
                <div class="breadcrumb">
                    <ul>
                        <li><Link to="/home">Home</Link></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </section>
        <div id="content" class="site-content">
            <div id="primary" class="content-area">
                <main id="main" class="site-main">
                    <div class="services-main">
                        <div class="services-pg">                            
                            <section class="services-offering">
                                <div class="container">
                                    <div class="center-content">
                                        <h2 class="section-title">SERVICES WE ARE OFFERING</h2>
                                        <span class="underline center"></span>
                                        <p class="lead">Things we can help you achieve</p>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="contact-location">
                                        <div class="flipcard">
                                            <div class="front">
                                                <div class="top-info">
                                                    <h3><i class="fa fa-credit-card" aria-hidden="true"></i><span>Book Translation</span></h3>
                                                </div>
                                                <div class="bottom-info">
                                                    <span class="top-arrow"></span>
                                                    <p>We can help you translate your books to more than 30 languages</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="bottom-info orange-bg">
                                                    <span class="bottom-arrow"></span>
                                                    <p>We can help you translate your books to more than 30 languages</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                                <div class="top-info dark-bg">
                                                    <h3><i class="fa fa-credit-card" aria-hidden="true"></i><span>Book Translation</span></h3>
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="flipcard">
                                            <div class="front">
                                                <div class="top-info">
                                                    <h3><i class="fa fa-desktop" aria-hidden="true"></i><span>Make Audio books</span></h3>
                                                </div>
                                                <div class="bottom-info">
                                                    <span class="top-arrow"></span>
                                                    <p>We can convert your books into audio formats.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="bottom-info orange-bg">
                                                    <span class="bottom-arrow"></span>
                                                    <p>We can convert your books into audio formats.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                                <div class="top-info dark-bg">
                                                    <h3><i class="fa fa-desktop" aria-hidden="true"></i><span>Make Audio Books</span></h3>
                                                </div>                                                
                                            </div>
                                        </div>
                                        {/* <div class="flipcard">
                                            <div class="front">
                                                <div class="top-info">
                                                    <h3><i class="fa fa-microphone" aria-hidden="true"></i><span>Meeting Rooms</span></h3>
                                                </div>
                                                <div class="bottom-info">
                                                    <span class="top-arrow"></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor turpis.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="bottom-info orange-bg">
                                                    <span class="bottom-arrow"></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor turpis.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                                <div class="top-info dark-bg">
                                                    <h3><i class="fa fa-microphone" aria-hidden="true"></i><span>Meeting Rooms</span></h3>
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="flipcard">
                                            <div class="front">
                                                <div class="top-info">
                                                    <h3><i class="fa fa-truck" aria-hidden="true"></i><span>Home Delivery</span></h3>
                                                </div>
                                                <div class="bottom-info">
                                                    <span class="top-arrow"></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor turpis.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="bottom-info">
                                                    <span class="bottom-arrow"></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor turpis.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                                <div class="top-info">
                                                    <h3><i class="fa fa-truck" aria-hidden="true"></i><span>Home Delivery</span></h3>
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="flipcard">
                                            <div class="front">
                                                <div class="top-info">
                                                    <h3><i class="fa fa-book" aria-hidden="true"></i><span>InterLibrary Loan</span></h3>
                                                </div>
                                                <div class="bottom-info">
                                                    <span class="top-arrow"></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor turpis.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="bottom-info">
                                                    <span class="bottom-arrow"></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor turpis.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                                <div class="top-info">
                                                    <h3><i class="fa fa-book" aria-hidden="true"></i><span>InterLibrary Loan</span></h3>
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="flipcard">
                                            <div class="front">
                                                <div class="top-info">
                                                    <h3><i class="fa fa-briefcase" aria-hidden="true"></i><span>Job Help</span></h3>
                                                </div>
                                                <div class="bottom-info">
                                                    <span class="top-arrow"></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor turpis.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="bottom-info">
                                                    <span class="bottom-arrow"></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dolor turpis.</p>
                                                    <Link to="/">View Selection <i class="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                                </div>
                                                <div class="top-info">
                                                    <h3><i class="fa fa-briefcase" aria-hidden="true"></i><span>Job Help</span></h3>
                                                </div>                                                
                                            </div>
                                        </div> */}
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        </>
    )
}