import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {API_ENDPOINTS} from "../utils/api-endpoints"
import http from "../utils/http";
import Axios from "axios";
import AuthSidePane from "../components/auth-side-pane";
import { useEffect } from "react";

function Register() {    
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('')
    const [disable_login, setLoginStatus] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [postal_code, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [tel, setTel] = useState("");
    const [referral, setReferral] = useState("");
    const [user_type, setUserType] = useState("User");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [error_message] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const getCountries = async () => {
            let cont = [];
            await Axios.get('https://restcountries.com/v3.1/all').then((res) => {
                for(let i = 0; i < res.data.length; i++) {
                    cont.push(res.data[i].name.common)
                }
                setCountries(cont);
            }).catch(err => {
                console.log(err);
            });
        }
        getCountries()
    }, [])

    const handleSubmit = async(e) => {
        setLoginStatus(true)
        e.preventDefault();
        let postData = {
            name: name,
            email: email,
            phone: tel,
            address: country + ' ' + state + ' ' + city + ' ' + postal_code,
            password: password,
            password_confirmation: confirm_password,
            referral: referral,
            user_type: user_type
        }

        await http.post(API_ENDPOINTS.REGISTER, postData)
        .then((data) => {
            if (data.data.authorisation.token) {
                localStorage.setItem("token", JSON.stringify(data.data.authorisation.token))
                if (data.data.user[0].user_type === "Author") {
                    navigate('/dashboard');
                } else if (data.data.user[0].user_type === "User") {
                    navigate('/books');
                }else if (data.data.user[0].user_type === "Teacher") {
                    navigate('/teacher-profile');
                } else if (data.data.user[0].user_type === "Publisher") {
                    navigate('/home');
                }
            }
        })

        .catch((err) => {
            setStatus(err.response.data.message)
            setLoading(false);
            setLoginStatus(false);
        })
        setEmail("");
        setTel("");
        setName("");
        setPassword("");
        setUserType("")
    };

    return (
        <div className="Flex-Display">
            <AuthSidePane />
            <div className="Register">
                <div className="login-brand">
                    <a href="/">
                        <img src="./logo.png" alt="logo" width="200" />
                    </a>
                </div>

                <div className="card card-primary">
                    <div className="card-header">
                        <h4>Register</h4>
                    </div>
                    <div style={{color: 'red', fontWeight: 'bold', textAlign: 'center'}}>{status}</div>
                    <div className="card-body">
                        <span><small><b>{error_message}</b></small></span>
                        <form method="POST" onSubmit={handleSubmit}>                            
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input id="name" value={name} name="name" onChange={(e) => setName(e.target.value)} type="text" className="form-control" autoFocus />
                            </div> 
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="email">Email</label>
                                    <input id="email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" name="email" />
                                    <div className="invalid-feedback">
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="phone">Phone</label>
                                    <input id="tel" value={tel} name="tel" onChange={(e) => setTel(e.target.value)} type="text" className="form-control" />
                                    <div className="invalid-feedback">
                                    </div>
                                </div>
                            </div>                           
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="password" className="d-block">Password</label>
                                    <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)}  className="form-control pwstrength" data-indicator="pwindicator" name="password" />
                                    <div id="pwindicator" className="pwindicator">
                                        <div className="bar"></div>
                                        <div className="label"></div>
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="password2" className="d-block">Password Confirmation</label>
                                    <input id="password2" value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} type="password" className="form-control" name="confirm_password" />
                                </div>
                            </div>
                            <div className="form-divider">Your Home</div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Country</label>
                                    <select className="form-control" style={{height: '40px'}} value={country} onChange={(e) => setCountry(e.target.value)} name="country">
                                        {!countries ? (<option>Select Countries</option>) : (countries.sort().map((count, idx) => (<option key={idx} value={count}>{count}</option>)))}
                                    </select>
                                </div>
                                <div className="form-group col-6">
                                    <label>State/Province</label>
                                    <input type="text" value={state} name="state" onChange={(e) => setState(e.target.value)}  className="form-control" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>City</label>
                                    <input type="text" value={city} onChange={(e) => setCity(e.target.value)}  className="form-control" required/>
                                </div>
                                <div className="form-group col-6">
                                    <label>Postal Code</label>
                                    <input type="text" value={postal_code} onChange={(e) => setPostalCode(e.target.value)}  className="form-control" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Referral Code</label>
                                    <input type="text" value={referral} onChange={(e) => setReferral(e.target.value)}  className="form-control" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Register as</label>
                                    <select className="form-control" style={{height: '40px'}} onChange={(e) => {setUserType(e.target.value); console.log(e.target.value)}} name="user_type">
                                        <option value="">Select User Type</option>
                                        <option value={'Publisher'}>Aggregator</option>
                                        <option value={'Author'}>Creator</option>
                                        <option value={'Teacher'}>Teacher</option>
                                        <option value={'User'}>User</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" name="agree" className="custom-control-input" id="agree" />
                                    <label className="custom-control-label" htmlFor="agree">I agree with the terms and conditions</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" onClick={() => setLoading(true)} className="btn btn-primary btn-lg btn-block" tabIndex="4">
                                    {disable_login ? <ClipLoader loading={loading} size={20} /> : 'Register'}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div style={{fontSize: '16px'}}>
                        Already have an account?{" "}
                        <Link to={"/login"}>
                            Login
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register;
