import {
  Avatar,
  Box,
  Card,
  Typography,
  Grid,
  Button,
  Sheet,
  Stack,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import http from "../utils/http";

function Dashboard() {
  const [articles, setArticles] = useState([]);
  const [books, setBooks] = useState([]);
  const [videos, setVideos] = useState([]);
  const [audios, setAudios] = useState([]);
  const [profile, setProfile] = useState();

  useEffect(() => {
    const getArticles = async () => {
      let cont = [];
      const data = await http.get("article");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      setArticles(cont);
    };
    getArticles();
  }, []);

  useEffect(() => {
    const getProfile = async () => {
      await http
        .get("profile")
        .then((data) => {
          setProfile(data.data.data[0]);
          // console.log(data)
        })
        .catch((err) => {
          // console.log(err);
        });
    };
    getProfile();
  }, []);

  useEffect(() => {
    const getProfile = async () => {
      await http
        .get("profile")
        .then((data) => {
          setProfile(data.data.data[0]);
          // console.log(data)
        })
        .catch((err) => {
          // console.log(err);
        });
    };
    getProfile();
  }, []);

  useEffect(() => {
    const getBooks = async () => {
      let cont = [];
      const { data } = await http.get("book");
      for (let i = 0; i < data.data.length; i++) {
        cont.push(data.data[i]);
      }
      setBooks(cont);
    };
    getBooks();
  }, []);

  useEffect(() => {
    const getAudios = async () => {
      let cont = [];
      const { data } = await http.get("audio");
      for (let i = 0; i < data.data.length; i++) {
        cont.push(data.data[i]);
      }
      setAudios(cont);
    };
    getAudios();
  }, []);

  useEffect(() => {
    const getVideos = async () => {
      let cont = [];
      const { data } = await http.get("video");
      for (let i = 0; i < data.data.length; i++) {
        cont.push(data.data[i]);
      }
      setVideos(cont);
    };
    getVideos();
  }, []);

  function labelDisplayedRows({ from, to, count }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  return (
    <>
      <Stack spacing={4} paddingY={2}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"}>
            <Box>
              <Avatar sx={{ p: 3, mr: 3 }} />
            </Box>
            <Box>
              <Typography level="h1" fontSize="xl4">
                Welcome Back, {!profile ? "" : profile.name}!
              </Typography>
              <Typography level="body1">
                This dashboard is a place to manage books, articles and more.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: 1, "& > *": { flexGrow: 1 } }}>
              {/* <Button
                variant="outlined"
                color="neutral"
                startDecorator={<i data-feather="download-cloud" />}
              >
                Download PDF
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                startDecorator={<i data-feather="table" />}
              >
                Download CSV
              </Button> */}
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={3} sx={{ flexGrow: 1 }}>
            <Grid lg={3} md={3} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    Wallet Balance
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    {!profile ? "" : profile.wallet.balance}
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
            <Grid lg={3} md={3} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    All Books
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    {books.length}
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
            <Grid lg={3} md={3} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    All Articles
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    {articles.length}
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
            <Grid lg={3} md={3} sm={12}>
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  p: 4,
                  borderRadius: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography level="h1" fontSize={"15px"}>
                    All Medias
                  </Typography>
                  <Typography level="h2" fontSize="30px" sx={{ mb: 0.5 }}>
                    {videos.length + audios.length}
                  </Typography>
                </Box>
              </Sheet>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <div className="main-content" style={{ padding: "0px 0px" }}>
        <section className="section">
          {/* <div className="section-header">
                        <h1>Dashboard</h1>
                    </div> */}
          {/* <div className="row">
                        <div class="col-12 mb-4">
                            <div class="hero text-white" style={{backgroundColor: '#12a89d'}}>
                                <div class="hero-inner">
                                    <h2>Welcome Back, {!profile? '' : profile.name}!</h2>
                                    <p class="lead">This dashboard is a place to manage books, articles and more.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}

          {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="card" style={{margin: '20px 10px'}}>
                                <div className="card-header">
                                    <h4>Statistics</h4>
                                    <div className="card-header-action">
                                        <button className="btn btn-sm btn-outline-secondary mr-1" id="one_month">1M</button>
                                        <button className="btn btn-sm btn-outline-secondary mr-1" id="six_months">6M</button>
                                        <button className="btn btn-sm btn-outline-secondary mr-1 active" id="one_year">1Y</button>
                                        <button className="btn btn-sm btn-outline-secondary mr-1" id="ytd">YTD</button>
                                        <button className="btn btn-sm btn-outline-secondary" id="all">ALL</button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="statistic-details mb-sm-4">
                                        <div className="statistic-details-item">
                                            <span className="text-muted"><span className="text-primary"><i className="fas fa-caret-up"></i></span> 7%</span>
                                            <div className="detail-value">$243</div>
                                            <div className="detail-name">Today's Sales</div>
                                        </div>
                                        <div className="statistic-details-item">
                                            <span className="text-muted"><span className="text-danger"><i className="fas fa-caret-down"></i></span> 23%</span>
                                            <div className="detail-value">$2,902</div>
                                            <div className="detail-name">This Week's Sales</div>
                                        </div>
                                        <div className="statistic-details-item">
                                            <span className="text-muted"><span className="text-primary"><i className="fas fa-caret-up"></i></span>9%</span>
                                            <div className="detail-value">$12,821</div>
                                            <div className="detail-name">This Month's Sales</div>
                                        </div>
                                        <div className="statistic-details-item">
                                            <span className="text-muted"><span className="text-primary"><i className="fas fa-caret-up"></i></span> 19%</span>
                                            <div className="detail-value">$92,142</div>
                                            <div className="detail-name">This Year's Sales</div>
                                        </div>
                                    </div>
                                    <div id="apex-timeline-chart"></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </section>
      </div>
    </>
  );
}

export default Dashboard;
