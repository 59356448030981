import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import http from "../../utils/http";

const EventTable = () => {
	const [selectedRows, setSelectedRows] = useState([]);

    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getEvents = async () => {
            let cont = []
            const {data} = await http.get('events');
            for(let i = 0; i < data.data.length; i++) {
                cont.push(data.data[i])
            }
            setEvents(cont);
        }
        getEvents();
    }, []); 

    console.log(events)

	useEffect(() => {		
		console.log('state', selectedRows);
	}, [selectedRows]);

	const viewEvent = (e, id) => {
		e.preventDefault();
        navigate(`/author/view-event/${id}`)  
	};

    const editEvent = (e, id) => {
		e.preventDefault();
        navigate(`/author/edit-event/${id}`)
	};

    const deleteEvent = async (e, id) => {
		e.preventDefault();
        const {data} = await http.delete(`event/${id}`);
        console.log(data)
        toast.success(data.data);
	};

	const handleChange = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const columns = useMemo(
		() => [
            {
                name: 'Name',
				selector: row => row.title,
				sortable: true,
				grow: 2,
                
			},
			{
                name: 'Date',
				selector: row => row.created_at,
				sortable: true,
			},
            {
                
                cell: (row) => <button onClick={(e) => viewEvent(e, row.id)}><i class="fa fa-eye"></i></button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                
                cell: (row) => <button onClick={(e) => editEvent(e, row.id)}><i class="fa fa-pen"></i></button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                
                cell: (row) => <button onClick={(e) => deleteEvent(e, row.id)}><i class="fa fa-trash"></i></button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
			
		],
		[],
	);

	return (
		<DataTable
			title="Events"
			data={events}
			columns={columns}
			selectableRows
            pagination
			onSelectedRowsChange={handleChange}
		/>
	);
}

const AuthorEvents = () => {
    return (
        <>
            <div className="main-content" style={{padding: '40px 0px'}}>
                <section className="section">
                    <div className="section-header">
                        <h1>My Events</h1>
                        <div className="section-header-breadcrumb">
                            <div className="breadcrumb-item active"><Link to="/author-dashboard">Dashboard</Link></div>
                            <div className="breadcrumb-item">Events</div>
                        </div>
                    </div>
                    <EventTable />
                </section>
            </div>
            <ToastContainer />
        </>
    )
}

export default AuthorEvents;