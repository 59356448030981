import React, { useEffect, useState } from "react";
import { Comment } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useCart } from "react-use-cart";
import { RWebShare } from "react-web-share";
import http from "../utils/http";

const Book = () => {
  const [book, setBook] = useState({});
  const [books, setBooks] = useState({});
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const { addItem } = useCart();
  let param = useParams();
  useEffect(() => {
    const getBook = async () => {
      await http
        .get("book/preview/" + param.id)
        .then((data) => {
          console.log(data.data.data[0]);
          setBook(data.data.data[0]);
          setComments(data.data.data[0].comments);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getBook();
  }, []);

  useEffect(() => {
    const getBooks = async () => {
      let cont = [];
      const data = await http.get("books/all");
      for (let i = 0; i < 3; i++) {
        cont.push(data.data.data[i]);
      }
      setBooks(cont);
    };
    getBooks();
  }, []);

  const postComment = async (e) => {
    e.preventDefault();
    let postData = {
      comments: comment,
    };

    const { data } = await http.post(`book/${param.id}/comment`, postData);
    if (data.data) {
      toast.success(data.data);
      setComment("");
    } else toast.success("something went wrong");
  };

  const addToCarts = (e, book) => {
    e.preventDefault();
    const result = addItem(book);
    toast.info(result);
  };

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>Book Details</h2>
            <span className="underline center"></span>
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/books">Books</Link>
              </li>
              <li>Book details</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="booksmedia-detail-main">
              <div className="container">
                <section>
                  <div className="booksmedia-detail-box">
                    <div className="detailed-box">
                      <div className="col-xs-12 col-sm-5 col-md-3">
                        <div className="post-thumbnail">
                          {/* <div className="book-list-icon yellow-icon"></div> */}
                          <img src={book.bookCoverUrl} alt="Book" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-7 col-md-6">
                        <div className="post-center-content">
                          <h2>{book.title}</h2>
                          <p>
                            <strong>Author:</strong>
                            {book.author_name ? book.author_name : book.author}
                          </p>
                          <p>
                            <strong>ISBN:</strong> {book.isbn}
                          </p>
                          <p>
                            <strong>Soft Copy Cost:</strong> From ₦
                            {book.softCopyAmount}
                          </p>
                          <p>
                            <strong>One-off Purchase:</strong> From ₦
                            {book.oneTimePayment}
                          </p>
                          <p>
                            <strong>Hard Copy Cost:</strong> ₦
                            {book.hardCopyAmount}
                          </p>
                          <p>
                            <strong>Rating:</strong>{" "}
                          </p>
                          <p>
                            <strong>Edition:</strong> First edition
                          </p>
                          {book.author_name ? (
                            <p>
                              <strong>Publisher:</strong>
                              {book.author}
                            </p>
                          ) : (
                            ""
                          )}
                          <p>
                            <strong>Format:</strong> PDF
                          </p>
                          <p>
                            <strong>Tags :</strong>
                            {book.tags ? book.tags((tag) => tag.name) : ""}
                          </p>
                          <p>
                            <strong>Categories:</strong>
                            {book.categories
                              ? book.categories((cat) => cat.name)
                              : ""}
                          </p>
                          <div className="actions">
                            <ul>
                              <li>
                                <Link
                                  onClick={(e) =>
                                    addToCarts(e, {
                                      ...book,
                                      price: book.softCopyAmount,
                                      type: "book",
                                      objectType: "book",
                                      bookType: "soft",
                                      id: `${book.id} soft`,
                                    })
                                  }
                                  target="_blank"
                                  data-toggle="blog-tags"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Add To Cart"
                                >
                                  <i className="fa fa-shopping-cart"></i>
                                </Link>
                              </li>
                              <li>
                                <RWebShare
                                  data={{
                                    text: book.title,
                                    url: "https://kokawe.com/book/" + book.id,
                                    title: book.title,
                                  }}
                                  onClick={() =>
                                    toast.success("shared successfully!")
                                  }
                                >
                                  <i className="fa fa-share-alt"></i>
                                </RWebShare>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-3 ">
                        <div className="post-right-content">
                          <h4>Available now</h4>
                          <Link
                            onClick={(e) =>
                              addToCarts(e, {
                                ...book,
                                price: book.softCopyAmount,
                                type: "book",
                                objectType: "book",
                                bookType: "soft",
                                id: `${book.id} soft`,
                              })
                            }
                            className="btn btn-dark-gray"
                          >
                            Read Online
                          </Link>
                          <Link
                            onClick={(e) =>
                              addToCarts(e, {
                                ...book,
                                price: book.hardCopyAmount,
                                type: "book",
                                objectType: "book",
                                bookType: "hard",
                                id: `${book.id} hard`,
                              })
                            }
                            className="btn btn-dark-gray"
                          >
                            Buy Hard Copy
                          </Link>
                          <Link to="#." className="btn btn-dark-gray">
                            Consult Author
                          </Link>
                          <Link to="/cart" className="btn btn-dark-gray">
                            Quick Checkout
                          </Link>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>
                    <p>
                      <strong>Summary:</strong>
                      {book.description}{" "}
                    </p>
                  </div>
                  <div className="comments-area" id="comments">
                    <div className="comment-bg">
                      <h4 className="comments-title">Comments</h4>
                      <span className="underline left"></span>
                      <ol className="comment-list">
                        <li className="comment even thread-even depth-1 parent">
                          {!comments ? (
                            <div className="comment-body">
                              <Comment
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="comment-loading"
                                wrapperStyle={{}}
                                wrapperClass="comment-wrapper"
                                color="#fff"
                                backgroundColor="#F4442E"
                              />
                              <p>No comments available</p>
                            </div>
                          ) : (
                            comments.map((_comment) => (
                              <div className="comment-body">
                                <div className="comment-author vcard">
                                  <img
                                    className="avatar avatar-32 photo avatar-default"
                                    src="images/blog/mathew.jpg"
                                    alt="Comment Author"
                                  />
                                  <b className="fn">
                                    <Link
                                      className="url"
                                      rel="external nofollow"
                                      to="#"
                                    >
                                      {_comment.comment_by}
                                    </Link>
                                  </b>
                                </div>
                                <footer className="comment-meta">
                                  <div className="left-arrow"></div>
                                  <div className="comment-metadata">
                                    <Link to="#">
                                      <time dateTime="2016-01-17">
                                        {_comment.created_at}
                                      </time>
                                    </Link>
                                  </div>
                                  <div className="comment-content">
                                    <p>{_comment.comments}</p>
                                  </div>
                                </footer>
                              </div>
                            ))
                          )}
                        </li>
                      </ol>
                    </div>
                    <div className="comment-respond" id="respond">
                      <h4 className="comment-reply-title" id="reply-title">
                        Write your comment:
                      </h4>
                      <span className="underline left"></span>
                      <form
                        className="comment-form"
                        id="commentform"
                        method="post"
                      >
                        <div className="row">
                          <p className="comment-form-comment">
                            <textarea
                              name="comment"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              id="comment"
                              placeholder=""
                            ></textarea>
                          </p>
                        </div>
                        <div className="clearfix"></div>
                        <p className="form-submit">
                          <input
                            value="Submit"
                            className="submit"
                            onClick={postComment}
                            id="submit"
                            name="submit"
                            type="submit"
                          />
                        </p>
                      </form>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>

      <div className="booksmedia-fullwidth">
        <div className="container">
          <h2 className="section-title text-center">Popular Books</h2>
          <span className="underline center"></span>
          {/* <p className="lead text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          <ul className="popular-items-detail-v2">
            {books.length > 0
              ? books.map((_book, idx) => (
                  <li key={idx}>
                    {/* <div className="book-list-icon yellow-icon"></div> */}
                    <Link to={"/book/" + _book.id}>
                      <figure>
                        <img src={_book.bookCoverUrl} alt="Book" />
                        <figcaption>
                          <header>
                            <h4>
                              <Link to={"/book/" + _book.id}>
                                {_book.title}
                              </Link>
                            </h4>
                            <p>
                              <strong>Author:</strong>
                              {_book.author}
                            </p>
                            <p>
                              <strong>ISBN:</strong>
                              {_book.isbn}
                            </p>
                            <p>
                              <strong>Cost:</strong> From ₦
                              {_book.softCopyAmount}
                            </p>
                          </header>
                          <p></p>
                          <div className="actions">
                            <ul>
                              <li>
                                <Link
                                  to="#"
                                  target="_blank"
                                  data-toggle="blog-tags"
                                  data-placement="top"
                                  title="Add To Cart"
                                >
                                  <i className="fa fa-shopping-cart"></i>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  target="_blank"
                                  data-toggle="blog-tags"
                                  data-placement="top"
                                  title="Like"
                                >
                                  <i className="fa fa-heart"></i>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  target="_blank"
                                  data-toggle="blog-tags"
                                  data-placement="top"
                                  title="Mail"
                                >
                                  <i className="fa fa-envelope"></i>
                                </Link>
                              </li>
                              {/* <li>
                                                            <Link to="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Search">
                                                                <i className="fa fa-search"></i>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Print">
                                                                <i className="fa fa-print"></i>
                                                            </Link>
                                                        </li> */}
                              <li>
                                <RWebShare
                                  data={{
                                    text: _book.title,
                                    url: "https://kokawe.com/book/" + _book.id,
                                    title: _book.title,
                                  }}
                                  onClick={() =>
                                    toast.success("shared successfully!")
                                  }
                                >
                                  <i className="fa fa-share-alt"></i>
                                </RWebShare>
                              </li>
                            </ul>
                          </div>
                        </figcaption>
                      </figure>
                    </Link>
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Book;
