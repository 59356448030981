import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ShoppingCart, ShareOutlined } from "@mui/icons-material";
import styled from "styled-components";
import http from "../utils/http";
import background from "../assets/course_background.jpeg";
import { useCart } from "react-use-cart";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import { shuffle } from "lodash";
import { RWebShare } from "react-web-share";

function CourseItems({ courses, title }) {
  const [searchedCourses, setSearchedCourses] = useState([]);
  const { addItem } = useCart();
  const navigate = useNavigate();
  const addToCarts = (e, course) => {
    e.preventDefault();
    addItem(course);
    toast.info("Course added to cart");
  };

  function navigator(e, id) {
    e.preventDefault();
    navigate(`/course/${id}`);
  }

  const searchCourses = () => {
    // e.preventDefault();
    let newCourses = [];
    courses.find((course) => {
      let result = course.includes(title);
      newCourses.push(result);
    });
    setSearchedCourses(newCourses);
  };

  function setPrice(price) {
    let totalPrice = 0;
    if (price > 0) {
      totalPrice = Number(price) * 930;
    }
    return totalPrice;
  }

  return (
    <div className="booksmedia-fullwidth">
      <ul style={{ margin: "40px 0px" }}>
        {!courses
          ? ""
          : courses.map((course, idx) => (
              <li key={idx}>
                <Card sx={{ maxWidth: 400 }}>
                  <Link to={`/course/${course.id}`}>
                    <Box
                      height="194"
                      sx={{
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        minHeight: "350px",
                        textAlign: "center",
                        padding: 5,
                      }}
                    >
                      <Typography
                        variant="h5"
                        marginTop={10}
                        color="white"
                        sx={{ fontWeight: "700" }}
                      >
                        {course.name}
                      </Typography>
                    </Box>
                  </Link>
                  <CardContent>
                    <Link to={`/course/${course.id}`}>
                      <Typography variant="h4" color="black">
                        {course.name}
                      </Typography>
                    </Link>
                    <Typography
                      variant="subtitle1"
                      sx={{ paddingY: "10px" }}
                      color="text.secondary"
                    >
                      {course.description.substring(0, 100) + "..."}
                    </Typography>
                  </CardContent>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="h5"
                      sx={{ padding: "10px", fontWeight: "600" }}
                    >
                      ₦{setPrice(course ? course.rrp : 0)}
                    </Typography>
                    <CardActions disableSpacing>
                      <IconButton aria-label="share">
                        <RWebShare
                          data={{
                            text: course.name,
                            url: "https://kokawe.com/course/" + course.id,
                            title: course.name,
                          }}
                          onClick={() => toast.success("shared!")}
                        >
                          <ShareOutlined />
                        </RWebShare>
                      </IconButton>
                      <IconButton
                        onClick={(e) =>
                          addToCarts(e, {
                            ...course,
                            price: setPrice(course ? course.rrp : 0),
                            author: "kokawe",
                            objectType: "course",
                            type: "course",
                          })
                        }
                        aria-label="add to cart"
                      >
                        <ShoppingCart />
                      </IconButton>
                    </CardActions>
                  </Box>
                </Card>
              </li>
            ))}
      </ul>
    </div>
  );
}

function Courses() {
  const itemsPerPage = 12;
  const [title, setTitle] = useState("");
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const getCourses = async () => {
      const courses = await http.get("courses");
      let shuffledData = shuffle(courses.data);
      setCourses(shuffledData);
    };

    getCourses();
  }, []);

  const MyPaginate = styled(ReactPaginate).attrs({
    // You can redefine classes here, if you want.
    activeClassName: "active", // default to "selected"
  })`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 5rem;
    li a {
      border-radius: 7px;
      padding: 0.1rem 1rem;
      border: gray 1px solid;
      cursor: pointer;
    }
    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
    }
    li.active a {
      background-color: #0366d6;
      border-color: transparent;
      color: white;
      min-width: 32px;
    }
    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  `;

  const [itemOffset, setItemOffset] = useState(0);
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = courses.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(courses.length / itemsPerPage);

  const searchCourses = (e) => {
    e.preventDefault();
    let newCourses = [];
    courses.find((course) => {
      if (course.name.includes(title)) {
        newCourses.push(course);
      }
    });
    setCourses(newCourses);
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % courses.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>Courses</h2>
            <span className="underline center"></span>
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>Courses</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="courses-full-width">
              <div className="container">
                <section className="search-filters mx-auto">
                  <div className="container">
                    <div className="filter-box">
                      <h3>What are you looking for at the library?</h3>
                      <form
                        style={{ display: "flex", justifyContent: "center" }}
                        onSubmit={(e) => searchCourses(e)}
                      >
                        {/* <div className="col-md-4"> */}
                        <div className="form-group">
                          <label className="sr-only" htmlFor="keywords">
                            Search by Keyword
                          </label>
                          <input
                            className="form-control"
                            style={{
                              margin: "auto",
                              maxWidth: "400px",
                              backgroundColor: "white",
                              color: "grey",
                              border: "none",
                              height: "35px",
                            }}
                            placeholder="Search by Keyword"
                            id="keywords"
                            type="text"
                            value={title}
                            name="title"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        {/* </div> */}
                        {/* <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <Select options={tagOptions} name="tag" />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <Select options={categoryOptions} name="category" />
                          </div>
                        </div> */}
                        <div className="form-group">
                          <input
                            className="form-control"
                            style={{ height: "35px", marginLeft: "20px" }}
                            type="submit"
                            value="Search"
                          />
                        </div>
                        {/* <div className="col-md-2 col-sm-6">
                        </div> */}
                      </form>
                    </div>
                  </div>
                </section>
                <CourseItems courses={currentItems} />
                <nav
                  aria-label="Page navigation comments"
                  className="my-2 mx-auto"
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    marginBottom: "30px",
                    marginRight: "auto",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination mx-auto justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    // eslint-disable-next-line no-unused-vars
                    hrefBuilder={(page, pageCount, selected) =>
                      page >= 1 && page <= pageCount ? `/page/${page}` : "#"
                    }
                    hrefAllControls
                    // forcePage={currentPage}
                  />
                </nav>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Courses;
