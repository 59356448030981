export const API_ENDPOINTS = {
    LOGIN: '/login',
    REGISTER: '/register',
    LOGOUT: '/logout',
    FORGET_PASSWORD: '/forget-password',
    ARTICLE: '/article',
    ARTICLES: '/articles',
    BOOKS:  'books/all',
    BOOK: '/book',
    TAGS: '/tags',
    TAG: '/tag',
    TEACHER: '/teacher',
    APPOINTMENT: '/appointment',
    CATEGORY: '/category',
    CATEGORIES: '/categories',
    ROLE: '/role',
    ROLES: '/roles'
}