import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast, ToastContainer } from "react-toastify";

function PublisherDashboard() {
    
    const [profile, setProfile] = useState()
    const [status, setStatus] = useState(false);

    useEffect(() => {
        const getProfile = async () => {
            await http.get('profile')
            .then((data) => {
                setProfile(data.data.data[0])
            }).catch((err) => {
            })            
        }
        getProfile();
    }, [])

    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>Dashboard</h2>
                        <span className="underline center"></span>
                        {/* <p className="lead">Rando text here</p> */}
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>Dashboard</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div id="content" className="site-content">
                    <div id="primary" className="content-area">
                        <main id="main" className="site-main">
                            <div className="books-full-width">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div style={{backgroundColor: '#12a89d', padding: '20px'}}>
                                                <h3>Referrals</h3>
                                                <h6 style={{textAlign: 'right', padding: '20px', fontSize: '25px'}}>0</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div style={{backgroundColor: 'skyblue', padding: '20px'}}>
                                                <h3>Wallet Balance</h3>
                                                <h6 style={{textAlign: 'right', padding: '20px', fontSize: '25px'}}><span>₦{!profile ? 0 : profile.wallet.balance}</span>&nbsp;&nbsp; <button style={{backgroundColor: 'secondary'}}>Withdraw</button></h6>
                                                
                                            </div>
                                        </div>                                       
                                    </div> 
                                    <div className="row" style={{marginTop: '20px'}}>
                                        <div className="col-md-4">
                                            <div style={{backgroundColor: '#12a89d', padding: '20px'}}>
                                                <h3>My Books</h3>
                                                <h6 style={{textAlign: 'right', padding: '20px', fontSize: '25px'}}>0</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div style={{backgroundColor: 'skyblue', padding: '20px'}}>
                                                <h3>Favorite Articles</h3>
                                                <h6 style={{textAlign: 'right', padding: '20px', fontSize: '25px'}}>0</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div style={{backgroundColor: '#ff7236', padding: '20px'}}>
                                                <h3>Saved Media</h3>
                                                <h6 style={{textAlign: 'right', padding: '20px', fontSize: '25px'}}>0</h6>
                                            </div>
                                        </div>                                                                               
                                    </div>
                                    <div className="row" style={{marginTop: '20px'}}>
                                        <div className="col-md-12">
                                            <div style={{backgroundColor: '#ff7236', padding: '20px'}}>
                                                <h3>Referral Link</h3>
                                                <h6 style={{textAlign: 'right', padding: '20px', fontSize: '15px'}}>
                                                <span>kokawe.com/register?refered_by=${!profile ? '' : profile.referral_code} </span> &nbsp;&nbsp;
                                                    <CopyToClipboard text={`kokawe.com/register?refered_by=${!profile ? '' : profile.referral_code}`}
                                                        onCopy={() => toast.success('copied')}>
                                                        <button>Copy</button>
                                                    </CopyToClipboard>
                                                </h6>
                                            </div>
                                        </div>     
                                    </div>                            
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
};

export default PublisherDashboard;