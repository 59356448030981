import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useCart } from "react-use-cart";
import { RWebShare } from "react-web-share";
import { addToCart } from "../utils/cart";
import { decrypt } from "../utils/encryptor";
import http from "../utils/http";


const Media = () => {
    const { addItem } = useCart();
    const [media, setMedia] = useState({});
    const [mediaType, setMediaType] = useState('');
    let param = useParams();
    useEffect(() => {
        const getMedia = async () => {
            if (param.type === 'audio') {
                await http.get('audio/preview/' + param.id)
                .then((data) => {
                    setMedia(data.data.data[0])
                })
                .catch((err) => {
                    console.log(err)
                })
            } else {
                await http.get('video/preview/' + param.id)
                .then((data) => {
                    setMedia(data.data.data[0])
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
        getMedia();
    }, []);

    const addToCarts = (e, media) => {
        e.preventDefault();
        addItem(media);
        toast.info('Media added to cart');
    }

    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>Media Details</h2>
                        <span className="underline center"></span>
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li><Link to="/medias">Media</Link></li>
                            <li>Media details</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <div className="booksmedia-detail-main">
                            <div className="container">
                                <section>
                                    <div className="booksmedia-detail-box">
                                        <div className="detailed-box">
                                            <div className="col-xs-12 col-sm-5 col-md-3">
                                                <div className="post-thumbnail">
                                                    {
                                                        mediaType === 'mp4' ? 
                                                        <video width="auto" height="240" autoPlay>
                                                            <source src={media.video_cover_url} type="video/quicktime"/>
                                                            <source src={media.video_cover_url} type="video/mp4"/>
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        :                                              
                                                        <img src={media.audio_cover_url ?? media.video_cover_url} alt={media.title} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-7 col-md-4">
                                                <div className="post-center-content">
                                                    <h2>{media.title}</h2>
                                                    <p><strong>Uploaded By:</strong> {media.owner}</p>
                                                    <p><strong>Cost:</strong> {media.amount}</p>
                                                    <p><strong>One-off purchase:</strong> {media.oneTimePayment}</p>
                                                    <p><strong>Tags:</strong> {media.tags ? media.tags.map((tag) => tag.name) : ''}</p>
                                                    <p><strong>Categories:</strong> {media.categories ? media.categories.map((cat) => cat.name) : ''}</p>
                                                    <div className="actions">
                                                        <ul>
                                                            <li>
                                                            {
                                                                media.stream_type === "free" ? 
                                                                <Link style={{cursor: 'not-allowed'}} data-original-title="Add To Cart">
                                                                    <i className="fa fa-shopping-cart"></i>
                                                                </Link> :                                                                
                                                                <Link onClick={(e) => addToCarts(e, {...media, price: parseInt(media.amount), type: 'media', objectType: media.video_cover_url ? 'video' : 'audio'})}  data-toggle="blog-tags" data-placement="top" title="" data-original-title="Add To Cart">
                                                                    <i className="fa fa-shopping-cart"></i>
                                                                </Link>
                                                            }
                                                            </li>
                                                            <li>
                                                                <Link to="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="" data-original-title="Like">
                                                                    <i className="fa fa-heart"></i>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <RWebShare
                                                                    data={{
                                                                        text: media.title,
                                                                        url: `https://kokawe.com/media/${media.audio_cover_url ? 'audio' : 'video'}/${media.id}`,
                                                                        title: media.title
                                                                    }}
                                                                    onClick={() => toast.success("shared successfully!")}
                                                                >
                                                                    <i className="fa fa-share-alt"></i>
                                                                </RWebShare>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-4">
                                                <div className="post-right-content">
                                                    {
                                                        <>
                                                            <h4>Available now</h4>
                                                            { media.stream_type === 'free' ? 
                                                                <Link disabled className="btn btn-dark-gray">Add to Cart</Link> :
                                                                <Link onClick={(e) => addToCarts(e, {...media, price: parseInt(media.amount), type: 'media', objectType: media.video_cover_url ? 'video' : 'audio'})} className="btn btn-dark-gray">Add to Cart</Link>
                                                            }
                                                            
                                                            <Link to="#." className="btn btn-dark-gray">Consult Author</Link>
                                                            <Link to="/cart" className="btn btn-dark-gray">Quick Checkout</Link>
                                                        </>
                                                        
                                                    }
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </section>

                                <h2 className="section-title text-center py-3">Media Content</h2>
                                <section>
                                    <div className="container">
                                        <div className="row">
                                            {
                                                media && media.video_url ? media.video_url.split(',').map((_media, idx) =>                                        
                                                <div key={idx + 1} className="col-xs-12 col-sm-6 col-md-4">
                                                    <div style={{padding: '20px 20px', margin: '20px 20px'}}>
                                                        <video width={400} height={300} controls style={{padding: '20px 20px'}}>
                                                            <source src={JSON.parse(decrypt(_media)).text} type="video/mp4"/>
                                                            <source src={JSON.parse(decrypt(_media)).text} type="video/quicktime"/>
                                                        </video>
                                                        <h4 style={{padding: '20px 20px'}}>{media.title} {idx + 1}</h4>
                                                    </div>
                                                </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Media;