import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import http from "../../utils/http";

const AdminTeachers = () => {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const {data} = await http.get("admin/teachers");
    setUsers(data.data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const deleteTeacher = async (e, id) => {
    e.preventDefault();
    await http
      .delete("admin/teachers/" + id)
      .then((data) => {
        toast.success(data.data);
        getUsers();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const approveTeacher = async (e, id) => {
    e.preventDefault();
    await http
      .patch("admin/teachers/" + id, {status: 'approved'})
      .then(({data}) => {
        console.log(data);
        toast.success(data.data);
        getUsers();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
  };

  const suspendTeacher = async (e, id) => {
    e.preventDefault();
    await http
      .patch("admin/teachers/" + id, {status: 'suspended'})
      .then(({data}) => {
        toast.success(data.data);
        getUsers();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography
          level="h1"
          textAlign="left"
          sx={{ pb: 5, pt: 3, fontFamily: "montserrat" }}
        >
          Teachers
        </Typography>
        <Sheet
          variant="outlined"
          sx={{
            "--TableCell-height": "40px",
            // the number is the amount of the header rows.
            "--TableHeader-height": "calc(1 * var(--TableCell-height))",
            "--Table-firstColumnWidth": "30px",
            "--Table-secondColumnWidth": "100px",
            "--Table-lastColumnWidth": "100px",
            // background needs to have transparency to show the scrolling shadows
            "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
            "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
            overflow: "auto",
            fontSize: '20px',
            background: (theme) =>
              `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 0 50%,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 100% 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
            backgroundSize:
              "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local, local, scroll, scroll",
            backgroundPosition:
              "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
            backgroundColor: "background.surface",
          }}
        >
          <Table
            borderAxis="bothBetween"
            stripe="odd"
            hoverRow
            sx={{
              "& tr > *:first-child": {
                position: "sticky",
                left: 0,
                boxShadow: "1px 0 var(--TableCell-borderColor)",
                bgcolor: "background.surface",
              },
              "& tr > *:last-child": {
                position: "sticky",
                right: 0,
                bgcolor: "var(--TableCell-headBackground)",
              },
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "var(--Table-firstColumnWidth)" }}>Row</th>
                <th style={{ width: "var(--Table-secondColumnWidth)" }}>Names</th>
                {/* <th>Highest Qualification</th> */}
                <th style={{ width: "var(--Table-firstColumnWidth)" }}>Amount</th>
                <th style={{ width: 100 }}>Certificate</th>
                <th style={{ width: 100 }}>Subjects</th>
                <th style={{ width: "var(--Table-firstColumnWidth)" }}>Status</th>
                <th
                  aria-label="last"
                  style={{ width: "var(--Table-lastColumnWidth)" }}
                />
              </tr>
            </thead>
            <tbody>
              {users.map((row, idx) => (
                <tr key={idx + 1}>
                  <td>{idx + 1}</td>
                  <td>{row.name}</td>
                  {/* <td>{row.highest_qualification}</td> */}
                  <td>{row.amount}</td>
                  <td>{row.certificate}</td>
                  <td>{row.subjects}</td>
                  {/* <td>{row.certificate_url}</td> */}
                  <td>{row.status}</td>
                  <td>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      {/* <Button size="sm" variant="plain" color="neutral">
                      Edit
                    </Button> */}
                      <Button
                        onClick={(e) => approveTeacher(e, row.id)}
                        size="sm"
                        variant="soft"
                        color="success"
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={(e) => suspendTeacher(e, row.id)}
                        size="sm"
                        variant="soft"
                        color="warning"
                      >
                        Suspend
                      </Button>
                      <Button
                        onClick={(e) => deleteTeacher(e, row.id)}
                        size="sm"
                        variant="soft"
                        color="danger"
                      >
                        Delete
                      </Button>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      </Box>
      <ToastContainer />
    </>
  );
};

export default AdminTeachers;
