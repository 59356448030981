import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UploadIcon from '@mui/icons-material/Upload';
import PeopleIcon from '@mui/icons-material/People';
import Menu from '@mui/material/Menu';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CreateIcon from '@mui/icons-material/Create';
import PercentIcon from '@mui/icons-material/Percent';
import MovieIcon from '@mui/icons-material/Movie';
import TagIcon from '@mui/icons-material/Tag';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EventIcon from '@mui/icons-material/Event';
import WalletIcon from '@mui/icons-material/Wallet';
import CategoryIcon from '@mui/icons-material/Category';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentsIcon from '@mui/icons-material/Payments';
import ArticleIcon from '@mui/icons-material/Article';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { getProfile } from '../utils/get-profile';
import { useState } from 'react';
import { useEffect } from 'react';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SideDrawer() {
  const theme = useTheme();
  const [admin, setAdmin] = React.useState(false);
  const [userType, setUserType] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const getUserType = async () => {
        const admin = await getProfile()
        if (admin === "Unauthenticated.") {
            localStorage.clear('token');
            <Navigate to={'/login'} />
        } else {
            setUserType(admin.data.data[0].user_type);
        };
        if (admin.data.data[0].role[0].name) {
            setAdmin(true);
        }
    }
     getUserType();
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null);
  const opened = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ backgroundColor: '#12a89d', display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
            <Button
                sx={{float: 'right', textAlign: 'right', color: 'white', position: 'relative', fontSize: '1.3rem', px: '2.5'}}
                primaryTypographyProps={{fontSize: '1.3rem'}}
                id="basic-button"
                aria-controls={opened ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={opened? 'true' : undefined}
                onClick={handleClick}
            >
                {localStorage.getItem('name')} <ExpandMoreIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={opened}
                style={{
                  px: '2.5'
                }}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem sx={{fontSize: '1.3rem', padding: '8px 30px'}}>
                  <Link to={'/home'}>Home</Link>
                </MenuItem>
                <MenuItem sx={{fontSize: '1.3rem', padding: '8px 30px'}}>
                  <Link to={'/author-profile'}>Profile</Link>
                </MenuItem>
                <MenuItem sx={{fontSize: '1.3rem', padding: '8px 30px'}}>
                  <Link to={'/logout'}>Logout</Link>
                </MenuItem>
            </Menu>          
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
        <Link to={"/dashboard"}><img src="logo.png" width={100} alt="logo" /></Link>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {
            admin ? (
                <List sx={{fontSize: '30px'}}>
                {[{text: 'Admin Dashboard', icon: <DashboardIcon />, href: '/admin-dashboard'}, {text: 'Users', icon: <PeopleIcon />, href: '/admin-users'}, {text: 'Roles', icon: <PeopleIcon />, href: '/admin-roles'}, {text: 'Books', icon: <LibraryBooksIcon />, href: '/admin-books'}, {text: 'Categories', icon: <EventIcon />, href: '/admin-events'}, {text: 'Percentages', icon: <PercentIcon />, href: '/admin-percentages'}, {text: 'Categories', icon: <CategoryIcon />, href: '/admin-categories'}, {text: 'Tags', icon: <TagIcon />, href: '/admin-tags'},  {text: 'Medias', icon: <MovieIcon />, href: '/admin-medias'}, {text: 'Subscriptions', icon: <ReceiptLongIcon />, href: '/admin-sub-transactions'}, {text: 'Book Transactions', icon: <ReceiptLongIcon />, href: '/admin-book-transactions'}, {text: 'Withdrawals', icon: <CreditCardIcon />, href: '/admin-withdrawals'}].map((text, index) => (
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        }}
                        href={text.href}
                    >
                        <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                        >
                        {text.icon}
                        </ListItemIcon>
                        <ListItemText primary={text.text} 
                            primaryTypographyProps={{fontSize: '1.3rem'}} 
                            sx={{ 
                                opacity: open ? 1 : 0
                            }} 
                        />
                    </ListItemButton>
                    </ListItem>
                ))}
                </List> ) : ''
        }
        <Divider />
        <List>
          {[{text: 'Dashboard', icon: <DashboardIcon />, href: '/dashboard'}, {text: 'Upload Book', icon: <UploadIcon />, href: '/upload-book'}, {text: 'My Books', icon: <LibraryBooksIcon />, href: '/author-books'}, {text: 'Upload Media', icon: <UploadIcon />, href: '/upload-media'}, {text: 'My Media', icon: <MovieIcon />, href: '/author-medias'}, {text: 'Write Article', icon: <CreateIcon />, href: '/new-article'}, {text: 'Create Event', icon: <EventIcon />, href: '/create-event'}, {text: 'My Events', icon: <EventIcon />, href: '/author-events'}, {text: 'My Articles', icon: <ArticleIcon />, href: '/author-articles'},
          {text: 'Wallet', icon: <WalletIcon />, href: '/author-wallet'},
          {text: 'Subscription', icon: <PaymentsIcon />, href: '/author-subscriptions'},
          ].map((text, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block'}}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                href={text.href}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText primary={text.text} primaryTypographyProps={{fontSize: '1.3rem'}}  sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Outlet />
    </Box>
  );
}