import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getCategories, getTags } from "../utils/get-categories";
import { RWebShare } from "react-web-share";
import http from "../utils/http";
import { toast, ToastContainer } from "react-toastify";
import { getProfile } from "../utils/get-profile";
import background from "../assets/course_background.jpeg";
import Select from "react-select";
import { shuffle } from "lodash";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import { ShareOutlined, ShoppingCart } from "@mui/icons-material";
import { useCart } from "react-use-cart";

function Home() {
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [cart, setCarts] = useState();
  const [profile, setProfile] = useState();
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [tag, setTag] = useState("");
  const [books, setBooks] = useState([]);
  const [courses, setCourses] = useState([]);
  const [audios, setAudios] = useState([]);
  const [videos, setVideos] = useState([]);
  const [articles, setArticles] = useState([]);
  const { addItem } = useCart();

  const addToCarts = (e, item) => {
    e.preventDefault();
    addItem(item);
    toast.info("Item added to cart");
  };

  const searchBooks = async (e) => {
    e.preventDefault();
    let payload = {
      category: category,
      title: title,
      tag: tag,
    };
    console.log(payload);
    const { data } = await http.post("books/search", payload);
    setBooks(data);
  };

  useEffect(() => {
    const getCat = async () => {
      let cont = [];
      const { data } = await getCategories();
      for (let i = 0; i < data.length; i++) {
        cont.push(data[i]);
      }
      setCategories(cont);
    };
    getCat();
  }, []);

  useEffect(() => {
    const getTage = async () => {
      let cont = [];
      const { data } = await getTags();
      for (let i = 0; i < data.length; i++) {
        cont.push(data[i]);
      }
      setTags(cont);
    };
    getTage();
  }, []);

  useEffect(() => {
    const getBooks = async () => {
      let cont = [];
      const data = await http.get("books/all");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      const shuffledArray = shuffle(cont);
      setBooks(shuffledArray);
    };
    getBooks();
  }, []);

  // useEffect(() => {
  //   const getCourses = async () => {
  //     const { data } = await http.get("courses");
  //     const shuffledArray = shuffle(data);
  //     setCourses(shuffledArray);
  //   };
  //   getCourses();
  // }, []);

  useEffect(() => {
    const getAudios = async () => {
      let cont = [];
      const data = await http.get("audio/all");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      setAudios(cont);
    };
    getAudios();
  }, []);

  useEffect(() => {
    const Profile = async () => {
      let myProfile = await getProfile();
      if (myProfile !== "Unauthenticated.") {
        setProfile(myProfile.data.data[0]);
      }
    };
    Profile();
  }, []);

  useEffect(() => {
    const getVideos = async () => {
      let cont = [];
      const data = await http.get("video/all");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      setVideos(cont);
    };
    getVideos();
  }, []);

  useEffect(() => {
    const getArticles = async () => {
      let cont = [];
      const data = await http.get("articles/all");
      for (let i = 0; i < data.data.data.length; i++) {
        cont.push(data.data.data[i]);
      }
      setArticles(cont);
    };
    getArticles();
  }, []);

  const options = [
    { value: "articles", label: "articles" },
    { value: "books", label: "books" },
    { value: "courses", label: "courses" },
  ];

  const categoryOptions = [{ value: "", label: "select category" }];
  categories.forEach((cat) => {
    categoryOptions.push({ value: cat.id, label: cat.name });
  });

  const tagOptions = [{ value: "", label: "select tags" }];
  tags.forEach((tg) => {
    tagOptions.push({ value: tg.id, label: tg.name });
  });

  function setPrice(price) {
    let totalPrice = 0;
    if (price > 0) {
      totalPrice = Number(price) * 930;
    }
    return totalPrice;
  }

  return (
    <>
      <div
        data-ride="carousel"
        className="carousel slide"
        id="home-v1-header-carousel"
      >
        <div className="carousel-inner">
          <div className="item active">
            <figure>
              <img
                alt="Home Slide"
                src="./assets/assets2/images/header-slider/home-v2/slider1.jpg"
              />
            </figure>
            <div className="container">
              <div className="carousel-caption">
                <h2>The Content Marketplace!</h2>
                <h3>Control. Distribute. Monetize.</h3>
                {/* <p>Read the best books on Kokawe</p> */}
                <div className="slide-buttons">
                  <Link
                    to={profile ? "/dashboard" : "/register"}
                    className="btn btn-primary"
                    style={{
                      fontSize: "10px",
                      margin: "10px",
                      padding: "10px 8px",
                    }}
                  >
                    Create Content
                  </Link>
                  <Link
                    to={profile ? "/books" : "/register"}
                    className="btn btn-default"
                    style={{
                      fontSize: "10px",
                      margin: "10px",
                      padding: "10px 8px",
                    }}
                  >
                    View Contents
                  </Link>
                  <Link
                    to={profile ? "/create-event" : "/register"}
                    className="btn btn-primary"
                    style={{
                      fontSize: "10px",
                      margin: "10px",
                      padding: "10px 8px",
                    }}
                  >
                    Create Event
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <figure>
              <img
                alt="Home Slide"
                src="./assets/assets2/images/header-slider/home-v2/slider2.jpg"
              />
            </figure>
            <div className="container">
              <div className="carousel-caption">
                <h2>The Content Marketplace!</h2>
                <h3>Control. Distribute. Monetize.</h3>
                {/* <p>Read thrilling articles on Kokawe for free</p> */}
                <div className="slide-buttons">
                  <Link
                    to={profile ? "/new-article" : "/register"}
                    className="btn btn-primary"
                    style={{ fontSize: "10px", margin: "10px" }}
                  >
                    Write Article
                  </Link>
                  <Link
                    to={"/articles"}
                    className="btn btn-default"
                    style={{ fontSize: "10px" }}
                  >
                    Read Articles
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <figure>
              <img
                alt="Home Slide"
                src="./assets/assets2/images/header-slider/home-v2/slider3.jpg"
              />
            </figure>
            <div className="container">
              <div className="carousel-caption">
                <h2>The Content Marketplace!</h2>
                <h3>Control. Distribute. Monetize.</h3>
                {/* <p>Read the best books on Kokawe</p> */}
                <div className="slide-buttons">
                  <Link
                    to={profile ? "/upload-book" : "/register"}
                    className="btn btn-primary"
                    style={{ fontSize: "10px", margin: "10px" }}
                  >
                    Upload Books
                  </Link>
                  <Link
                    to={profile ? "/books" : "/register"}
                    className="btn btn-default"
                    style={{ fontSize: "10px" }}
                  >
                    Start Reading
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <figure>
              <img
                alt="Home Slide"
                src="./assets/assets2/images/header-slider/home-v2/slider4.jpg"
              />
            </figure>
            <div className="container">
              <div className="carousel-caption">
                <h2>The Content Marketplace!</h2>
                <h3>Control. Distribute. Monetize.</h3>
                {/* <p>Watch Educative Videos on Kokawe for free</p> */}
                <div className="slide-buttons">
                  <Link
                    to={profile ? "/upload-media" : "/register"}
                    className="btn btn-primary"
                    style={{ fontSize: "10px", margin: "10px" }}
                  >
                    Watch Media
                  </Link>
                  <Link
                    to={profile ? "/medias" : "/register"}
                    className="btn btn-default"
                    style={{ fontSize: "10px" }}
                  >
                    Upload Media
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <figure>
              <img
                alt="Home Slide"
                src="./assets/assets2/images/header-slider/home-v2/slider5.jpg"
              />
            </figure>
            <div className="container">
              <div className="carousel-caption">
                <h2>The Content Marketplace!</h2>
                <h3>Control. Distribute. Monetize.</h3>
                {/* <p>Listen to Educative AudioBooks on Kokawe for free</p> */}
                <div className="slide-buttons">
                  <Link
                    to={profile ? "/upload-media" : "/register"}
                    className="btn btn-primary"
                    style={{ fontSize: "10px", margin: "10px" }}
                  >
                    Upload Book
                  </Link>
                  <Link
                    to={profile ? "/upload-media" : "/register"}
                    className="btn btn-default"
                    style={{ fontSize: "10px" }}
                  >
                    Start Reading
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ol className="carousel-indicators">
          <li
            data-target="#home-v1-header-carousel"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#home-v1-header-carousel" data-slide-to="1"></li>
          <li data-target="#home-v1-header-carousel" data-slide-to="2"></li>
          <li data-target="#home-v1-header-carousel" data-slide-to="3"></li>
          <li data-target="#home-v1-header-carousel" data-slide-to="4"></li>
        </ol>
      </div>
      <section className="search-filters">
        <div className="container">
          <div className="filter-box">
            <h3>What are you looking for at the library?</h3>
            <form onSubmit={(e) => searchBooks(e)} method="post">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="sr-only" htmlFor="keywords">
                    Search by Keyword
                  </label>
                  <input
                    className="form-control"
                    style={{
                      backgroundColor: "white",
                      color: "grey",
                      border: "none",
                      height: "35px",
                    }}
                    placeholder="Search by Keyword"
                    id="keywords"
                    type="text"
                    value={title}
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <Select options={options} name="type" />
                </div>
              </div>
              {/* <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <Select options={categoryOptions} name="category" />
                </div>
              </div> */}
              <div className="col-md-2 col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control"
                    style={{ height: "35px" }}
                    type="submit"
                    value="Search"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section
        className="welcome-section"
        style={{ marginTop: "30px" }}
        id="about"
      >
        <div className="container">
          <h2 className="section-title text-center">Welcome to the Kokawe</h2>
          <div>
            <img src="./../../assets/about.jpg" loading="lazy" alt="about" />
          </div>
        </div>
      </section>

      <section className="booksmedia-fullwidth">
        <div className="container">
          <h2 className="section-title text-center">Popular Books</h2>
          <span className="underline center"></span>
          <ul className="popular-items-detail-v2">
            {books.length > 0
              ? books.slice(0, 3).map((_book, idx) => (
                  <li key={idx}>
                    {/* <div className="book-list-icon yellow-icon"></div> */}
                    <Link to={"/book/" + _book.id}>
                      <figure>
                        <img src={_book.bookCoverUrl} alt="Book" />
                        <figcaption>
                          <header>
                            <h4>
                              <Link to={"/book/" + _book.id}>
                                {_book.title}
                              </Link>
                            </h4>
                            <p>
                              <strong>Author:</strong>
                              {_book.author}
                            </p>
                            <p>
                              <strong>ISBN:</strong>
                              {_book.isbn}
                            </p>
                            <p>
                              <strong>Cost:</strong> From ₦
                              {_book.softCopyAmount}
                            </p>
                          </header>
                          <p></p>
                          <div className="actions">
                            <ul>
                              <li>
                                <Link
                                  onClick={(e) =>
                                    addToCarts(e, {
                                      ..._book,
                                      price: _book.softCopyAmount,
                                      type: "book",
                                      objectType: "book",
                                      bookType: "soft",
                                      id: `${_book.id} soft`,
                                    })
                                  }
                                  target="_blank"
                                  data-toggle="blog-tags"
                                  data-placement="top"
                                  title="Add TO CART"
                                >
                                  <i className="fa fa-shopping-cart"></i>
                                </Link>
                                {/* <Link
                                  onClick={(e) => addToCarts(e, _book)}
                                  target="_blank"
                                  data-toggle="blog-tags"
                                  data-placement="top"
                                  title="Add TO CART"
                                >
                                  <i className="fa fa-shopping-cart"></i>
                                </Link> */}
                              </li>
                              {/* <li>
                                  <Link to="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                      <i className="fa fa-heart"></i>
                `                  </Link>
                              </li>
                              <li>
                                  <Link to="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                  </Link>
                              </li> */}
                              <li>
                                <RWebShare
                                  data={{
                                    text: _book.title,
                                    url: "https://kokawe.com/book/" + _book.id,
                                    title: _book.title,
                                  }}
                                  onClick={() =>
                                    toast.success("shared successfully!")
                                  }
                                >
                                  <Link
                                    to="#"
                                    target="_blank"
                                    data-toggle="blog-tags"
                                    data-placement="top"
                                    title="Share"
                                  >
                                    <i className="fa fa-share-alt"></i>
                                  </Link>
                                </RWebShare>
                              </li>
                            </ul>
                          </div>
                        </figcaption>
                      </figure>
                    </Link>
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </section>
{/* 
      <section className="booksmedia-fullwidth">
        <div className="container">
          <h2 className="section-title text-center">Recommended Courses</h2>
          <span className="underline center"></span>
          <ul style={{ margin: "40px 0px" }}>
            {!courses
              ? ""
              : courses.slice(0, 3).map((course, idx) => (
                  <li key={idx}>
                    <Card sx={{ maxWidth: 445 }}>
                      <Box
                        height="194"
                        sx={{
                          backgroundImage: `url(${background})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          minHeight: "350px",
                          textAlign: "center",
                          padding: 5,
                        }}
                      >
                        <Typography
                          variant="h5"
                          marginTop={10}
                          color="white"
                          sx={{ fontWeight: "700" }}
                        >
                          {course.name}
                        </Typography>
                      </Box>
                      <CardContent>
                        <Link to={`/course/${course.id}`}>
                          <Typography variant="h4" color="black">
                            {course.name}
                          </Typography>
                        </Link>
                        <Typography
                          variant="subtitle1"
                          sx={{ paddingY: "10px" }}
                          color="text.secondary"
                        >
                          {course.description.substring(0, 100) + "..."}
                        </Typography>
                      </CardContent>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          variant="h5"
                          sx={{ padding: "10px", fontWeight: "600" }}
                        >
                          ₦{setPrice(course ? course.rrp : 0)}
                        </Typography>
                        <CardActions disableSpacing>
                          <IconButton aria-label="share">
                            <ShareOutlined />
                          </IconButton>
                          <IconButton
                            onClick={(e) =>
                              addToCarts(e, {
                                ...course,
                                type: "course",
                                price: setPrice(course ? course.rrp : 0),
                                author: "kokawe",
                                objectType: "course",
                              })
                            }
                            aria-label="add to cart"
                          >
                            <ShoppingCart />
                          </IconButton>
                        </CardActions>
                      </Box>
                    </Card>
                  </li>
                ))}
          </ul>
        </div>
      </section> */}

      <section
        className="category-filter booksmedia-fullwidth section-padding"
        id="new-releases"
      >
        <div className="container">
          <div className="row">
            <div className="center-content">
              <h2 className="section-title">New Releases</h2>
              <span className="underline center"></span>
              <p className="lead">Check Out The New Releases</p>
            </div>
            <ul className="popular-items-detail-v2">
              {books.length > 0
                ? books.slice(0, 3).map((_book, idx) => (
                    <li key={idx}>
                      {/* <div className="book-list-icon yellow-icon"></div> */}
                      <Link to={"/book/" + _book.id}>
                        <figure>
                          <img src={_book.bookCoverUrl} alt="Book" />
                          <figcaption>
                            <header>
                              <h4>
                                <Link to={"/book/" + _book.id}>
                                  {_book.title}
                                </Link>
                              </h4>
                              <p>
                                <strong>Author:</strong>
                                {_book.author}
                              </p>
                              <p>
                                <strong>ISBN:</strong>
                                {_book.isbn}
                              </p>
                              <p>
                                <strong>Cost:</strong> From ₦
                                {_book.softCopyAmount}
                              </p>
                            </header>
                            <p></p>
                            <div className="actions">
                              <ul>
                                <li>
                                  <Link
                                    onClick={(e) => addToCarts(e, _book)}
                                    target="_blank"
                                    data-toggle="blog-tags"
                                    data-placement="top"
                                    title="Add TO CART"
                                  >
                                    <i className="fa fa-shopping-cart"></i>
                                  </Link>
                                </li>
                                {/* <li>
                                                            <Link to="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                <i className="fa fa-heart"></i>
                                          `                  </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" target="_blank" data-toggle="blog-tags" data-placement="top" title="Mail"><i className="fa fa-envelope"></i>
                                                            </Link>
                                                        </li> */}
                                <li>
                                  <RWebShare
                                    data={{
                                      text: _book.title,
                                      url:
                                        "https://kokawe.com/book/" + _book.id,
                                      title: _book.title,
                                    }}
                                    onClick={() =>
                                      toast.success("shared successfully!")
                                    }
                                  >
                                    <Link
                                      to="#"
                                      target="_blank"
                                      data-toggle="blog-tags"
                                      data-placement="top"
                                      title="Share"
                                    >
                                      <i className="fa fa-share-alt"></i>
                                    </Link>
                                  </RWebShare>
                                </li>
                              </ul>
                            </div>
                          </figcaption>
                        </figure>
                      </Link>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="container">
          <ul>
            <li className="yellow-hover">
              <div className="feature-box">
                <i className="yellow"></i>
                <h3>Books (HARD COPY)</h3>
                <p></p>
                <Link className="yellow" to="/books">
                  View All <i className="fa fa-long-arrow-right"></i>
                </Link>
              </div>
            </li>
            <li className="light-green-hover">
              <div className="feature-box">
                <i className="light-green"></i>
                <h3>eBooks</h3>
                <p></p>
                <Link className="light-green" to="/books">
                  View All <i className="fa fa-long-arrow-right"></i>
                </Link>
              </div>
            </li>

            <li className="red-hover">
              <div className="feature-box">
                <i className="red"></i>
                <h3>Articles</h3>
                <p></p>
                <Link className="red" to="/articles">
                  View All <i className="fa fa-long-arrow-right"></i>
                </Link>
              </div>
            </li>
            {/* <li className="violet-hover">
              <div className="feature-box">
                <i className="violet"></i>
                <h3>Online Courses</h3>
                <Link className="violet" to="/medias" aria-disabled="true">
                  View All <i className="fa fa-long-arrow-right"></i>
                </Link>
              </div>
            </li> */}
            <li className="green-hover">
              <div className="feature-box">
                <i className="green"></i>
                <h3>AudioBooks</h3>
                {/* <p>coming soon..</p> */}
                <Link className="green" to="/medias">
                  View Selection <i className="fa fa-long-arrow-right"></i>
                </Link>
              </div>
            </li>
            <li className="blue-hover">
              <div className="feature-box">
                <i className="blue"></i>
                <h3>Videos</h3>
                {/* <p>coming soon</p> */}
                <Link className="blue" to="/medias">
                  View Selection <i className="fa fa-long-arrow-right"></i>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <div className="layout-v2-counter">
        <div className="facts-counter">
          <div className="container">
            <div className="row">
              <ul>
                {/* <li className="color-light-green">
                    <div className="fact-item">
                        <div className="fact-icon">
                            <i className="ebook"></i>
                        </div>
                        <span>eBooks<strong className="fact-counter">{books.length}</strong></span>
                    </div>
                </li> */}
                <li className="color-light-green">
                  <Link className="blue" to="/books">
                    <div className="fact-item">
                      <div className="fact-icon">
                        <i className="ebook"></i>
                      </div>
                      <span>
                        Books
                        <strong className="fact-counter">{books.length}</strong>
                      </span>
                    </div>
                  </Link>
                </li>
                <li className="color-red">
                  <Link className="blue" to="/articles">
                    <div className="fact-item">
                      <div className="fact-icon">
                        <i className="magazine"></i>
                      </div>
                      <span>
                        Articles
                        <strong className="fact-counter">
                          {articles.length}
                        </strong>
                      </span>
                    </div>
                  </Link>
                </li>
                {/* <li className="color-red">
                  <Link className="blue" to="/courses">
                    <div className="fact-item">
                      <div className="fact-icon">
                        <i className="magazine"></i>
                      </div>
                      <span>
                        Online Courses
                        <strong className="fact-counter">
                          {courses.length}
                        </strong>
                      </span>
                    </div>
                  </Link>
                </li> */}
                {/* <li className="color-green">
                  <div className="fact-item">
                    <div className="fact-icon">
                      <i className="eaudio"></i>
                    </div>
                    <span>
                      Medias
                      <strong className="fact-counter">{audios.length}</strong>
                    </span>
                  </div>
                </li> */}
                <li className="color-blue">
                  <Link className="blue" to="/medias">
                    <div className="fact-item">
                      <div className="fact-icon">
                        <i className="videos"></i>
                      </div>
                      <span>
                        Medias
                        <strong className="fact-counter">
                          {videos.length + audios.length}
                        </strong>
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="testimonials">
        <div className="container">
          <h3 className="section-title">Words From our Community</h3>
          <span className="underline center"></span>
          <div className="row">
            <div className="col-md-12" data-wow-delay="0.2s">
              <div
                className="carousel slide"
                data-interval="false"
                data-ride="carousel"
                id="testimonials"
              >
                <div className="carousel-inner text-center">
                  <div className="item active">
                    <figure>
                      <img
                        src="./assets/assets2/images/testimonial.jpeg"
                        width={80}
                        alt="testimonials"
                      />
                      <figcaption>
                        <div className="client-name">Godgift Egims</div>
                        <div className="designation">Publisher</div>
                      </figcaption>
                    </figure>
                    <p>I made cool money referring writers on this platform</p>
                  </div>

                  <div className="item">
                    <figure>
                      <img
                        src="./assets/assets2/images/testimonials-01.png"
                        alt="testimonials"
                      />
                      <figcaption>
                        <div className="client-name">Ayomide Daniels</div>
                        <div className="designation">Reader</div>
                      </figcaption>
                    </figure>
                    <p>The platform makes it encouraging to read a book</p>
                  </div>

                  <ol className="carousel-indicators">
                    <li
                      data-target="#testimonials"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li data-target="#testimonials" data-slide-to="1"></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="newsletter section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="center-content">
                <h2 className="section-title">Subscribe to our Newsletters</h2>
                <span className="underline center"></span>
                <p className="lead"></p>
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Enter your Email!"
                  id="newsletter"
                  name="newsletter"
                  type="email"
                />
                <input
                  className="form-control"
                  value="Subscribe"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default Home;
