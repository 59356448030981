import { ToastContainer } from "react-toastify";

const AuthorSettings = () => {

    return (
        <>
            <div className="main-content" style={{padding: '40px 0px'}}>
                <section className="section">
                    <div className="section-header">
                        <h1>Settings</h1>
                    </div>
                    <p>Coming soon!!!</p>
                </section>
            </div>
            <ToastContainer />
        </>
    )
}

export default AuthorSettings;
