import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import http from "../../utils/http";

const AdminWallets = () => {
  const [wallets, setWallets] = useState([]);
 
  const getWallet = async () => {
    const data = await http.get('admin/wallets');
    let cont = [];
    for(let i = 0; i < data.data.data.length; i++) {
      cont.push(data.data.data[i])
    }
    setWallets(cont);
  } 
  useEffect(() => {
    getWallet()
  }, [])

  return (
    <>
      <div className="main-content" style={{padding: '40px 0px'}}>
        <section className="section">
          <div className="section-header">
            <h1>Wallet transactions</h1>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card" style={{margin: '20px 10px'}}>
                <div className="card-header">
                  <h4>Admin Wallets</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-md v_center">
                      <tr>
                        <th>#</th>
                        <th>Name/Owner</th>
                        <th>Balance</th>                                            
                        <th>Action</th>
                      </tr>
                      {
                        wallets ? wallets.map((wallet, idx) => 
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{wallet.owner}</td>
                            <td>{wallet.balance}</td>                           
                            <td><Link className="btn btn-secondary">Freeze</Link></td>
                          </tr>
                        ) : ""
                      }
                    </table>
                  </div>
                </div>
                {/* <div className="card-footer text-right">
                  <nav className="d-inline-block">
                    <ul className="pagination mb-0">
                      <li className="page-item disabled"><Link className="page-link" to="" tabindex="-1"><i className="fas fa-chevron-left"></i></Link></li>
                      <li className="page-item active"><Link className="page-link" to="">1 <span className="sr-only">(current)</span></Link></li>
                      <li className="page-item"><Link className="page-link" to="">2</Link></li>
                      <li className="page-item"><Link className="page-link" to="">3</Link></li>
                      <li className="page-item"><Link className="page-link" to=""><i className="fas fa-chevron-right"></i></Link></li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default AdminWallets;
