import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { decrypt } from "../../utils/encryptor";
import http from "../../utils/http";

function PlayMedia() {
    const [media, setMedia] = useState();
    const params = useParams();

    useEffect(() => {
        const getMedia = async () => {            
            const {data} = await http.get(`my-video/stream/${params.id}`);
            setMedia(data.data);
        }
        getMedia();
    }, [params.id])

    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>{media ? media.title : ''}</h2>
                        <span className="underline center"></span>
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>{media ? media.title : ''}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <div className="books-full-width">
                            <div className="container">
                                <div className="booksmedia-fullwidth">
                                    <ul>
                                        {
                                            media && media.video_url ? media.video_url.split(',').map((_media, idx) =>                                        
                                            <div key={idx + 1} className="col-xs-12 col-sm-6 col-md-4">
                                                <div style={{padding: '20px 20px', margin: '20px 20px'}}>
                                                    <video width={400} height={300} controls style={{padding: '20px 20px'}}>
                                                        <source src={JSON.parse(decrypt(_media)).text} type="video/mp4"/>
                                                        <source src={JSON.parse(decrypt(_media)).text} type="video/quicktime"/>
                                                    </video>
                                                    <h4 style={{padding: '20px 20px'}}>{media.title} {idx + 1}</h4>
                                                </div>
                                            </div>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
};

export default PlayMedia;