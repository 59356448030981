import Select from "react-select";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import http from "../utils/http";
import { toast, ToastContainer } from "react-toastify";
import { ClipLoader } from "react-spinners";
import background from "../assets/course_background.jpeg";
import { useCart } from "react-use-cart";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

function Cart() {
  const {
    getItem,
    updateItem,
    items,
    cartTotal,
    totalItems,
    emptyCart,
    updateItemQuantity,
    removeItem,
  } = useCart();
  const [carts, setCarts] = useState([]);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [disable_login, setLoginStatus] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [ref, setRef] = useState(new Date().getTime().toString());

  let email = localStorage.getItem("email");

  useEffect(() => {
    if (items) {
      let totalAmount = 0;
      for (let i = 0; i < items.length; i++) {
        totalAmount += items[i].subtotal;
      }
      setAmount(totalAmount);
    }
  }, []);

  const navigate = useNavigate();

  // const getTotalAmount = () => {
  //     if (items) {
  //         let totalAmount = 0;
  //         for (let i = 0; i < items.length; i++) {
  //             totalAmount += (items[i].subtotal);
  //         }
  //         setAmount(totalAmount);
  //     }
  // }

  // const handleTypeChange = (type, product) => {
  //     carts.forEach((cart) => {
  //         if (cart.id === product.id) {
  //             cart.bookType = [];
  //             delete cart.title
  //             delete cart.isbn
  //             type.forEach((copy) => {
  //                 cart.bookType.push(copy.value);
  //                 if (copy.value === 'soft copy') {
  //                     cart.softCopySubtotal = product.softCopyAmount ?? product.amount.softCopy
  //                 } else {
  //                     cart.hardCopySubtotal = product.hardCopyAmount ?? product.amount.hardCopy
  //                 }
  //             })
  //         }
  //     })
  //     setCarts(carts)
  // }

  // const handleTimeChange = (time, product) => {
  //     carts.forEach((cart) => {
  //         if (cart.id === product.id && cart.type === 'book') {
  //             if (cart.softCopyAmount) {
  //                 cart.softCopySubtotal = time.value === 7 ? (cart.softCopyAmount * time.value) - (cart.softCopyAmount * time.value * 0.05) : time.value === 1 ? cart.softCopyAmount : time.value === 14 ? (cart.softCopyAmount * time.value) - (cart.softCopyAmount * time.value * 0.10) : time.value === 30 ? (cart.softCopyAmount * time.value) - (cart.softCopyAmount * time.value * 0.15) : cart.softCopySubtotal;
  //             }
  //             cart.subtotal = cart.softCopySubtotal + (cart.hardCopySubtotal ?? 0);
  //             cart.sub_type = time.value;
  //         }
  //         let totalAmount = cart.subtotal;
  //         setAmount(totalAmount)
  //     })
  //     setCarts(carts)
  // };

  const handleHardCopyChange = (e, product) => {
    updateItemQuantity(product.id, e.target.value);
  };

  const handleMediaAccessChange = (time, product) => {
    if (time.value === 0 && product.type === "book") {
      updateItem(product.id, { price: parseInt(product.oneTimePayment) ?? 0 });
    } else if (time.value === 1 && product.type === "book") {
      updateItem(product.id, { price: product.softCopyAmount });
    } else if (time.value === 0 && product.type === "media") {
      updateItem(product.id, { price: parseInt(product.oneTimePayment) ?? 0 });
    } else if (time.value === 1 && product.type === "media") {
      updateItem(product.id, { price: parseInt(product.amount) });
    }
  };

  const time = [
    { key: 1, value: 1, label: "One Day" },
    { key: 0, value: 0, label: "One time Purchase" },
  ];

  const purchase = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoginStatus(true);
    if (!email) {
      toast.info("please login to continue");
      return;
    }
    items.forEach((cart) => {
      delete cart.created_at;
      delete cart.description;
      delete cart.comments;
      delete cart.message;
      delete cart.unLikeUser;
      delete cart.unlike;
      delete cart.author_bio;
      delete cart.author_name;
      delete cart.likes;
      delete cart.likeUser;
      delete cart.price;
      delete cart.title;
      delete cart.hardCopySubtotal;
      delete cart.softCopySubtotal;
      cart.quantity = parseInt(cart.quantity);
      if (cart.type === "book") {
        if (cart.id.includes("soft")) {
          cart.id = parseInt(cart.id.split(" ")[0].toString());
          cart.bookType = "soft copy";
          cart.sub_type = "";
        } else {
          cart.id = parseInt(cart.id.split(" ")[0].toString());
          cart.bookType = "hard copy";
          cart.sub_type = "";
        }
      } else {
        cart.sub_type = "";
      }
    });
    let postData = {
      ref_no: ref,
      items: items,
      country: country,
      state: state,
      city: city,
      phone: phone,
      address: address,
    };
    console.log(postData);
    let { data } = await http.post("purchase", postData);
    if (data.data) {
      initializePayment(onSuccess, onClose);
    } else {
      setLoading(false);
      setLoginStatus(false);
      toast.error(data.message);
    }
  };

  // you can call this function anything
  const onSuccess = () => {
    const data = http.post(`checkout/${ref}`);
    emptyCart();
    navigate("/reader-books");
  };

  // you can call this function anything
  const onClose = () => {
    toast.info("Payment Cancelled");
  };

  const config = {
    reference: ref,
    email: email != null ? email.slice(1, email.length - 1) : "",
    amount: cartTotal * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  };

  const initializePayment = usePaystackPayment(config);

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>Cart</h2>
            <span className="underline center"></span>
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>Cart</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="cart-main">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="page type-page status-publish hentry">
                      <div className="entry-content">
                        <div
                          className="woocommerce table-tabs"
                          id="responsiveTabs"
                        >
                          <div className="tab-content">
                            <div
                              id="sectionA"
                              className="tab-pane fade in active"
                            >
                              <form method="post">
                                <div className="table-responsive">
                                  <table className="table table-bordered shop_table cart">
                                    <thead>
                                      <tr>
                                        <th className="product-name">
                                          &nbsp;#
                                        </th>
                                        <th className="product-name">Title</th>
                                        {/* <th className="product-name">Type</th> */}
                                        <th className="product-price">
                                          Time / Quantity
                                        </th>
                                        <th className="product-subtotal">
                                          Subtotal
                                        </th>
                                        <th className="product-action">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {!carts ? (
                                        <>
                                          <p>Empty Cart</p>
                                        </>
                                      ) : (
                                        items.map((product, idx) => (
                                          <tr key={idx} className="cart_item">
                                            <td
                                              data-title="cbox"
                                              className="product-cbox"
                                            >
                                              {idx + 1}
                                            </td>
                                            {product.type === "book" ? (
                                              <td
                                                data-title="Product"
                                                className="product-nam"
                                                style={{ width: "250px" }}
                                              >
                                                <span className="product-thumbnail">
                                                  <Link to="">
                                                    <img
                                                      src={product.bookCoverUrl}
                                                      alt="cart-product-1"
                                                    />
                                                  </Link>
                                                </span>
                                                <span className="product-detail">
                                                  <span>
                                                    <Link to="">
                                                      <strong>
                                                        {product.title ??
                                                          product.name}
                                                      </strong>
                                                    </Link>
                                                  </span>
                                                  <span>
                                                    <strong>Author:</strong>{" "}
                                                    {product.author}{" "}
                                                  </span>
                                                  <span>
                                                    <strong>ISBN:</strong>{" "}
                                                    {product.isbn}
                                                  </span>
                                                  {product.bookType ===
                                                  "soft" ? (
                                                    <>
                                                      <span>
                                                        <strong>
                                                          Soft Copy Fees:
                                                        </strong>{" "}
                                                        <em>
                                                          ₦
                                                          {
                                                            product.softCopyAmount
                                                          }
                                                        </em>
                                                      </span>
                                                      <span>
                                                        <strong>
                                                          (Soft Copy)
                                                        </strong>
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <span>
                                                        <strong>
                                                          Hard Copy Cost:
                                                        </strong>{" "}
                                                        <em>
                                                          ₦
                                                          {
                                                            product.hardCopyAmount
                                                          }
                                                        </em>
                                                      </span>
                                                      <span>
                                                        <strong>
                                                          (Hard Copy)
                                                        </strong>
                                                      </span>
                                                    </>
                                                  )}
                                                </span>
                                              </td>
                                            ) : product.type === "course" ? (
                                              <td
                                                data-title="Product"
                                                className="product-nam"
                                                style={{ width: "250px" }}
                                              >
                                                <Box
                                                  height="194"
                                                  sx={{
                                                    backgroundImage: `url(${background})`,
                                                    backgroundRepeat:
                                                      "no-repeat",
                                                    backgroundSize: "contain",
                                                    minWidth: "250px",
                                                    minHeight: "250px",
                                                    textAlign: "center",
                                                    padding: 9,
                                                  }}
                                                >
                                                  <Typography
                                                    variant="h5"
                                                    marginTop={10}
                                                    color="white"
                                                    sx={{ fontWeight: "700" }}
                                                  >
                                                    {product.name}
                                                  </Typography>
                                                </Box>
                                                <span className="product-detail">
                                                  <span>
                                                    <Link to="">
                                                      <strong>
                                                        {product.name}
                                                      </strong>
                                                    </Link>
                                                  </span>
                                                  <span>
                                                    <strong>Author:</strong>{" "}
                                                    {product.author}{" "}
                                                  </span>
                                                  <span>
                                                    <strong>Amount:</strong>{" "}
                                                    <em>₦{product.price}</em>
                                                  </span>
                                                </span>
                                              </td>
                                            ) : (
                                              <td
                                                data-title="Product"
                                                className="product-nam"
                                                style={{ width: "250px" }}
                                              >
                                                <span className="product-thumbnail">
                                                  <Link to="">
                                                    <img
                                                      src={
                                                        product.video_cover_url ??
                                                        product.audio_cover_url
                                                      }
                                                      alt="cart-product-1"
                                                    />
                                                  </Link>
                                                </span>
                                                <span className="product-detail">
                                                  <span>
                                                    <Link to="">
                                                      <strong>
                                                        {product.title}
                                                      </strong>
                                                    </Link>
                                                  </span>
                                                  <span>
                                                    <strong>Author:</strong>{" "}
                                                    {product.owner}{" "}
                                                  </span>
                                                  <span>
                                                    <strong>Amount:</strong>{" "}
                                                    <em>₦{product.amount}</em>
                                                  </span>
                                                </span>
                                              </td>
                                            )}
                                            {product.type === "book" ? (
                                              <>
                                                {product.bookType === "soft" ? (
                                                  <td
                                                    data-title="Price"
                                                    className="product-pric"
                                                  >
                                                    <label htmlFor="time">
                                                      Access Duration
                                                    </label>{" "}
                                                    <br />
                                                    <Select
                                                      options={time}
                                                      onChange={(e) =>
                                                        handleMediaAccessChange(
                                                          e,
                                                          product
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                ) : (
                                                  <td
                                                    data-title="Price"
                                                    className="product-pric"
                                                  >
                                                    <label htmlFor="copies">
                                                      Hard Copies
                                                    </label>{" "}
                                                    <br />
                                                    <input
                                                      type="number"
                                                      min={0}
                                                      onChange={(e) =>
                                                        handleHardCopyChange(
                                                          e,
                                                          product
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                )}
                                              </>
                                            ) : product.type === "course" ? (
                                              <td
                                                data-title="Price"
                                                className="product-pric"
                                              ></td>
                                            ) : (
                                              <td
                                                data-title="Price"
                                                className="product-pric"
                                              >
                                                <label htmlFor="time">
                                                  Access Duration
                                                </label>{" "}
                                                <br />
                                                <Select
                                                  options={time}
                                                  onChange={(e) =>
                                                    handleMediaAccessChange(
                                                      e,
                                                      product
                                                    )
                                                  }
                                                />
                                              </td>
                                            )}
                                            <td
                                              className="product-price"
                                              style={{ width: "240px" }}
                                            >
                                              <span style={{ top: "30px" }}>
                                                <strong>SUBTOTAL:</strong> ₦
                                                {product.itemTotal}
                                              </span>
                                            </td>
                                            <td
                                              className="product-price"
                                              style={{ width: "40px" }}
                                            >
                                              <Link
                                                onClick={(e) =>
                                                  removeItem(product.id)
                                                }
                                              >
                                                <i
                                                  style={{ fontSize: "25px" }}
                                                  className="fa fa-trash"
                                                ></i>
                                              </Link>
                                            </td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <p>
                                  Scroll table to edit cart item
                                  <i className="fa-solid fa-arrow-right"></i>
                                </p>
                                <h3
                                  style={{
                                    textAlign: "end",
                                    padding: "10px 0px 50px",
                                  }}
                                >
                                  <strong> TOTAL : ₦{cartTotal}</strong>
                                </h3>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section>
                    <article className="page type-page status-publish hentry">
                      <div className="entry-content">
                        <div className="woocommerce">
                          <form
                            className="checkout woocommerce-checkout"
                            method="post"
                            name="checkout"
                          >
                            <div className="">
                              <div className="col-sm-12">
                                <div className="woocommerce-billing-fields">
                                  <h2 style={{ padding: "50px 0px 10px" }}>
                                    Delivery Address
                                  </h2>
                                  <span className="underline left"></span>
                                  <div className="row">
                                    <div className="billing-address-box">
                                      <div className="clear"></div>
                                      <div className="col-xs-12 col-sm-12">
                                        <p
                                          id="billing_address_1_field"
                                          className="form-row form-row form-row-wide address-field validate-required"
                                        >
                                          <input
                                            type="text"
                                            value={address}
                                            onChange={(e) =>
                                              setAddress(e.target.value)
                                            }
                                            placeholder="Address"
                                            name="billing_address_1"
                                            className="input-text"
                                          />
                                        </p>
                                      </div>
                                      <div className="col-xs-12 col-sm-6">
                                        <p
                                          id="billing_city_field"
                                          className="form-row form-row form-row-wide address-field validate-required"
                                        >
                                          <input
                                            type="text"
                                            value={city}
                                            onChange={(e) =>
                                              setCity(e.target.value)
                                            }
                                            placeholder="Town / City"
                                            name="billing_city"
                                            className="input-text"
                                          />
                                        </p>
                                      </div>
                                      <div className="col-xs-12 col-sm-6">
                                        <p
                                          id="billing_state_field"
                                          className="form-row form-row form-row-first address-field validate-state validate-required"
                                        >
                                          <input
                                            type="text"
                                            name="state"
                                            placeholder="State"
                                            value={state}
                                            onChange={(e) =>
                                              setState(e.target.value)
                                            }
                                            className="input-text "
                                          />
                                        </p>
                                      </div>
                                      <div className="col-xs-12 col-sm-6">
                                        <p
                                          id="billing_state_field"
                                          className="form-row form-row form-row-first address-field validate-state validate-required"
                                        >
                                          <input
                                            type="text"
                                            name="country"
                                            placeholder="Country"
                                            value={country}
                                            onChange={(e) =>
                                              setCountry(e.target.value)
                                            }
                                            className="input-text "
                                          />
                                        </p>
                                      </div>
                                      <div className="col-xs-12 col-sm-6">
                                        <p
                                          id="billing_phone_field"
                                          className="form-row form-row form-row-last validate-required validate-phone"
                                        >
                                          <input
                                            type="tel"
                                            value={phone}
                                            onChange={(e) =>
                                              setPhone(e.target.value)
                                            }
                                            autoComplete="tel"
                                            placeholder="Phone"
                                            id="billing_phone"
                                            name="phone"
                                            className="input-text"
                                          />
                                        </p>
                                      </div>
                                      <div className="col-xs-12 col-sm-12">
                                        <div className="radio">
                                          <input
                                            id="bill-address"
                                            type="radio"
                                            value="bill-address"
                                            name="bill-address"
                                          />
                                          <label htmlFor="bill-address">
                                            Ship Items To The Above Billing
                                            Address
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      type="submit"
                                      onClick={(e) => purchase(e)}
                                      id="place_order"
                                      name="woocommerce_checkout_place_order"
                                      className="btn btn-primary"
                                    >
                                      {disable_login ? (
                                        <ClipLoader
                                          loading={loading}
                                          size={20}
                                        />
                                      ) : (
                                        "Checkout"
                                      )}
                                    </button>
                                  </div>
                                  <div className="clear"></div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </article>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Cart;
