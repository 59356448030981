import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
// import ErrorPage from './error-page';
import Privacy from "./routes/Privacy";
import AdminArticles from "./routes/admin/admin-articles";
import AdminBooks from "./routes/admin/admin-books";
import AdminCategories from "./routes/admin/admin-categories";
import AdminTags from "./routes/admin/admin-tags";
import AdminUsers from "./routes/admin/admin-users";
import Article from "./routes/Article";
import AuthRoutes from "./routes/Auth-routes";
import AuthorRoutes from "./routes/Author";
import AuthorSubscription from "./routes/author/author-subscription";
import Books from "./routes/Books";
import Dashboard from "./routes/Dashboard";
import Home from "./routes/Home";
import Login from "./routes/Login";
import NewArticle from "./routes/New-article";
import Register from "./routes/Register";
import CalendlyAuth from "./routes/CalendlyAuth"
import RootRoutes from "./routes/root";
import Services from "./routes/Services";
import UploadBook from "./routes/Upload-book";
import PrivateRoutes from "./utils/PrivateRoutes";
import AdminWithdrawals from "./routes/admin/admin-withdrawals";
import AdminBookTransactions from "./routes/admin/admin-book-transactions";
import AdminSubTransactions from "./routes/admin/admin-sub-transactions";
import Articles from "./routes/Articles";
import Reader from "./routes/Reader";
import Contact from "./routes/Contact";
import ForgotPassword from "./routes/Forgot-Password";
import AuthorArticles from "./routes/author/author-articles";
import AuthorBooks from "./routes/author/author-books";
import Cart from "./routes/Cart";
import AdminRoles from "./routes/admin/admin-roles";
import Book from "./routes/Book";
import ReaderBooks from "./routes/reader/reader-books";
import ChangePassword from "./routes/Change-password";
import ConsultAuthor from "./routes/reader/consult-author";
import AuthorSettings from "./routes/author/author-settings";
import AuthorWallet from "./routes/author/author-wallet";
import AuthorProfile from "./routes/author/author-profile";
import SecondReader from "./routes/Second-reader";
import AdminWallets from "./routes/admin/admin-wallets";
import AuthorEditArticle from "./routes/author/author-edit-article";
import Medias from "./routes/Medias";
import UploadMedia from "./routes/author/author-upload-media";
import AuthorMedias from "./routes/author/author-medias";
import Media from "./routes/Media";
import AuthorEditBook from "./routes/author/author-edit-book";
import UserMedia from "./routes/reader/user-media";
import Logout from "./routes/Logout";
import AdminPercentages from "./routes/admin/admin-percentages";
import AdminEditPercentages from "./routes/admin/admin-edit-percentage";
import PublisherDashboard from "./routes/publisher/publisher-dashboard";
import AdminDashboard from "./routes/admin/admin-dashboard";
import Authors from "./routes/Authors";
import CookieConsent from "react-cookie-consent";
import AdminMedias from "./routes/admin/admin-media";
import AuthorCreateEvent from "./routes/author/author-create-event";
import Events from "./routes/Events";
import EventDetails from "./routes/Event-Details";
import AuthorEvents from "./routes/author/author-events";
import AuthorEditEvent from "./routes/author/author-edit-event";
import AdminEvents from "./routes/admin/admin-events";
import AuthorEventUsers from "./routes/author/author-events-users";
import EditMedia from "./routes/author/author-edit-media";
import PlayMedia from "./routes/reader/player";
import Courses from "./routes/Courses";
import Course from "./routes/Course";
import UserCourse from "./routes/reader/user-course";
import Teachers from "./routes/Teachers";
import Teacher from "./routes/Teacher";
import TeacherProfile from "./routes/teacher-profile";
import AdminTeachers from "./routes/admin/admin-teachers";
import MyAppointment from "./routes/reader/my-appointments";
import AdminSubjects from "./routes/admin/admin-subjects";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}></Route>
          <Route element={<AuthorRoutes />}>
            <Route element={<AdminArticles />} path="/admin-articles" exact />
            <Route
              element={<AdminCategories />}
              path="/admin-categories"
              exact
            />
            <Route element={<AdminWallets />} path="/admin-wallets" exact />
            <Route
              element={<AdminWithdrawals />}
              path="/admin-withdrawals"
              exact
            />
            <Route
              element={<AdminBookTransactions />}
              path="/admin-book-transactions"
              exact
            />
            <Route
              element={<AdminSubTransactions />}
              path="/admin-sub-transactions"
              exact
            />
            <Route element={<AdminBooks />} path="/admin-books" exact />
            <Route element={<AdminEvents />} path="/admin-events" exact />
            <Route element={<AdminMedias />} path="/admin-medias" exact />
            <Route
              element={<AdminPercentages />}
              path="/admin-percentages"
              exact
            />
            <Route
              element={<AdminEditPercentages />}
              path="/admin-edit-percentage"
              exact
            />
            <Route element={<AdminTags />} path="/admin-tags" exact />
            <Route element={<AdminSubjects />} path="/admin-subjects" exact />
            <Route element={<ConsultAuthor />} path="/consult-author" exact />
            <Route element={<AdminRoles />} path="/admin-roles" exact />
            <Route element={<AdminUsers />} path="/admin-users" exact />
            <Route element={<AdminTeachers />} path="/admin-teachers" exact />
            <Route element={<Dashboard />} path="/dashboard" exact />
            <Route element={<AdminDashboard />} path="/admin-dashboard" exact />
            <Route element={<AuthorArticles />} path="/author-articles" exact />
            <Route
              element={<AuthorEditArticle />}
              path="/author/article/:id"
              exact
            />
            <Route element={<AuthorEditBook />} path="/author/book/:id" exact />
            <Route
              element={<AuthorEditEvent />}
              path="/author/edit-event/:id"
              exact
            />
            <Route
              element={<EditMedia />}
              path="/author/edit-media/:type/:id"
              exact
            />
            <Route
              element={<AuthorEventUsers />}
              path="/author/view-event/:id"
              exact
            />
            <Route element={<AuthorCreateEvent />} path="/create-event" exact />
            <Route element={<AuthorBooks />} path="/author-books" exact />
            <Route element={<AuthorEvents />} path="/author-events" exact />
            <Route element={<AuthorMedias />} path="/author-medias" exact />
            <Route element={<AuthorSettings />} path="/author-settings" exact />
            <Route element={<AuthorWallet />} path="/author-wallet" exact />
            <Route element={<AuthorProfile />} path="/author-profile" exact />
            <Route
              element={<AuthorSubscription />}
              path="/author-subscriptions"
              exact
            />
            <Route element={<UploadBook />} path="/upload-book" />
            <Route element={<UploadMedia />} path="/upload-media" />
            <Route element={<NewArticle />} path="/new-article" />
          </Route>
          <Route element={<RootRoutes />}>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route element={<Home />} path="/home" exact />
            <Route element={<Books />} path="/books" />
            <Route element={<Cart />} path="/cart" />
            <Route element={<ReaderBooks />} path="/reader-books" exact />
            <Route
              element={<PublisherDashboard />}
              path="/aggregator-dashboard"
              exact
            />
            <Route element={<UserMedia />} path="/user-media" exact />
            <Route element={<UserCourse />} path="/user-course" exact />
            <Route element={<MyAppointment />} path="/my-appointments" exact />
            <Route element={<PlayMedia />} path="/play-media/:id" exact />
            <Route element={<Book />} path="/book/:id" />
            <Route element={<Course />} path="/course/:id" />
            <Route element={<Media />} path="/media/:type/:id" />
            <Route element={<Articles />} path="/articles" />
            <Route element={<Medias />} path="/medias" />
            <Route element={<Courses />} path="/courses" />
            <Route element={<Events />} path="/events" />
            <Route element={<Teachers />} path="/teachers" />
            <Route element={<Teacher />} path="/teacher/:id" />
            <Route element={<EventDetails />} path="/event/:id" />
            <Route element={<Authors />} path="/authors" />
            <Route element={<Services />} path="/services" />
            <Route element={<Article />} path="/article/:id" />
            <Route element={<Contact />} path="/contact" />
            <Route element={<Privacy />} path="/privacy-policy" />
          </Route>
          <Route element={<AuthRoutes />}>
            <Route element={<Reader />} path="/reader/:id" />
            <Route element={<SecondReader />} path="/second-reader" />
            <Route element={<Register />} path="/register" />
            <Route element={<CalendlyAuth />} path="/calendly_auth/:code" />
            <Route element={<TeacherProfile />} path="/teacher-profile" />
            <Route element={<ForgotPassword />} path="/forget-password" />
            <Route element={<ChangePassword />} path="/change-password" />
            <Route element={<Logout />} path="/logout" />
            <Route element={<Login />} path="/login" />
          </Route>
        </Routes>
      </Router>
      <CookieConsent
        location="bottom"
        buttonText="Yes"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "20px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. Using this
        site means you accept the use of this cookies.{" "}
      </CookieConsent>
    </div>
  );
}

export default App;
