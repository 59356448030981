import http from './http';

const getCategories = async () => {
    const { data } = await http.get('/categories');
    return data;
};

const getTags = async () => {
    const { data } = await http.get('/tags');
    return data;
};

export { getTags, getCategories };
