import { useRef } from 'react'
import {
  EpubViewer,
  ReactEpubViewer
} from 'react-epub-viewer'

const SecondReader = () => {
  const viewerRef = useRef(null);
  
  return (
    <div style={{ position: "relative", userSelect: "none", height: "100%" }}>
      <ReactEpubViewer 
        style={{userSelect: "none !important"}}
        url={'https://react-reader.metabits.no/files/alice.epub'}
        ref={viewerRef}
      />
    </div>
  );
}

export default SecondReader;