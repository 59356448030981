import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { RWebShare } from "react-web-share";
import BookLoader from "../components/book-loader";
import http from "../utils/http";

function EventDetails() {

    const [details, setDetails] = useState();
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const params = useParams();

    const options = [
        {value: 'male', label: 'Male'},
        {value: 'female', label: 'Female'},
        {value: 'prefer not to say', label: 'Prefer not to Say'}
    ]
    
    const handleGender = (e) => {
        setGender(e.value)
    }

    const RegisterForEvent = async(e, id) => {
        e.preventDefault();
        let payload = {
            name: `${firstname} ${lastname}`,
            phone: phone,
            gender: gender,
            email: email
        }
        console.log(payload)
        const {data} = await http.post(`event/${id}/register`, payload);
        toast.success(data.data)
        console.log(data)
    }

    useEffect(() => {
        const getEvent = async() => {
            const {data} = await http.get(`event/${params.id}`);
            setDetails(data.data)
        }
        getEvent()
    }, [])
    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>Event Details</h2>
                        <span className="underline center"></span>
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>Event</li>
                        </ul>
                    </div>
                </div>
            </section>
            <div id="content" class="site-content">
                <div id="primary" class="content-area">
                    <main id="main" class="site-main">
                        <div class="main-news-list">
                            {
                                !details ? <div style={{textAlign: 'center'}}><BookLoader /></div> :
                                <div class="container">
                                    <div>
                                        <section class="search-filters">
                                            <div class="filter-box">
                                                <h3>Find Event</h3>
                                                <form action="" method="get">
                                                    <div class="col-md-10">
                                                        <div class="row">
                                                            <div class="col-md-4 col-sm-6">
                                                                <div class="form-group">
                                                                    <label class="sr-only" htmlFor="keywords">Search by Keyword</label>
                                                                    <input class="form-control" placeholder="Search by Keyword" id="keywords" name="keywords" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-sm-3">
                                                                <div class="form-group">
                                                                    <select name="category" id="category" class="form-control">
                                                                        <option>All Categories</option>
                                                                        <option>Category 01</option>
                                                                        <option>Category 02</option>
                                                                        <option>Category 03</option>
                                                                        <option>Category 04</option>
                                                                        <option>Category 05</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-sm-3">
                                                                <div class="form-group">
                                                                    <select name="locations" id="locations" class="form-control">
                                                                        <option>All Locations</option>
                                                                        <option>Location 01</option>
                                                                        <option>Location 02</option>
                                                                        <option>Location 03</option>
                                                                        <option>Location 04</option>
                                                                        <option>Location 05</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-sm-6">
                                                                <div class="row">
                                                                    <div class="col-sm-6">
                                                                        <div class="form-group">
                                                                            <select name="startdate" id="startdate" class="form-control">
                                                                                <option>Start Date</option>
                                                                                <option>Start Date 01</option>
                                                                                <option>Start Date 02</option>
                                                                                <option>Start Date 03</option>
                                                                                <option>Start Date 04</option>
                                                                                <option>Start Date 05</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-6">
                                                                        <div class="form-group">
                                                                            <select name="enddate" id="enddate" class="form-control">
                                                                                <option>End Date</option>
                                                                                <option>End Date 01</option>
                                                                                <option>End Date 02</option>
                                                                                <option>End Date 03</option>
                                                                                <option>End Date 04</option>
                                                                                <option>End Date 05</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-sm-3">
                                                                <div class="form-group">
                                                                    <input class="form-control" name="age_group" id="age_group" placeholder="Age Group" />                                                               
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-sm-3">
                                                                <div class="form-group">
                                                                    <select name="language" id="language" class="form-control">
                                                                        <option>Language</option>
                                                                        <option>Language 01</option>
                                                                        <option>Language 02</option>
                                                                        <option>Language 03</option>
                                                                        <option>Language 04</option>
                                                                        <option>Language 05</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="row">
                                                            <div class="col-md-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <input class="form-control btn-clearform" type="submit" value="Clear Form" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <input class="form-control" type="submit" value="Find Event" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="clear"></div>
                                        </section>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="news-list-detail">
                                                    <div class="news-list-box">
                                                        <div class="single-news-list">
                                                            <div class="social-content">
                                                                <div class="social-share">
                                                                    <ul>
                                                                        <li><Link href="#."><i class="fa fa-comment"></i> 0</Link></li>
                                                                        <li><Link href="#."><i class="fa fa-thumbs-o-up"></i> 0</Link></li>
                                                                        <li><Link href="#."><i class="fa fa-eye"></i> 0</Link></li>
                                                                    </ul>
                                                                </div>
                                                                <div class="social-media">
                                                                    <RWebShare
                                                                        data={{
                                                                            text: details.title,
                                                                            url: "https://kokawe.com/event/" + details.id,
                                                                            title: details.title
                                                                        }}
                                                                        onClick={() => toast.success("shared successfully!")}
                                                                    >
                                                                        <Link href="#."><i className="fa fa-share-alt text-white" style={{color: 'whitesmoke', textAlign: 'center', padding: '20px', fontSize: '20px'}}></i></Link>                                                                        
                                                                    </RWebShare>
                                                                </div>
                                                            </div>
                                                            <figure>
                                                                <img src={details.banner} alt={details.title} />
                                                            </figure>
                                                            <div class="content-block">
                                                                <div class="member-info">
                                                                    <div class="content_meta_category">
                                                                        <span class="arrow-right"></span>
                                                                        <Link href="#." rel="category tag">EVENT</Link>
                                                                    </div>
                                                                    <ul class="news-event-info">
                                                                        <li>
                                                                            <Link to={'/'} target="_blank">
                                                                                <i class="fa fa-calendar"></i>
                                                                                {details.event_time.split(' ')[0]}
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={'/'} target="_blank">
                                                                                <i class="fa fa-clock-o"></i>
                                                                                {details.event_time.split(' ')[1]}
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={'/'} target="_blank">
                                                                                <i class="fa fa-map-marker"></i>
                                                                                {details.location}
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                    <h2>{details.title}</h2>
                                                                    <p>{details.description}</p>
                                                                </div>
                                                            </div>
                                                            <div class="clearfix"></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-5">
                                                            <div class="contact-info">
                                                                <h3>Event Info</h3>
                                                                <span class="underline left"></span>
                                                                <h5>Event Date</h5>
                                                                <p>Date: {details.event_time.split(',')[0]}</p>
                                                                <p>Date: {details.event_time.split(',')[1]}</p>
                                                                <p>Date: {details.event_time.split(',')[2]}</p>

                                                                <h5>Contact Details</h5>
                                                                <p>Phone: {details.contact_details.split(',')[1]}</p>
                                                                <p>Email: {details.contact_details.split(',')[0]}</p>
                                                                <p>Website: {details.contact_details.split(',')[2]}</p>
                                                                <br />
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-7">
                                                            <div class="events-calendar">
                                                                <h3>Register for Event</h3>
                                                                <span class="underline left"></span>                                                        
                                                                <form onSubmit={(e) => RegisterForEvent(e, details.id)} method="post">
                                                                    <div className="row">
                                                                        <div className="col-md-6 col-sm-6">
                                                                            <div className="form-group">
                                                                                <input className="form-control" value={firstname} onChange={(e) => setFirstname(e.target.value)} type="text" placeholder="First Name" name="first-name" id="first-name" size="30" aria-required="true" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-6">
                                                                            <div className="form-group">
                                                                                <input className="form-control" value={lastname} onChange={(e) => setLastname(e.target.value)}  type="text" placeholder="Last Name" name="last-name" id="last-name" size="30" aria-required="true" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-6">
                                                                            <div className="form-group">
                                                                                <input className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}  type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" placeholder="Email" name="email" id="email" size="30" aria-required="true" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-6">
                                                                            <div className="form-group">
                                                                                <input className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)}  type="text" placeholder="Phone Number" name="phone" id="phone" size="30" aria-required="true" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-6">
                                                                            <div class="form-group">
                                                                                <Select options={options} onChange={(e) => handleGender(e)} name="type"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="form-group form-submit">
                                                                                <input className="btn btn-default" onClick={(e) => RegisterForEvent(e, details.id)} id="submit-contact-form" type="button" name="submit" value="Register" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                <div class="clear"></div>
                                                            </div>
                                                        </div>
                                                    </div>                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default EventDetails;
