import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import http from "../../utils/http";
import { toast, ToastContainer } from "react-toastify";
import { getCategories, getTags } from "../../utils/get-categories";
import { ClipLoader } from "react-spinners";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 } from "uuid";
import { Editor } from '@tinymce/tinymce-react';
import ProgressBar from "@ramonak/react-progress-bar";
import { useParams } from "react-router-dom";

const config = {
    region: process.env.REACT_APP_S3_REGION,
    credentials: {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY        
    }
}

const client = new S3Client(config);

function AuthorEditArticle() {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [categories, setCategories] = useState([]);
    const [article, setArticle] = useState();
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [coverProgress, setCoverProgress] = useState(0);
    const [coverUrl, setCoverUrl] = useState('');
    const [status, setStatus] = useState('')
    const [disable_upload, setUploadStatus] = useState(false);
    const [selectedTags] = useState([]);
    const [selectedCategories] = useState([]);
    const [coverFile, setCoverFile] = useState();
    const [isCoverPicked, setIsCoverPicked] = useState(false);
    const editorRef = useRef(null);

    useEffect(() => {
        async function cate() {
            let cont = [];
            const { data } = await getCategories();
            for (let i = 0; i < data.length; i++) {
                cont.push(data[i])
            }
            setCategories(cont);
        }
        cate();
    }, [])

    useEffect(() => {
        const Tages = async () => {
            let cont = [];
            const { data } = await getTags();
            for (let i = 0; i < data.length; i++) {
                cont.push(data[i])
            }
            setTags(cont);
        }
        Tages()
    }, [])

    const param = useParams();

    useEffect(() => {
        const getArticle = async () => {
            const { data } = await http.get(`article/${param.id}`);
            setArticle(data.data[0]);
            setTitle(data.data[0].title);
            setBody(data.data[0].body)
        }
        getArticle()
    }, [])

    const categoryOptions = [{ value: '', label: 'select category' }];
    categories.forEach((cat) => {
        categoryOptions.push({ value: cat.id, label: cat.name })
    })

    const tagOptions = [{ value: '', label: 'select tags' }];
    tags.forEach((tg) => {
        tagOptions.push({ value: tg.id, label: tg.name })
    })

    const handleCategories = (e) => {
        let value = []
        value.push(e[e.length - 1])
        value.forEach((val) => {
            selectedCategories.push(val.value)
        })
    }

    const handleTags = (e) => {
        let value = []
        value.push(e[e.length - 1])
        value.forEach((val) => {
            selectedTags.push(val.value)
        })
    }

    const UploadCover = async (cover) => {
        let type = cover.type.split('/')[1];
        const coverNameKey = "articles/";
        const coverKey = coverNameKey + v4() + '.' + type;
        await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: coverKey, Body: cover}))
        .then((data) => {
            if(data) {
                setCoverProgress(100);
            }
        }).catch(err => toast.error(err))
        const _coverUrl = process.env.REACT_APP_S3_CLOUDFRONT_URL + coverKey
        setCoverUrl(_coverUrl)
    }

    const coverUploadEvent = (e) => {
        e.preventDefault();
        setIsCoverPicked(true);
        setCoverFile(e.target.files[0])
        UploadCover(e.target.files[0])
    }

    const postArticle = async (e) => {
        e.preventDefault();
        setUploadStatus(true)
        let postData = {
            id: article.id,
            title: title,
            body: editorRef.current.getContent(),
            categories: selectedCategories ? selectedCategories : article.categories,
            tags: selectedTags ? selectedTags : article.tags,
            articleCoverUrl: coverUrl ? coverUrl : article.articleCoverUrl
        }
        await http.patch('article/'+ param.id, postData)
            .then((data) => {
                toast.success(data.data.data)
                setLoading(false);
            })
            .catch((err) => {
                toast.error(err.response.data.message)
                setLoading(false);
                setUploadStatus(false);
            })
    }

    return (
        <>
            <div className="main-content" style={{padding: '40px 0px'}}>
                <section className="section">
                    <div className="section-header">
                        <h1>Edit Article</h1>
                    </div>
                    <div className="card" style={{margin: '20px 10px'}}>
                        <div className="card-header">
                            <h4>Edit Article</h4>
                        </div>
                        <div className="card-body">
                            {/* <div style={{ color: 'green', fontWeight: 'bold', textAlign: 'center' }}>{status}</div> */}
                            <form onSubmit={postArticle} method="post">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="form-group row mb-4">
                                            <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Title</label>
                                            <div className="col-sm-12 col-md-7">
                                                <input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Category</label>
                                            <div className="col-sm-12 col-md-7">
                                                <Select options={categoryOptions} onChange={(e) => handleCategories(e)} name="category" isMulti />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Tag</label>
                                            <div className="col-sm-12 col-md-7">
                                                <Select options={tagOptions} onChange={(e) => handleTags(e)} name="category" isMulti />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Thumbnail</label>
                                            <div className="col-sm-12 col-md-7">
                                                <input type="file" onChange={(e) => coverUploadEvent(e)} name="coverFile" className="form-control" />
                                                <ProgressBar completed={coverProgress} />
                                                {isCoverPicked ? (
                                                    <div>
                                                        <p>Filename: {coverFile.name}</p>
                                                        <p>Filetype: {coverFile.type}</p>
                                                        <p>Size in bytes: {coverFile.size}</p>
                                                        <p>
                                                            lastModifiedDate:{' '}
                                                            {coverFile.lastModifiedDate.toLocaleDateString()}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <p>Select a file to show details</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Content</label>
                                            <div className="col-sm-12 col-md-7">
                                                <Editor
                                                    apiKey="ibl1nhfo5rah9yx1rbcfbid2dkuu7ez2mdz6wqqll53juv8t"
                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                    value={body}
                                                    onChange={(e) => setBody(e.target.value)}
                                                    initialValue="<p>This is the initial content of the editor.</p>"
                                                    init={{
                                                    height: 500,
                                                    menubar: false,                                                    
                                                    plugins: [
                                                    'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                                                    'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                                    'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                                                    ],
                                                    toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                                    'alignleft aligncenter alignright alignjustify | ' +
                                                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                />
                                            </div>
                                        </div>                                        
                                        <div className="form-group row mb-4">
                                            <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                                            <div className="col-sm-12 col-md-7">
                                                <button type="submit" onClick={() => setLoading(true)} className="btn btn-primary btn-lg btn-block">
                                                    {disable_upload ? <ClipLoader loading={loading} size={20} /> : 'Publish'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <ToastContainer />
        </>
    )
}

export default AuthorEditArticle;
