import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";

function ReaderBooks() {
    const [books, setBooks] = useState([]);


    useEffect(() => {
        const getBooks = async () => {
            let cont = [];
            const { data } = await http.get('books/mine');
            console.log(data)
            for (let i = 0; i < data.data.length; i++) {
                cont.push(data.data[i])
            }
            setBooks(cont);
        }
        getBooks();
    }, [])

    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>My Books</h2>
                        <span className="underline center"></span>
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>My Books</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div id="content" className="site-content">
                    <div id="primary" className="content-area">
                        <main id="main" className="site-main">
                            <div className="books-full-width">
                                <div className="container">
                                    <div className="booksmedia-fullwidth">
                                        <ul>
                                            {
                                                !books ? ('') : books.map((book, idx) =>
                                                    <li key={idx}>
                                                        {/* <div className="book-list-icon yellow-icon"></div> */}
                                                        <Link to={`/reader/${book.id}`}>
                                                            <figure>
                                                                <Link to={`/reader/${book.id}`}><img src={book.bookCoverUrl} alt="Book" /></Link>
                                                                <figcaption>
                                                                    <header>
                                                                        <h4><Link to={`/reader/${book.id}`}>{book.title}</Link></h4>
                                                                        <p><strong>Author:</strong> {book.author}</p>
                                                                        <p><strong>ISBN:</strong> {book.isbn}</p>
                                                                    </header>
                                                                    <p>{book.description.slice(0, 100) + ' ...'}</p>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <Link to={`/reader/${book.id}`} target="_blank" data-toggle="blog-tags" data-placement="top" title="Read">
                                                                                    <i className="fa fa-book-open"></i>
                                                                                </Link>
                                                                            </li>
                                                                            {/* <li>
                                                                            <Link to="/" target="_blank" data-toggle="blog-tags" data-placement="top" title="Like">
                                                                                <i className="fa fa-"></i>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="/" target="_blank" data-toggle="blog-tags" data-placement="top" title="Share">
                                                                                <i className="fa fa-"></i>
                                                                            </Link>
                                                                        </li> */}
                                                                            <li>
                                                                                <span>Time Left: <strong>{ }</strong></span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </figcaption>
                                                            </figure>
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    {/* <nav className="navigation pagination text-center">
                                    <h2 className="screen-reader-text">Posts navigation</h2>
                                    <div className="nav-links">
                                        <Link className="prev page-numbers" to="#."><i className="fa fa-long-arrow-left"></i> Previous</Link>
                                        <Link className="page-numbers current" to="#.">1</Link>
                                        <span className="page-numbers">2</span>
                                        <Link className="page-numbers" to="#.">3</Link>
                                        <Link className="page-numbers" to="#.">4</Link>
                                        <Link className="next page-numbers" to="#.">Next <i className="fa fa-long-arrow-right"></i></Link>
                                    </div>
                                </nav> */}
                                </div>

                            </div>
                        </main>
                    </div>
                </div>
            </section>

        </>
    )
};

export default ReaderBooks;