import arrayShuffle from "array-shuffle";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import http from "../../utils/http";

const AdminMedias = () => {
  const [medias, setMedias] = useState([]);

  useEffect(() => {
    const getMedias = async () => {
        let cont = []
        const {data} = await http.get('admin/audio');
        const {data: video} = await http.get('admin/video');
        for(let i = 0; i < data.data.length; i++) {
            cont.push(data.data[i])
        }
        for(let i = 0; i < video.data.length; i++) {
            cont.push(video.data[i])
        }
        let shuffled = arrayShuffle(cont)
        setMedias(shuffled);
    }
    getMedias();
}, []);

const deleteMedia = async (e, id) => {
    e.preventDefault();
    await http.delete('audio/' + id)
    .then((data) => {
        console.log(data.data)
        toast.success(data.data.data);
    })
    .catch(err => {
        toast.error(err.response.data.message)
    })
}
  return (
    <>
      <div className="main-content" style={{padding: '40px 0px'}}>
        <section className="section">
          <div className="section-header">
            <h1>Books</h1>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card" style={{margin: '20px 10px'}}>
                <div className="card-header">
                  <h4>Books</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-md v_center">
                      <tr>
                        <th>#</th>
                        <th>Name</th>                        
                        <th>Action</th>
                      </tr>
                      {
                        medias ? medias.map((media) => 
                          <tr>
                            <td>{media.id}</td>
                            <td>{media.title}</td>
                            
                            <td><Link onClick={(e) => deleteMedia(e, media.id)} className="btn btn-danger">Delete</Link></td>
                          </tr>
                        ) : ""
                      }
                    </table>
                  </div>
                </div>
                {/* <div className="card-footer text-right">
                  <nav className="d-inline-block">
                    <ul className="pagination mb-0">
                      <li className="page-item disabled"><Link className="page-link" to="" tabindex="-1"><i className="fas fa-chevron-left"></i></a></li>
                      <li className="page-item active"><Link className="page-link" to="">1 <span className="sr-only">(current)</span></a></li>
                      <li className="page-item"><Link className="page-link" to="">2</a></li>
                      <li className="page-item"><Link className="page-link" to="">3</a></li>
                      <li className="page-item"><Link className="page-link" to=""><i className="fas fa-chevron-right"></i></a></li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  )
}

export default AdminMedias;
