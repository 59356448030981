// import globalthis from "globalthis";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { StyledEngineProvider } from '@mui/joy/styles';
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import { CartProvider } from "react-use-cart";

/**
 * We use Sentry to monitor the application
 */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  debug: process.env.REACT_APP_SENTRY_DEBUG === "true",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

/**
 * globalThis polyfill:
 * Prevent error on IOS/MacOS version 12.1 or before
 * ReferenceError: Can't find variable: globalThis
 */
// globalthis.shim();
// if (typeof globalThis === "undefined") {
//   var globalThis = Function("return this")();
// }

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <CartProvider>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </CartProvider>
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
