import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import ProgressBar from "@ramonak/react-progress-bar";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { v4 } from "uuid";
import http from "../../utils/http";

const AuthorProfile = () => {
    const config = {
        region: process.env.REACT_APP_S3_REGION,
        credentials: {
            bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY        
        }
    }
    
    const client = new S3Client(config);

    const [profile, setProfile] = useState();
    const [pictureUrl, setPictureUrl] = useState('');
    const [coverFile, setCoverFile] = useState();
    const [coverFileBase64, setCoverFileBase64] = useState();
    const [isCoverPicked, setIsCoverPicked] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [bio, setBio] = useState('');
    const [password, setPassword] = useState('');
    const [current_password, setCurrentPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [phone, setPhone] = useState();
    const [address, setAddress] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const getProfile = async () => {
            await http.get('profile')
            .then((data) => {
                setProfile(data.data.data[0])
                setName(data.data.data[0].name)
                setEmail(data.data.data[0].email)
                setBio(data.data.data[0].biography)
                setPhone(data.data.data[0].phone)
                setAddress(data.data.data[0].address)
            }).catch((err) => {
                // console.log(err);
            })            
        }
        getProfile();
    }, [])

    const coverUploadEvent = (e) => {
        e.preventDefault();
        setIsCoverPicked(true);
        setCoverFile(e.target.files[0])
        UploadPicture(e.target.files[0])
    }

    const UploadPicture = async (media) => {
        let type = media.type.split('/')[1];
        const mediaNameKey = "profile_picture/";
        const mediaKey = mediaNameKey + v4() + '.' + type;
        await client.send(new PutObjectCommand({Bucket: 'bestubborn', ContentType: type, Key: mediaKey, Body: media}))
        .then((data) => {
            if(data) {
                setProgress(100);
            }
        }).catch(err => toast.error(err))
        const _mediaUrl = process.env.REACT_APP_S3_CLOUDFRONT_URL + mediaKey;
        setPictureUrl(_mediaUrl)
    }

    const updateProfile = async (e) => {
        e.preventDefault()
        let postData = {
            name: name,
            email: email,
            phone: phone,
            address: address,
            biography: bio,
            social_media: [facebook, twitter, instagram],
            profilePicture: pictureUrl
        }
        await http.post('profile/update', postData)
        .then((data) => {
            toast.success(data.data.data)
        })
        .then((err) => {
            // console.log(err)
            toast.error(err)
        })
    }

    const updatePassword = async (e) => {
        e.preventDefault();
        await http.post('changePassword', {current_password: current_password, password: password, password_confirmation: password_confirmation})
        .then((data) => {
            toast.success(data.data.data)
        })
        .then((err) => {
            // console.log(err)
            toast.error(err)
        })
    }

    const updateWallet = async (e) => {
        e.preventDefault()
        let postData = {
            bank_name: bankName,
            account_number: accountNumber,
            account_name: accountName
        }
        await http.post('wallet', postData)
        .then((data) => {
            toast.success(data.data.data)
            setBankName('');
            setAccountName('');
            setAccountNumber('');
        })
        .then((err) => {
            toast.error(err)
        })
    }

    return (
        <>
            <div className="main-content" style={{padding: '40px 0px'}}>
                <section className="section">
                    <div className="section-header">
                        <h1>Profile</h1>
                        <div className="section-header-breadcrumb">
                            <div className="breadcrumb-item active"><Link to="#">Dashboard</Link></div>
                            <div className="breadcrumb-item">Profile</div>
                        </div>
                    </div>
                    <div className="section-body">
                        <h2 className="section-title">Hi, {!profile ? '' : profile.name}</h2>
                        <p className="section-lead">Change information about yourself on this page.</p>

                        <div className="row mt-sm-4">
                            <div className="col-12 col-md-12 col-lg-5">
                                <div className="card profile-widget">
                                    <div className="profile-widget-header">
                                        <img alt="author" src={!profile ? 'assets/user.jpeg' : profile.image} className="rounded-circle profile-widget-picture"/>                                            
                                    </div>
                                    <div className="profile-widget-description">
                                        <div className="profile-widget-name">{!profile ? '' : profile.name} <div className="text-muted d-inline font-weight-normal">
                                            </div>
                                        </div>
                                        {!profile ? '' : profile.biography}
                                    </div>
                                    <div className="card-footer text-center">
                                        <div className="font-weight-bold mb-2">Follow {!profile ? '' : profile.name} On</div>
                                        <Link to="#" className="btn btn-social-icon btn-facebook mr-1"><i className="fab fa-facebook-f"></i></Link>
                                        <Link to="#" className="btn btn-social-icon btn-twitter mr-1"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#" className="btn btn-social-icon btn-github mr-1"><i className="fab fa-github"></i></Link>
                                        <Link to="#" className="btn btn-social-icon btn-instagram"><i className="fab fa-instagram"></i></Link>
                                    </div>
                                </div>
                                <div className="card" style={{margin: '20px 10px'}}>
                                    <form method="post" className="needs-validation" noValidate="">
                                        <div className="card-header">
                                            <h4>Update Account Details</h4>
                                        </div>
                                        <div className="card-body">                                            
                                            <div className="row">
                                                <div className="form-group col-md- col-12">
                                                    <label>Account Number</label>
                                                    <input type="text" className="form-control" onChange={(e) => setAccountNumber( e.target.value)} value={accountNumber} required=""/>
                                                        <div className="invalid-feedback">Please fill in the first name</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-7 col-12">
                                                    <label>Account Name</label>
                                                    <input type="text" className="form-control" onChange={(e) => setAccountName(e.target.value)} value={accountName} required=""/>
                                                        <div className="invalid-feedback">Please fill in the email</div>
                                                </div>
                                                <div className="form-group col-md-5 col-12">
                                                    <label>Bank Name</label>
                                                    <input type="text" className="form-control" onChange={(e) => setBankName(e.target.value)} value={bankName}/>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-primary" onClick={updateWallet}>Save Changes</button>
                                        </div>
                                    </form>
                                </div>

                                <div className="card" style={{margin: '20px 10px'}}>
                                    <form method="post" className="needs-validation" noValidate="">
                                        <div className="card-header">
                                            <h4>Update Password</h4>
                                        </div>
                                        <div className="card-body">                                            
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Current Password</label>
                                                    <input type="password" className="form-control" onChange={(e) => setCurrentPassword( e.target.value)} value={current_password} required=""/>
                                                        <div className="invalid-feedback">Please fill in the first name</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Password</label>
                                                    <input type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} value={password} required=""/>
                                                        <div className="invalid-feedback">Please fill in the email</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Confirm Password</label>
                                                    <input type="password" className="form-control" onChange={(e) => setPasswordConfirmation(e.target.value)} value={password_confirmation}/>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-primary" onClick={updatePassword}>Save Changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-7">
                                <div className="card" style={{margin: '20px 10px'}}>
                                    <form method="post" className="needs-validation" noValidate="">
                                        <div className="card-header">
                                            <h4>Edit Profile</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group row mb-4">
                                                <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Profile Image</label>
                                                <div className="col-sm-12 col-md-7">
                                                    <input type="file" onChange={(e) => coverUploadEvent(e)} name="coverFile" className="form-control"/>
                                                    <ProgressBar completed={progress} />
                                                    {isCoverPicked ? (
                                                        <div>
                                                            <p>Filename: {coverFile.name}</p>
                                                            <p>Filetype: {coverFile.type}</p>
                                                            <p>Size in bytes: {coverFile.size}</p>
                                                            {/* <p>
                                                                lastModifiedDate:{' '}
                                                                {coverFile.lastModifiedDate.toLocaleDateString()}
                                                            </p> */}
                                                        </div>
                                                    ) : (
                                                        <p>Select a file to show details</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Full Name</label>
                                                    <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} value={name} required=""/>
                                                        <div className="invalid-feedback">Please fill in the first name</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-7 col-12">
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} required=""/>
                                                        <div className="invalid-feedback">Please fill in the email</div>
                                                </div>
                                                <div className="form-group col-md-5 col-12">
                                                    <label>Phone</label>
                                                    <input type="tel" className="form-control" onChange={(e) => setPhone(e.target.value)} value={phone}/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-4 col-12">
                                                    <label>Twitter Link</label>
                                                    <input type="text" className="form-control" onChange={(e) => setTwitter(e.target.value)} value={twitter} required=""/>
                                                        <div className="invalid-feedback">Please fill in the email</div>
                                                </div>
                                                <div className="form-group col-md-4 col-12">
                                                    <label>Facebook Link</label>
                                                    <input type="text" className="form-control" onChange={(e) => setFacebook(e.target.value)} value={facebook}/>
                                                </div>
                                                <div className="form-group col-md-4 col-12">
                                                    <label>Instagram Link</label>
                                                    <input type="text" className="form-control" onChange={(e) => setInstagram(e.target.value)} value={instagram}/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label>Address</label>
                                                    <input type={address} className="form-control" onChange={(e) => setAddress(e.target.value)} value={address} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label>Bio</label>
                                                    <textarea className="form-control" onChange={(e) => setBio(e.target.value)} value={bio} rows={12} cols="12"></textarea>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-primary" onClick={updateProfile}>Save Changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ToastContainer />
        </>
    )
}

export default AuthorProfile;