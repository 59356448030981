import arrayShuffle from "array-shuffle";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";

function UserMedia() {
    const [medias, setMedias] = useState([]);

    useEffect(() => {
        const getMedias = async () => {
            let cont = [];
            const {data} = await http.get('my-audio');
            for (let i = 0; i < data.data.length; i++) {
                cont.push(data.data[i])
            }
            const {data: video} = await http.get('my-video');
            for (let i = 0; i < video.data.length; i++) {
                cont.push(video.data[i])
            }
            let shuffled = arrayShuffle(cont);
            setMedias(shuffled);
        }
        getMedias();
    }, [])

    return (
        <>
            <section className="page-banner services-banner">
                <div className="container">
                    <div className="banner-header">
                        <h2>My Media</h2>
                        <span className="underline center"></span>
                        {/* <p className="lead">Rando text here</p> */}
                    </div>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>My Media</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <div className="books-full-width">
                            <div className="container">
                                <div className="booksmedia-fullwidth">
                                    <ul>
                                        {
                                            !medias ? ('') : medias.map((media, idx) =>
                                                <li key={idx}>
                                                    {/* <div className="book-list-icon yellow-icon"></div> */}
                                                    <Link to={"/play-media/" + media.id}>
                                                        <figure>
                                                            <Link to={"/play-media/" + media.id}><img src={media.audio_cover_url ?? media.video_cover_url} alt={media.title} /></Link>
                                                            <figcaption>
                                                                <header>
                                                                    <h4><Link to={"/media/" + media.id}>{media.title}</Link></h4>
                                                                    <p><strong>Author:</strong> {media.owner}</p>
                                                                    {/* <p><strong>ISBN:</strong> {media.isbn}</p> */}
                                                                </header>
                                                                <p>{media.description}</p>
                                                                <div className="actions">
                                                                    <ul>
                                                                        <li>
                                                                            <Link to={`/play-media/${media.id}`} target="_blank" data-toggle="blog-tags" data-placement="top" title="Read">
                                                                                <i className="fa fa-play"></i>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </figcaption>
                                                        </figure>
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
};

export default UserMedia;
