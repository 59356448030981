import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { getProfile } from "../utils/get-profile";

function Sidebar() {
    const location = useLocation();
    // const navigate = useNavigate();
    const [admin, setAdmin] = useState(false)
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const getUserType = async () => {
            const admin = await getProfile()
            if (admin === "Unauthenticated.") {
                localStorage.clear('token');
                <Navigate to={'/login'} />
            } else {
                setUserType(admin.data.data[0].user_type);
            };
            if (admin.data.data[0].role[0].name) {
                setAdmin(true);
            }
        }
        getUserType();
    }, [])

    return (
        <div className="main-sidebar sidebar-style-2">
            <aside id="sidebar-wrapper">
                <div className="sidebar-brand">
                    <Link to={"/dashboard"}><img src="logo.png" width={100} alt="logo" /></Link>
                </div>
                <div className="sidebar-brand sidebar-brand-sm">
                    <Link to={"/dashboard"}><img src="favicon.jpeg" width={50} alt="logo" /></Link>
                </div>
                <ul className="sidebar-menu">
                    {
                        admin ? (
                            <>
                                <li className="menu-header">Admin</li>
                                <li className={location.pathname === '/admin-dashboard' ? "active" : ''}><Link className="nav-link" to={"/admin-dashboard"}><i className="fas fa-dashboard"></i> <span>Admin Dashboard</span></Link></li>
                                <li className={location.pathname === '/admin-users' ? "active" : ''}><Link className="nav-link" to={"/admin-users"}><i className="fas fa-users"></i> <span>Users</span></Link></li>
                                <li className={location.pathname === '/admin-teachers' ? "active" : ''}><Link className="nav-link" to={"/admin-users"}><i className="fas fa-users"></i> <span>Teachers</span></Link></li>
                                <li className={location.pathname === '/admin-roles' ? "active" : ''}><Link className="nav-link" to={"/admin-roles"}><i className="fas fa-users"></i> <span>Roles</span></Link></li>
                                <li className={location.pathname === '/admin-books' ? "active" : ''}><Link className="nav-link" to={"/admin-books"}><i className="fas fa-book"></i> <span>Books</span></Link></li>
                                <li className={location.pathname === '/admin-percentages' ? "active" : ''}><Link className="nav-link" to={"/admin-percentages"}><i className="fas fa-percent"></i> <span>Percentages</span></Link></li>
                                <li className={location.pathname === '/admin-articles' ? "active" : ''}><Link className="nav-link" to={"/admin-articles"}><i className="fas fa-file-text"></i> <span>Articles</span></Link></li>
                                <li className={location.pathname === '/admin-categories' ? "active" : ''}><Link className="nav-link" to={"/admin-categories"}><i className="fas fa-tags"></i> <span>Categories</span></Link></li>
                                <li className={location.pathname === '/admin-tags' ? "active" : ''}><Link className="nav-link" to={"/admin-tags"}><i className="fas fa-tag"></i> <span>Tags</span></Link></li>
                                <li className="menu-header">Transactions</li>
                                <li className={location.pathname === '/admin-book-transactions' ? "active" : ''}><Link className="nav-link" to={"/admin-book-transactions"}><i className="fa fa-money"></i><span>Book</span></Link></li>
                                <li className={location.pathname === '/admin-sub-transactions' ? "active" : ''}><Link className="nav-link" to={"/admin-sub-transactions"}><i className="fa fa-money"></i><span>Subscription</span></Link></li>
                                <li className={location.pathname === '/admin-withdrawals' ? "active" : ''}><Link className="nav-link" to={"/admin-withdrawals"}><i className="far fa-money"></i> <span>Withdrawals</span></Link></li>
                                <li className={location.pathname === '/admin-consultations' ? "active" : ''}><Link className="nav-link" to={"/admin-consultations"}><i className="fas fa-pencil-ruler"></i> <span>Consultations</span></Link></li>
                                <li className={location.pathname === '/admin-settings' ? "active" : ''}><Link className="nav-link" to={"/admin-settings"}><i className="fas fa-cog"></i> <span>Settings</span></Link></li>

                            </>
                        ) : ''}
                    {userType === "Author" ? (
                        <>
                            <li className="menu-header">Dashboard</li>
                            <li className={location.pathname === '/dashboard' ? "active" : ''}><Link className="nav-link" to="/dashboard"><i className="fas fa-dashboard"></i> <span>Dashboard</span></Link></li>
                            <li className="menu-header">Books</li>
                            <li className={location.pathname === '/upload-book' ? "active" : ''}><Link className="nav-link" to={"/upload-book"}><i className="fas fa-upload"></i> <span>Upload Book</span></Link></li>
                            <li className={location.pathname === '/author-books' ? "active" : ''}><Link className="nav-link" to={"/author-books"}><i className="fas fa-book"></i> <span>My Books</span></Link></li>
                            <li className="menu-header">Medias</li>
                            <li className={location.pathname === '/upload-media' ? "active" : ''}><Link className="nav-link" to={"/upload-media"}><i className="fas fa-upload"></i> <span>Upload Media</span></Link></li>
                            <li className={location.pathname === '/author-medias' ? "active" : ''}><Link className="nav-link" to={"/author-medias"}><i className="fas fa-book"></i> <span>My Medias</span></Link></li>
                            <li className="menu-header">Articles</li>
                            <li className={location.pathname === '/new-article' ? "active" : ''}><Link className="nav-link" to={"/new-article"}><i className="fas fa-pencil"></i> <span>Write Article</span></Link></li>
                            <li className={location.pathname === '/author-articles' ? "active" : ''}><Link className="nav-link" to={"/author-articles"}><i className="fas fa-list"></i> <span>My Articles</span></Link></li>
                            <li className="menu-header">Consultations</li>
                            <li className={location.pathname === '/author-consultations' ? "active" : ''}><Link className="nav-link" to={"/author-consultations"}><i className="fas fa-pencil-ruler"></i> <span>View Consultations</span></Link></li>
                            <li className="menu-header">Starter</li>
                            <li className={location.pathname === '/author-portfolio' ? "active" : ''}><Link className="nav-link" to={"/author-portfolio"}><i className="fas fa-briefcase"></i> <span>My Portfolio</span></Link></li>
                            <li className={location.pathname === '/author-subscription' ? "active" : ''}><Link className="nav-link" to={"/author-subscriptions"}><i className="fas fa-money"></i> <span>Subscriptions</span></Link></li>
                            <li className={location.pathname === '/author-wallet' ? "active" : ''}><Link className="nav-link" to={"/author-wallet"}><i className="fas fa-wallet"></i> <span>Wallet</span></Link></li>
                            <li className={location.pathname === '/author-settings' ? "active" : ''}><Link className="nav-link" to={"/author-settings"}><i className="fas fa-cogs"></i> <span>Settings</span></Link></li>

                        </>
                    ) : userType === "Publisher" ? (
                        <>
                            <li className="menu-header">Publisher</li>
                            <li className={location.pathname === '/aggregator-dashboard' ? "active" : ''}><Link className="nav-link" to={"/publisher-dashboard"}><i className="fas fa-dashboard"></i> <span>Dashboard</span></Link></li>
                            <li className={location.pathname === '/publisher-referrals' ? "active" : ''}><Link className="nav-link" to={"/publisher-referrals"}><i className="fas fa-person"></i> <span>Referral</span></Link></li>
                        </>
                    ) : userType === "User" ? (
                        <>
                            <li className="menu-header">Reader</li>
                            <li className={location.pathname === '/reader-books' ? "active" : ''}><Link className="nav-link" to={"/reader-books"}><i className="fas fa-book"></i> <span>Purchased Books</span></Link></li>
                            <li className={location.pathname === '/consult-author' ? "active" : ''}><Link className="nav-link" to={"/consult-author"}><i className="fas fa-pencil-ruler"></i> <span>Consult Author</span></Link></li>
                            <li className={location.pathname === '/saved-articles' ? "active" : ''}><Link className="nav-link" to={"/saved-article"}><i className="fas fa-file-text-o"></i> <span>Saved Articles</span></Link></li>
                        </>
                    ) : ('')
                }
                </ul>
            </aside>
        </div>
    )
}

export default Sidebar;
