import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import http from "../../utils/http";

function MyAppointment() {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const getAppointments = async () => {
      const { data } = await http.get("/appointments");
      console.log(data.data);
      setAppointments(data.data);
    };
    getAppointments();
  }, []);

  return (
    <>
      <section className="page-banner services-banner">
        <div className="container">
          <div className="banner-header">
            <h2>My Appointments</h2>
            <span className="underline center"></span>
            {/* <p className="lead">Rando text here</p> */}
          </div>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>My Appointments</li>
            </ul>
          </div>
        </div>
      </section>

      <div id="content" className="site-content" style={{marginTop: '20px'}}>
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="books-full-width">
              <div className="container">
                <div className="booksmedia-fullwidth">
                  <ul>
                    {!appointments
                      ? ""
                      : appointments.map((appointment, idx) => (
                          <li key={idx}>
                            <Card sx={{ maxWidth: 400 }}>
                              <CardContent>
                                <Link to={`/my-appointment/${appointment.id}`}>
                                  <Typography variant="h6" color="black">
                                    {appointment.subject} class
                                  </Typography>
                                </Link>
                                <div className="flex justify-between" style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography
                                    variant="subtitle1"
                                    sx={{ paddingY: "10px" }}
                                    color="text.secondary"
                                    >
                                    {appointment.day} - {appointment.time} <br />
                                    1hr
                                    </Typography>
                                    <Typography
                                    variant="subtitle1"
                                    sx={{ paddingY: "10px", float: 'right' }}
                                    color="text.secondary"
                                    >
                                    {appointment.month}
                                    </Typography>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Link to={`/my-appointment/${appointment.id}`}>
                                    <Typography variant="subtitle2" color="black">
                                        Subscriber: {appointment.teacher_name}
                                    </Typography>
                                    </Link>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ paddingY: "0px", float: 'right' }}
                                        color="text.secondary"
                                        >
                                        Amount: {appointment.amount}
                                    </Typography>
                                </div>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ paddingY: "0px", float: 'right' }}
                                    color="text.secondary"
                                    >
                                    Paid: {appointment.paid === 0 ? "Not Paid" : "Paid"}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ paddingY: "0px", float: 'right' }}
                                    color="text.secondary"
                                    >
                                    Validity: {appointment.valid === 0 ? "Completed" : "Ongoing"}
                                </Typography>
                              </CardContent>
                            </Card>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default MyAppointment;
