import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import http from "../../utils/http";

const AdminEditPercentages = () => {
  const [authorPercentage, setAuthorPercentage] = useState('');
  const [referralPercentage, setReferralPercentage] = useState('');
  const [marketing, setMarketing] = useState('');
  const [percentage, setPercentage] = useState();
 
  const updatePercentage = async (e) => {
    e.preventDefault();
    let postData = {
        id: percentage.id,
        authorPercentage: authorPercentage,
        referralPercentage: referralPercentage,
        marketing: marketing,
    };
    const data = await http.patch('admin/percentages/'+params.id , postData);
    toast.success(data.data.data)
  }

  const params = useParams();

  useEffect(() => {
    const getPercentage = async () => {
      const data = await http.get('admin/percentage/'+params.id);
      setPercentage(data.data.data);
    } 
    getPercentage()
  }, [])

  return (
    <>
      <div className="main-content" style={{padding: '40px 0px'}}>
        <section className="section">
          <div className="section-header">
            <h1>Edit Percentage</h1>
          </div>
          <form onSubmit={updatePercentage} method="post">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{margin: '20px 10px'}}>
                  <div className="card-header">
                    <h4>Update Percentage</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Referral Percentage</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" value={percentage.referralPercentage} onChange={(e) => setReferralPercentage(e.target.value)} name="title" className="form-control" />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Author Percentage</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" value={percentage.authorPercentage} onChange={(e) => setAuthorPercentage(e.target.value)} name="title" className="form-control" />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Marketing</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" value={percentage.marketing} onChange={(e) => setMarketing(e.target.value)} name="title" className="form-control" />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className="btn btn-primary">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
      <ToastContainer />
    </>
  )
}

export default AdminEditPercentages;
