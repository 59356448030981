const CryptoJS = require('crypto-js');

const encrypt = (text) => {
    return CryptoJS.AES.encrypt(JSON.stringify({ text }), process.env.REACT_APP_SECRET_KEY).toString();
//   return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

const decrypt = (data) => {
    return CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
//   return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export {encrypt, decrypt};