import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader"
import '../routes/Auth.css'
import '../App.css'
import { Link, useNavigate } from "react-router-dom";
import http from "../utils/http";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthSidePane from "../components/auth-side-pane";
import axios from "axios";

function Login() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('')
    const [disable_login, setLoginStatus] = useState(false);
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoginStatus(true)
        let postData = {
            email: email,
            password: password
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}login`, postData)
            .then((data) => {
                if (data.data.authorisation.token) {
                    localStorage.setItem("token", JSON.stringify(data.data.authorisation.token))
                    localStorage.setItem("email", data.data.user[0].email)
                    localStorage.setItem("name", data.data.user[0].name)
                    if (data.data.user[0].user_type === "Author") {
                        navigate('/dashboard')
                    } else if (data.data.user[0].user_type === "User") {
                        navigate('/reader-books')
                    } else if (data.data.user[0].user_type === "Publisher") {
                        navigate('/home');
                    } else if (data.data.user[0].user_type === "Teacher") {
                        navigate('/home');
                    }
                }
            })
            .catch((err) => {
                setStatus(err.response.data.message)
                setLoading(false);
                setLoginStatus(false);
                // console.log(err.response.data.message)
            })
        setPassword("");
        setEmail("");
    };

    return (
        <div className="Flex-Display">
            {/* <ToastContainer /> */}
            <AuthSidePane />
            <div className="Login">
                {/* <h3>Welcome Back</h3> */}

                <div className="login-brand">
                    <Link to="/home">
                        <img src="logo.png" alt="logo" width="350" />
                    </Link>
                </div>
                <div className="mx-4">
                    {/* <div className="card-header">
                            <h4>Login</h4>
                        </div> */}
                    <div className="card-body">
                        <div style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>{status}</div>
                        <form method="POST" onSubmit={handleSubmit} className="needs-validation" noValidate="">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input id="email" type="email" value={email} className="form-control" name="email" onChange={(e) => setEmail(e.target.value)} tabIndex="1" required autoFocus />
                                <div className="invalid-feedback">
                                    Please fill in your email
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="d-block">
                                    <label htmlFor="password" className="control-label">Password</label>
                                    <div className="float-right">
                                        <Link to="/forget-password" className="text-small">
                                            Forgot Password?
                                        </Link>
                                    </div>
                                </div>
                                <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" name="password" tabIndex="2" required />
                                <div className="invalid-feedback">
                                    please fill in your password
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me" />
                                    <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" onClick={() => setLoading(true)} className="btn btn-primary btn-lg btn-block" tabIndex="4">
                                    {disable_login ? <ClipLoader loading={loading} size={20} /> : 'Login'}
                                </button>
                            </div>
                        </form>
                        <div className="mt-3 text-muted text-center">
                            Don't have an account? <Link to="/register">Create One</Link>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Login;
