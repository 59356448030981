import React from "react";
import '../routes/Auth.css'
import '../App.css'
import { Link } from "react-router-dom";

function AuthSidePane() {
    return (
        <div className="Sidepane">
            <div className="center margin-y-30">
                <a href="/">
                    <img src="./assets/logo2.png" width={170} alt="logo" />
                </a>
            </div>

            <div className="margin-y-30">
                <a href="/">
                    <img src="./assets/Frame.png" width={300} alt="frame" />
                </a>
            </div>

            {/* <div className="Vector-text Text-White">
                <p>Softcopies are read on the <br /> websites; with a unique code</p>
            </div> */}

            {/* <div>
                <h3 className="Text-White">Sign in as</h3>
                <p>
                    <Link className="txt">Administrator</Link>
                </p>
                <p>
                    <Link className="txt">Author</Link>
                </p>
                <p>
                    <Link className="txt">Reader</Link>
                </p>
            </div> */}
        </div>
    )
}

export default AuthSidePane;
